.push_notification_modal {
    // text-align: center;
    background-color: white;
    width: 400px;
    border-radius: 10px;
    font-family: Inter_Regular;
    .push_notification_modal_content {
        padding: 16px 32px 16px 32px;
        max-height: 85vh;
        overflow-y: auto;
        .push_notification_title_div {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            letter-spacing: -0.01em;
            /* Primary/800 */
            color: #211740;
            font-family: Inter_Regular;
            font-weight: 500px;
            margin-bottom: 4px;
        }
        .push_notification_subtitle_div {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height, or 150% */
            letter-spacing: -0.002em;
            /* Gray/Gray 500 */
            color: #667085;
            padding-bottom: 17px;
            border-bottom: 1px solid #f9fafb;
        }
        .select_date_cover {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #98a2b3;
            margin-bottom: 8px;
            margin-bottom: 20px;
            font-family: Inter_Regular;
        }
        .select_time_cover {
            .select_time_title {
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #98a2b3;
                font-family: Inter_Regular;
                margin-bottom: 13px;
            }
        }
    }
}