.verify-email {
  background-color: #f6f6f9;
  .verify-email-view {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f9;
    min-height: 100vh;
    &-div {
      // background-color: green;
      max-width: 418px;
      &-icon {
        margin-bottom: 32px;
        text-align: center;
      }
      &-title {
        font-size: 32px;
        color: #312360;
        font-weight: 500;
        font-family: Calibre_Medium;
        text-align: center;
        margin-bottom: 16px;
      }
      &-subtitle {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: Calibre_Regular;
        margin-bottom: 32px;
        color: #616e7c;
        span {
          color: #523b9f;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      &-form {
        .verify-email-view-div-otp {
          display: flex;
          justify-content: center;
          div {
            div {
              input {
                border-radius: 4px !important;
                width: 48px !important;
                height: 56px !important;
                border: 1px solid #d0d5dd;
                color: #475467;
                background: #f6f6f9;
                font-size: 32px;
                font-weight: 400;
                font-family: "Inter_Regular";
                &:focus {
                  outline: none !important;
                  border: 1px solid #523b9f;
                }
              }
            }
          }
        }

        &-button {
          display: flex;
          justify-content: center;
          font-family: Inter_Medium;
          &-inner {
            margin-top: 40px;
            width: 65%;
          }
        }
      }
      &-resend {
        text-align: center;
        margin-top: 16px;
        font-size: 14px;
        line-height: 17px;
        font-family: Calibre_Regular;
        color: #667085;
        span {
          color: #523b9f;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
