.question_modal {
  width: 346px;
  background-color: white;
  border-radius: 10px;
  // padding: 20px 32px;
  padding: 72px 37px 17px 37px;
  @media (max-width: 650px) {
    width: 100%;
  }
  .question_confirm_or_cancel_modal {
    .question_icon_wrapper {
      display: flex;
      justify-content: center;
      margin: 20px 0px 24px 0px;
    }
    .question_modal_title {
      font-family: "Inter_Regular";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */
      text-align: center;
      /* Black */
      color: #121212;
      // margin-bottom: 12px;
      margin-bottom: 16px;
    }
    .question_modal_sub_title {
      font-family: "Inter_light";
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      /* or 125% */
      text-align: center;
      color: #667085;
      margin-bottom: 16px;
      span {
        color: #5941a9;
        text-transform: capitalize;
        font-family: "Inter_light";
      }
    }
    padding-bottom: 30px;
  }
  .twin-buttons-cover {
    // max-width: 370px;
    margin: 2em auto 0;
    // width: fit-content;
    // display: flex;
    width: 100%;
    flex-direction: column;

    .app-buttons {
      font-size: 1em;
      margin-bottom: 0;
      padding: 0.5em 1.6em;
      border-radius: 5px;
      span {
        font-size: 0.8em;
      }
    }
    .cancel-type {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      color: #344054;
    }
    .save-type {
      background: #5941a9;
      border: 1px solid #5941a9;
      color: white;
      margin: 0px 0px 15px 0px;
    }
  }
}
