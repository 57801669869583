.payment_link_image_list_cover {
    .first_image_cover {
        width: 100%;
        height: 211px;
        background-color: silver;
        border-radius: 4px;
        margin-bottom: 20px;
        position: relative;
        .image_icon_right {
            position: absolute;
            right: 10px;
            top: 40%;
            cursor: pointer;
        }
        .image_icon_left {
            cursor: pointer;
            position: absolute;
            left: 10px;
            top: 40%;
        }
    }
    .bottom_list_image_cover {
        display: flex;
        .bottom_images {
            // background-color: green;
            height: 80px;
            // margin-right: 8px !important;
            border-radius: 4px;
        }
    }
}