.image_cover_with_hover {
    position: relative;
    .image_del_icon_cover {
        display: none;
    }
    .image_del_icon_loader {
        background-color: rgba(152, 162, 179, 1);
        font-size: 10px;
        font-family: Inter_Regular;
        position: absolute;
        right: 8px;
        top: 8px;
        color: white;
        font-weight: 500;
        padding: 1px 5px;
        border-radius: 15px;
    }
}

.image_cover_with_hover:hover {
    cursor: pointer;
    .image_del_icon_cover {
        position: absolute;
        right: 8px;
        top: 8px;
        display: block;
    }
}