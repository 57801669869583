.table-container {
  .div-table {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: "Inter_Regular";
    margin: 0em auto;
    padding: 0;
    background: #ffffff;
    border-radius: 4px;
    max-height: 900px;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    width: max-content;
    min-width: 100%;
    @media (max-width: 654px) {
      max-height: 100%;
    }
    .table-cover-top {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 16px 20px;
      padding-bottom: 0;
      border-bottom: 1px solid #f2f4f7;
    }
    table {
      margin: 0 auto;
      text-align: left;
      border-collapse: collapse;
      width: 100%;
    }
    .table-body-container {
      width: 100%;
    }
    thead {
      th {
        padding-right: 1em;
        padding-left: 1em;
        font-size: 12px;
        height: 40px;
        p {
          font-size: 12px;
          font-family: "Inter_Regular";
        }
      }
      tr {
        width: calc(100% - 74px);
        margin: 0 auto;
        background: #f9fafb;
        border-radius: 6px;
      }
    }
    tbody {
      font-size: 13px;
      border-bottom: 1px solid #f9fafb;
      td {
        padding: 0 1em;
        height: 48px;
        @media (max-width: 500px) {
          width: 6px;
          padding-right: 12px !important;
          // background: green;
        }
      }
      tr {
        width: 98%;
        margin: 0 auto;
        .loan-dropdown-details-list {
          background: #ffffff;
          box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
          border-radius: 4px;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;

          div {
            align-items: baseline;
            font-size: 12px;
            line-height: 12px;
            font-family: Inter_Regular;
            font-weight: 400;
            padding: 8px 8px 9px 8px;
            height: 32px;
            cursor: pointer;
            span {
              margin-right: 10px;
            }
          }
          div:hover {
            background: rgba(220, 216, 236, 0.3);
            color: #523b9f !important;

            span {
              svg {
                path {
                  fill: #523b9f;
                }
              }
            }
          }
        }
      }
    }
    tbody:nth-last-child(1) {
      border-bottom: 1px solid white;
      padding-bottom: 0;
    }
    td,
    th {
      text-align: left;
      font-style: normal;
      max-width: 300px;
      @media screen and (max-width: 655px) {
        width: fit-content;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #475467;
        font-size: 13px;
        font-family: "Inter_Regular";
        margin: 0;
        padding: 0;
      }
      span {
        font-size: 12px;
        color: #272643;
        font-family: "Inter_Regular";
      }
    }
    .action-key {
      // width: 6em !important;
      width: fit-content;
      span {
        background: #fffafa;
        border: 1px solid #e4e4e4;
        box-sizing: border-box;
        font-family: "Inter_Regular";
        border-radius: 3px;
        padding: 0.5em 1em;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .link-view {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 0.3em;
        color: #5941a9;
        font-family: "Inter_Regular";
        font-size: 12px;
        display: block;
        cursor: pointer;
      }
    }
    .btn-view {
      span {
        padding: 4px 16px;
        background: #5941a9;
        border-radius: 2px;
        color: white;
        margin: 0 auto;
        height: 25px;
        text-align: center;
        display: block;
        font-size: 13px;
        font-family: "Avenir-Medium";
        width: fit-content;
      }
    }
    .table-img {
      height: 3.5em;
      width: 3.5em;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 1em;
      margin-left: 0em;
      border: 1px solid #e4e4e4;
    }
    th {
      text-transform: capitalize;
      font-family: "Inter_Regular";
      color: #a6aab4;
    }
    .double-cell {
      display: flex;
      p:nth-last-child(1) {
        font-family: "Inter_Regular";
        color: #98a2b3;
        margin-left: 8px;
      }
      p {
        :first-child {
          .admin-small-image-table {
            background-color: #d9dbdea6;
            width: 20px !important;
            height: 20px !important;
            border-radius: 100%;
            border: 1px solid transparent;
            object-fit: cover;
          }
        }
      }
    }
    .disabled-row {
      opacity: 0.4;
      cursor: not-allowed;
    }
    .colorize-blue {
      color: #5795e7;
    }
    .amount-style {
      color: #1d2939;
      font-weight: 500;
      font-family: "Inter_Medium";
    }
    .comment-style {
      color: #5941a9;
      font-weight: 500;
      font-family: "Inter_Medium";
    }
    .defaultCharges-style {
      color: #fa5b7c;
      font-weight: 500 !important;
    }
    .transactionType-style {
      text-transform: uppercase;
    }
    .amount-charges-style {
      color: #1d2939;
      font-weight: 500;
      font-family: "Inter_Medium";
    }
    .message-style {
      color: #5941a9;
      font-weight: 500;
      font-family: "Inter_Medium";
    }
    .enabled-default,
    .active-status,
    .inactive-status,
    .out-of-stock,
    .pending-status,
    .transit-status,
    .credit-status,
    .debit-status,
    .card-status,
    .pending-approval-status,
    .deactivated-status {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 73px;
      text-align: center;
      text-transform: capitalize;
      border-radius: 100px;
      font-size: 10px;
      width: fit-content;
      padding-left: 0.5em;
      padding-right: 0.5em;
      height: 22px;
      // padding: 0.5em;
      // font-weight: bold;
      // font-size: 0.7em;
      // text-transform: uppercase;
      // width: 13em;
    }
    .defaulting-status {
      color: #912018;
      background: rgba(254, 205, 202, 0.3);
      justify-content: center;
      border-radius: 100px;
      text-transform: capitalize;
      font-size: 10px;
      height: 22px;
      display: flex;
      align-items: center;
      width: 73px;
      min-width: 60px;
    }
    .not-due-status {
      color: #2f394b;
      background: #d9dbde;
      justify-content: center;
      border-radius: 100px;
      text-transform: capitalize;
      font-size: 10px;
      height: 22px;
      display: flex;
      align-items: center;
      min-width: 60px;
    }
    .due-status {
      color: #93370d;
      background: #fffbf1;
      justify-content: center;
      border-radius: 100px;
      text-transform: capitalize;
      font-size: 10px;
      height: 22px;
      display: flex;
      align-items: center;
      width: 73px;
      min-width: 60px;
    }
    .active-status {
      color: #05603a;
      background: rgba(0, 196, 140, 0.07);
    }
    .deactivated-status {
      color: #f04438;
      background: rgba(240, 68, 56, 0.07);
    }

    .table-checkbox {
      width: 2em;
      min-width: 2em;
      margin-right: 8px;
      display: block;
    }
    .checkbox-cover {
      width: 2em;
      padding-right: 0;
      padding-left: 1.5em;
    }
    .enabled-default {
      background-color: #dceddd;
      // border-color: #2dad31;
      color: #2dad31;
    }
    .inactive-status {
      background: #d9dbde;
      color: #2f394b;
    }
    .out-of-stock {
      background-color: #f1f5ff;
      color: #a6aab4;
    }
    .pending-status {
      background: #fffbf1;
      color: #93370d;
    }
    .pending-approval-status {
      background: rgba(156, 117, 208, 0.1);
      color: #5941a9;
    }
    .credit-status {
      color: #5941a9;
      background: rgba(89, 65, 169, 0.1);
    }
    .card-status {
      background-color: #fbebca;
      color: #93370d;
    }
    .colorize-green {
      color: #0c9923;
    }
    .colorize-red {
      color: #d90b0b;
    }
    .colorize-yellow {
      color: orange;
    }
    .completed {
      background-color: rgba(0, 196, 140, 0.07);
      color: #05603a;
      width: 100%;
      padding: 5px 7.5px;
      border-radius: 100px;
      font-weight: 500;
      font-size: 11px;
      text-transform: capitalize;
      font-family: Inter_Regular;
      text-align: center;
    }
    .in-progress,
    .processing,
    .pending {
      background-color: rgba(255, 251, 241, 1);
      color: #93370d;
      width: 100%;
      padding: 5px 7.5px;
      border-radius: 100px;
      font-weight: 500;
      font-size: 11px;
      text-transform: capitalize;
      font-family: Inter_Regular;
      text-align: center;
    }
    .failed {
      background-color: rgba(254, 205, 202, 0.3);
      color: #912018;
      width: 100%;
      padding: 5px 7.5px;
      border-radius: 100px;
      font-weight: 500;
      font-size: 11px;
      text-transform: capitalize;
      font-family: Inter_Regular;
      text-align: center;
    }
    .initiated {
      background-color: rgba(245, 244, 249, 1);
      color: rgba(89, 65, 169, 1);
      width: 100%;
      padding: 5px 7.5px;
      border-radius: 100px;
      font-weight: 500;
      font-size: 11px;
      text-transform: capitalize;
      font-family: Inter_Regular;
      text-align: center;
    }
    .table-toggle {
      width: 35%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: translateY(1em);
      div {
        width: fit-content;
      }
    }
    .img-column {
      width: 4em;
    }
    .pagination-table {
      display: flex;
      justify-content: flex-end;
    }
    @media screen and (max-width: 700px) {
      font-size: 0.95em;
    }
    @media screen and (max-width: 500px) {
      font-size: 0.85em;
    }
    .inactiveBtn {
      opacity: 0.2;
    }
    .table-cover-top {
      padding: 0px !important;
    }
    .pending_loan_dropdown_cover {
      position: absolute;
      z-index: 4000;
      right: 0;
      width: 170px;
      top: 65%;
      font-size: 13px;
    }
  }
  .empty-div {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    .empty-cover {
      flex-direction: column;
      width: 350px;
      .empty-icon {
        margin-bottom: 1.5em;
      }
      h5 {
        width: 100%;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 1.5em;
        color: #191d21;
        text-align: center;
        margin: 0;
      }
      span {
        width: 100%;
        font-family: "Inter_Regular";
        font-size: 0.8em;
        text-align: center;
        color: #656f77;
        margin-bottom: 0em;
      }
    }
  }
  &::-webkit-scrollbar {
    height: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background: none;
    border-radius: 10px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: silver;
    border-radius: 10px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: silver;
  }
}
