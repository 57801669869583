.verify-email-successful-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: auto;
  &-div {
    &-icon {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
    }
    &-title {
      font-size: 32px;
      line-height: 38px;
      color: #312360;
      font-weight: 400;
      font-family: Calibre_Regular;
      text-align: center;
      margin-bottom: 16px;
    }
    &-subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #616e7c;
      font-family: Calibre_Regular;
      text-align: center;
      margin-bottom: 32px;
    }
    &-button {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      &-inner {
        width: 78%;
      }
    }
    &-signin {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 400;
      color: #667085;
      text-align: center;
      font-family: Calibre_Regular;
      span {
        color: #523b9f;
        text-decoration: underline;
        cursor: pointer;
        font-family: Calibre_Regular;
      }
    }
  }
}
