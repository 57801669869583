.payment_details_cover {
  width: 438px;
  margin-top: 32px;
  @media (max-width: 650px) {
    width: 100%;
  }
  .payment_link_custom_url_cover {
    display: flex;
    .custom_url_prefix {
      // padding: 10px 16px;
      border-radius: 3px 0px 0px 3px;
      background: #101828;
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 16px;
      padding-right: 16px;
      /* identical to box height, or 143% */
      color: #ffffff;
    }
    .custom_url_input {
      flex-grow: 1;
    }
  }
  .payment_link_check {
    margin: 0 0 34px 0;
    > .checkbox-new {
      height: unset;
    }
    span {
      color: #101828;
      font-family: "Inter_Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0 0 0 5px;
    }
  }
  .payment_link_product_options {
    .payment_link_product_title {
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      /* Gray/Gray 900 */
      color: #101828;
      margin-bottom: 12px;
    }
    .payment_link_product_subtitle {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
      color: #676e7e;
      margin-bottom: 16px;
    }
  }
  .payment_details_add_images {
    margin-top: 40px;
    border-top: 1px solid rgba(230, 231, 236, 1);
    padding-top: 40px;
    .payment_details_add_images_title {
      font-family: "Inter_Regular";
      // font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      /* Gray/Gray 900 */
      color: #101828;
      margin-bottom: 8px;
    }
    .payment_details_add_images_sub_title {
      margin-bottom: 32px;
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
      color: #676e7e;
    }
  }
  .additional_btn_and_create_btn_cover {
    display: flex;
    justify-content: flex-end;
    margin-top: 92px;
    margin-bottom: 24px;
  }
}
