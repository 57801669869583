.color_and_color_input_cover {
    margin-bottom: 12px;
    .color_input_cover {
        padding-top: 7px;
        border: 1px solid rgba(234, 236, 240, 0.7);
        border-radius: 4px;
        height: fit-content;
        padding-bottom: 1px;
        width: 100%;
        padding-left: 10px;
        // padding-right: 0px !important;
        .input_product_options {
            width: 100%;
            border: none;
            font-size: 12px;
            color: rgba(152, 162, 179, 1);
        }
        .input_product_options:focus {
            outline: none;
        }
    }
}

.double_input_add_and_remove {
    display: flex;
    .add {
        flex-grow: 1;
    }
    .remove {}
}