.kyc-page-cover {
  max-width: 809px;
  margin-top: 14px;
  padding-right: 31px;
  .kyc-render-div {
    padding-top: 33px;
  }
  .upload-new-doc {
    border: 1px solid #f2f4f7;
    border-radius: 4px;
    width: fit-content;
    padding: 16px 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
    cursor: pointer;

    span {
      margin-top: 12px;
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 16px;
      color: #523b9f;
    }
  }
}
