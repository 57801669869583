.user_managenemt_table_cover {
    margin-top: 41px;
    .user_management_search_and_btn_cover {
        display: flex;
        @media (max-width: 650px) {
            flex-direction: column;
        }
        .user_management_search_cover {
            flex-grow: 1;
        }
        .user_management_btn_cover {
            @media (max-width: 650px) {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 16px;
            }
        }
    }
}