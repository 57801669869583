.admin-merchant-cover {
    display: flex;
    overflow-x: scroll;
    /* width */
    &::-webkit-scrollbar {
        width: 7px;
        height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background: none;
        border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: grey;
    }
    .admin-merchant-inner-cover {
        display: flex;
        .admin-tab-link {
            margin: 0px;
            margin-right: 20px;
            width: max-content;
            color: #757d8a;
            font-size: 16px;
            line-height: 24px;
            font-family: Inter_Regular;
            padding: 8px 24px 8px 24px;
            cursor: pointer;
        }
        .admin-active-tab {
            margin: 0px;
            margin-right: 20px;
            width: max-content;
            color: #5941a9;
            font-size: 16px;
            line-height: 24px;
            font-family: Inter_Regular;
            padding: 8px 24px 8px 24px;
            cursor: pointer;
            background-color: #f5f4f9;
            border-radius: 8px;
        }
    }
}