.audit-logs {
  padding-top: 30px;
  padding-left: 31px;
  padding-right: 31px;
  .summary-details-cover {
    border: 1px solid #eaecf0;
    padding: 0;
    border-radius: 6px;
    @media (max-width: 550px) {
      width: 95% !important;
    }
    .summary-header {
      border-bottom: 1px solid #f2f4f7;
      padding-bottom: 15px;
      margin-bottom: 20px;
      font-size: 16px;
      color: #121212;
      font-weight: 500;
      font-family: Inter_Regular;
      line-height: 19.36px;
    }
  }
}

.audit-log-wrapper {
  justify-content: space-between;
  .audit-log-header {
    margin-bottom: 35px;
    .audit-log-body {
      max-height: 600px;
      overflow-y: scroll;
    }
    .audit-log-header-wrapper {
      margin-bottom: 13px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 22px 22px 0 22px;
      .title-and-search-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;

        @media (max-width: 927px) {
          align-items: flex-start;
          flex-direction: column;
        }
        .title-cover {
          display: flex;
          align-items: flex-end;
          margin-right: 40px;
          h5 {
            margin: 0;
            font-family: "Inter_SemiBold";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000e29;
            padding-bottom: 12px;
          }
          small {
            font-family: Inter_Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: #98a2b3;
            margin-left: 0.5em;
            margin-bottom: 0.7em;
          }
        }
        .subtitle {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #667085;
        }

        .input-with-export-button {
          width: 70%;

          @media (max-width: 650px) {
            width: 100% !important;
          }
          .search-box {
            @media (max-width: 750px) {
              margin-top: 28px;
              width: 100%;
            }
          }
        }
        .input-with-no-export-button {
          width: 70%;

          @media (max-width: 650px) {
            width: 100% !important;
          }
          .search-box {
            @media (max-width: 750px) {
              margin-top: 16px;
              width: 100%;
            }
          }
        }
      }
      .children-and-export-button {
        display: flex;
        @media (max-width: 750px) {
          flex-direction: column;
        }
        .children-btn-on-mobile {
          display: none;
          @media (max-width: 750px) {
            display: block;
          }
          div {
            display: flex;
            justify-content: flex-end;
          }
        }
        .children-btn-on-desktop {
          display: block;
          @media (max-width: 750px) {
            display: none;
          }
        }
        .export-and-filter {
          align-items: flex-end;
          .app-buttons {
            span {
              color: #475467;
            }
          }
        }
      }
      .no-children-with-export-button {
        padding-top: 30px;
      }
    }
  }
  .about-audit-activity-wrapper {
    margin-top: 13px;
    animation-name: animate;
    animation-duration: 0.6s;
    animation-timing-function: ease-out;

    @keyframes animate {
      from {
        transform: scale(0.5);
      }
      to {
        transform: scale(1);
      }
    }
  }
}
.no-more-container {
  padding: 0 16px 16px;
  span {
    font-family: "Inter_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
    color: #90a0c2;
  }
}

.empty-record {
  border: none;
}
