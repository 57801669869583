.not-allowed {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  flex-wrap: wrap-reverse;
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 400px;
    h3 {
      font-size: 32px;
    }
  }
}
