.coming-soon-cont-cover{
  padding-left: 40px;
  padding-right: 40px;

  .clock-icon-cover{

    margin-top: 22px;
    text-align: center;
    margin-bottom: 24px;
  }
  .coming-soon-title{
    color: #211740;
    text-align: center;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 500;
    font-family: Inter_Regular;
    margin-bottom: 16px;
  }
  .coming-soon-subtitle{
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #676E7E;
    font-family: Calibre_Regular;
    margin-bottom: 24px;

  }
}