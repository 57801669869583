.ad-merchant-details-header-cover{
  display: flex;
  padding-right: 47px;
.title{
  flex-grow: 1;
  font-family: Inter_Regular;
  font-weight: 400;
  color: #667085;
}
.list-icon{
  position: relative;
  .list-icon-dropdown{
    position: absolute;
    right: 0;
    width: 170px;
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.25));
  }
}

}