.loan-summary-cover {
  margin-top: 39px;
  margin-bottom: 39px;
  .loan-summary-title-and-button-div {
    display: flex;
    flex-wrap: wrap;
  }
  .loan-summary-button-and-process-refund-button-div {
    padding-bottom: 10px;
    // border-bottom: 1px solid #f2f4f7;
    // margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    .loan-more-button {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid #e6e7ec;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #fff;
      .admin-user-dropdown-cover {
        position: absolute;
        width: 151px;
        z-index: 1000;
        top: 55px;
        right: 0px;
      }
    }
    .loan-summary-title {
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #121212;
      flex-grow: 1;
    }
  }
  .loan-summary-amount-and-status {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    .loan-summary-amount {
      margin-right: 16px;
      .loan-summary-amount-label {
        margin-bottom: 4px;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #98a2b3;
      }
      .loan-summary-amount-div {
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 23px;
        line-height: 28px;
        color: #261940;
      }
    }
    .status {
      width: fit-content;
      padding: 5px 12px 5px 12px;
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      border-radius: 100px;
      text-transform: capitalize;
    }
    .APPROVED {
      background: rgba(0, 196, 140, 0.07);
      color: #05603a;
    }
    .ONGOING {
      background-color: #fbebca;
      color: #93370d;
    }
    .PENDING {
      background-color: #fbebca;
      color: #93370d;
    }
    .CANCELLED,
    .PAAD_PENDING {
      background: #d9dbde;
      color: #2f394b;
    }
    .AWAITING_APPROVAL {
      background: rgba(156, 117, 208, 0.1);
      color: #5941a9;
    }
  }
  .dropdown-details-list {
    position: absolute;

    background: #ffffff;
    box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
    border-radius: 4px;
    top: 0% !important;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    div {
      font-size: 12px;
      line-height: 12px;
      font-family: Inter_Regular;
      font-weight: 400;
      padding: 8px 8px 9px 8px;
      height: 32px;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
    //   div:hover{
    //     background:rgba(220, 216, 236, 0.3);
    //     color: #523B9F !important;

    //     span{
    //       svg{
    //         path{
    //           fill:#523B9F;
    //         }
    //       }
    //     }
    //   }
    div:hover {
      color: #05603a !important;
      background-color: rgba(220, 216, 236, 0.3);

      span {
        svg {
          fill: #05603a !important;
          path {
            // fill:inherit !important;
            stroke: #05603a !important;
          }
        }
      }
    }
    div:first-child:hover {
      background-color: white;
      color: #b42318 !important;
      span {
        svg {
          path {
            fill: none !important;
            stroke: #b42318 !important;
          }
        }
      }
    }
  }
}
