// .summary-details-cover {
//   max-width: 1000px;
// }

.item-comp-cover {
  .items-comp-title {
    border-bottom: 1px solid #f2f4f7;
    padding-bottom: 19px;
    font-size: 16px;
    line-height: 19.36px;
    font-family: Inter_Regular;
    font-weight: 400;
    color: #211740;
  }

  .item-comp-body {
    margin-top: 27px;
    .item-comp-details-cover {
      display: flex;
      min-width: 300px;
      @media (max-width: 400px) {
        min-width: 220px;
      }
      width: 50%;
      flex-grow: 1;
      margin-bottom: 16px;
      // padding-left: 30px;
      .img-wrap {
        box-sizing: border-box;
        background-color: #f7f7f7;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        min-width: 50px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 10px;
        svg {
          width: 30px;
          height: 30px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
      aside {
        margin-left: 10px;
        flex-grow: 1;
        border: none;
        flex-grow: 1;

        align-items: center;
        :last-child {
          margin-bottom: 0px !important;
        }
        .item-comp-details-title {
          color: #727272;
          font-size: 14px;
          font-weight: 400;
          font-family: Inter_Medium;
          text-transform: capitalize;
        }
        .item-comp-details-value {
          color: #1a1a1a;
          font-size: 14px;
          font-weight: 500;
          font-family: Inter_Medium;
          margin-left: 14px;
        }
        span {
          color: #98a2b3;
          font-size: 12px;
          font-weight: 500;
          font-family: Inter_Medium;
          margin-bottom: 8px;
        }
        .item-comp-details-options-cover {
          display: flex;
          small {
            color: #98a2b3;
            font-size: 12px;
            font-weight: 500;
            font-family: Inter_Medium;
            border-right: 1px solid #e8e8e8;
            padding: 0 8px;
            text-transform: capitalize;
          }
          small:last-child {
            border-right: none;
          }
          small:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}
