.onboarding-cover {
  .onboarding-card {
    // margin-left: 9rem;
    height: fit-content;
    border-color: #e6e7e9;
    border-radius: 10px;
    padding-bottom: 3rem;
    position: relative;

    .form {
      margin-top: 2.3rem;
    }

    h2 {
      font-size: 1.25rem;
      color: #272643;
      padding: 1rem 1rem;
      border-bottom: 1px solid #e6e7e9;
      font-family: "Avenir-Heavy";
    }
  }

  .bus-info {
  }

  .onboarding-textarea {
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    margin-bottom: 1rem;
    outline: none;
    height: 7rem;
    padding: 1rem;
    resize: none;
    width: 100%;

    &::placeholder {
      font-family: "Avenir-Medium";
      color: #a6aab4;
    }
  }
}

.onboarding-form {
  width: 30.5rem;
}

.steps {
  width: 25%;
  max-width: 350px;
  flex-grow: 1;
  min-width: 200px;
  // height: 85vh; // experiment with this value, try changing to 110vh
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 5%;
}

.error-comp {
  width: 100%;
  margin: 0.5em auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.merchant-onboarding-cover {
  margin-top: 64px !important;
  margin-left: 64px !important;
  margin-right: 64px !important;
  max-width: 1100px;
  &-header {
    display: flex;
    margin-bottom: 29px !important;
    flex-wrap: wrap;
    justify-content: space-between;
    &-step {
      max-width: 709px;
      flex-grow: 1;
      // margin-bottom: 49px !important;
      border-bottom: 1px solid #f2f4f7;
      margin: 0;
      margin-right: 32px;
      margin-bottom: 20px;
    }
    .send-for-activation {
      margin-bottom: 20px;
      max-width: 260px;
      min-width: 220px;
      // width: 100%;
      flex-grow: 1;
      background: rgba(246, 254, 249, 0.81);
      padding: 20px;
      border: 1px solid #d1fadf;
      box-shadow: -5px 1px 15px -5px rgba(58, 125, 5, 0.05);
      border-radius: 6px;
      @media (max-width: 1100px) {
        max-width: 400px;
      }
      h5 {
        font-family: "Inter_Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #054f31;
        text-align: left;
        margin: 0;
        padding: 0;
      }
      .span-text {
        display: block;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 140%;
        color: #475467;
        margin: 14px 0 27px;
        text-align: left;
        padding: 0;
      }
      button {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        height: 36px;
        width: 100%;
        border-radius: 4px;
        background: #12b76a;
        span {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
        }
      }
    }
  }
  &-content {
    max-width: 709px;
  }
}

@media (max-width: 650px) {
  .merchant-onboarding-cover {
    margin-top: 64px !important;
    margin-left: 25px !important;
    margin-right: 25px !important;
    &-step {
      max-width: 709px;

      border-bottom: 1px solid #f2f4f7;
      margin-bottom: 49px !important;
    }
  }
}
