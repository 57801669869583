.audit-active {
  background-color: #ecf2ff;
  color: #5941a9 !important;
  font-size: 14px !important;
}

.audit-comp-wrapper {
  min-height: 86px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  border-bottom: 1px solid #f8f9fb;
  cursor: pointer;
  color: #000e29;
  transition: all 0.8s;
  &:hover {
    background-color: #ecf2ff;
    color: #5941a9;
    font-size: 13px;
  }
  .single-user-activity {
    max-width: 500px;
    display: flex;
    .connector {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-left: 22px;
      .circle {
        margin-top: 17px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f7f8fb;
        filter: drop-shadow(-2px 2px 6px rgba(58, 70, 93, 0.11965));
        z-index: 2;
        .circle-inner {
          width: 8px;
          height: 8px;
          background: #5941a9;
          border-radius: 50%;
        }
      }
      .border-line {
        width: 1.26px;
        height: 100%;
        background: #e7edf3;
        position: absolute;
        z-index: 1;
      }
    }
    .activity-info {
      padding-left: 35px;
      padding-right: 16px;
      padding-top: 17px;
      padding-bottom: 17px;
      .activity-text {
        text-transform: capitalize;
        margin-bottom: 12px;
        display: block;
        font-family: "Inter_Regular";
        font-weight: 400;
        font-style: normal;
        line-height: 16px;
        font-size: 13px;
        color: #90a0c2;
      }
      .activity-status {
        max-width: fit-content;
        span {
          font-family: "Inter_Regular";
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.3px;
          text-transform: lowercase;
          color: "#000E29";
        }
      }
      .style-failed {
        background: rgba(254, 205, 202, 0.3);
        span {
          color: #912018;
        }
      }
      .style-not-available {
        background: rgba(220, 216, 236, 0.2);
        span {
          color: #475467;
        }
      }
    }
  }
  .activity-period {
    min-width: 120px;
    padding: 17px 22px;
    .activity-time {
      font-family: "Inter_Regular";
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
      font-size: 13px;
      color: #90a0c2;
    }
  }
}

.date-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  span {
    font-family: "Inter_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #3f588d;
  }
}
