.settlement-flashcard-new {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  padding: 28px;
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 262px;
  width: 100%;
  min-width: 160px;
  @media (max-width:1170px) {

    min-width: 262px;
  }
  .card-content {
    margin-left: 1em;
    h6 {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 13px;
      color: #98a2b3;
      text-transform: capitalize;
      margin-bottom: 8px;
    }
    .card-amount {
      strong {
        font-size: 24px;
        color: #475467;
        font-family: "Calibre_Medium";
        font-weight: 500;
        line-height: 28px;
      }
      small {
        font-size: 18px;
        color: #98a2b3;
        font-family: "Calibre_Medium";
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
}

.colored-flashcard {
  background: #5941a9;
  .card-content {
    h6 {
      color: #d0d5dd;
    }
    .card-amount {
      strong {
        color: #fff;
      }
      small {
        color: #fff;
      }
    }
  }
}
