.dropdown-details-list {
  position: absolute;


  background: #ffffff;
  box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
  border-radius: 4px;
  top: 0% !important;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

  div {

    font-size: 12px;
    line-height: 12px;
    font-family: Inter_Regular;
    font-weight: 400;
    padding: 8px 8px 9px 8px;
    height: 32px;
    cursor: pointer;
    span {
      margin-right: 10px;

    }

  }
  div:hover{
    background:rgba(220, 216, 236, 0.3);
    color: #523B9F !important;

    span{
      svg{
        path{
          fill:#523B9F;
        }
      }
    }
  }
  div:last-child:hover{
    color: #B42318 !important;
    background-color: white;
    span{
      svg{
        fill:#B42318 !important;
        path{
          // fill:inherit !important;
          stroke:#B42318 !important;
        }
      }
    }
  }
}
.special-dropdown-details-list{
  position: absolute;


  background: #ffffff;
  box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
  border-radius: 4px;
  top: 0% !important;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

  div {

    font-size: 12px;
    line-height: 12px;
    font-family: Inter_Regular;
    font-weight: 400;
    padding: 8px 8px 9px 8px;
    height: 32px;
    cursor: pointer;
    span {
      margin-right: 10px;


    }

  }
  div:hover{
    background:rgba(220, 216, 236, 0.3);
    color: #523B9F !important;

    // span{
    //   // svg{
    //   //   path{
    //   //     fill:#523B9F;
    //   //   }
    //   // }
    // }
  }
  // div:last-child:hover{
  //   color: #B42318 !important;
  //   background-color: white;
  //   span{
  //     svg{
  //       fill:#B42318 !important;
  //       path{
  //         // fill:inherit !important;
  //         stroke:#B42318 !important;
  //       }
  //     }
  //   }
  // }
}