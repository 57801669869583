.merchant-profile-cover {
  padding: 40px !important;
  max-width: 1100px;
  width: 100%;
  @media (max-width:650px) {
    padding: 20px !important;
  }
  &-content-row {
    padding-bottom: 21px !important;
    border-bottom: 1px solid #f2f4f7 !important;
    margin-bottom: 41px !important;
    &-left-col {
      @media (max-width:767px) {
        display: flex;
        // width: 100%;

      }
    &-div{
      @media (max-width:767px) {
       flex-grow: 1;
      width: 80%;
      margin-bottom: 24px;
      }
        &-title {
          color: #1d2939;
          line-height: 20px;
          font-weight: 400;
          font-family: Calibre_Regular;
          font-size: 20px;
          margin-bottom: 16px;
        }
        &-subtitle {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: Calibre_Regular;
          color: rgba(102, 112, 133, 0.8);
          margin-bottom: 4px;
          width: 55%;
          @media (max-width:650px) {

           width: 100%;
           }
        }
      }

      &-edit-button {
        color: #523b9f;
        font-size: 13px;
        font-family: Inter_Regular;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;

      }
    }
    &-right-col {
      &-form-row {
        .merchant-profile-form-left-div {
          padding-right: 8px !important;
        }
        .merchant-profile-form-right-div {
          padding-left: 8px !important;
        }
        .merchant-profile-form-right-div-button {
          text-align: right;
        }
      }
    }
  }
  &-change-password-row {
    padding-bottom: 41px !important;
    border-bottom: 1px solid #f2f4f7 !important;
    margin-bottom: 41px !important;
    &-content {
      .change-password-row-content-title {
        font-size: 20px;
        color: #1d2939;
        font-weight: 400;
        line-height: 20px;
        font-family: Calibre_Regular;
        margin-bottom: 16px;
      }
      .change-password-row-content-subtitle {
        font-size: 16px;
        color: rgba(102, 112, 133, 0.8);
        font-weight: 400;
        line-height: 20px;
        font-family: Calibre_Regular;
        width: 55%;
        @media (max-width:650px) {
          width: 70%;
          margin-bottom: 19px;
          }
      }
    }
  }
  &-avater-row {
    &-content {
      &-title {
        color: #1d2939;
        font-size: 20px;
        line-height: 20px;
        font-family: Calibre_Regular;
        font-weight: 400;
        margin-bottom: 16px;
      }
      &-subtitle {
        font-size: 16px;
        color: rgba(102, 112, 133, 0.8);
        font-weight: 400;
        line-height: 20px;
        font-family: Calibre_Regular;
        width: 38%;
        @media (max-width:650px) {
         width: 50%;
         margin-bottom: 24px;

        }
      }
    }
    &-avater-upload-cover {
      display: flex;
      @media (max-width:767px) {
        flex-direction: column;

      }
      .avater-image-component-cover {
        margin-right: 48px;
        @media (max-width:767px) {
          justify-content: center;
          display: flex;
          margin-right: 0px;
          margin-bottom: 24px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
      .avater-drag-and-drop-comp {
        flex-grow: 1;
      }
    }
  }
}
