.modal-cancel {
  // display: flex;
  // justify-content: flex-end;
  // margin-bottom: 16px;
  position: absolute;
  top: -40px;
  right: 1.3rem;
  &-icon-cover {
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.modal-card {
  padding: 0;
  max-height: 700px;
  overflow-y: auto;
  // width: 30.5rem;
  max-width: 440px;
  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
  }
  background-color: white;
  border-radius: 6px;
  .svg {
    margin: 0 auto;
  }
  a {
    margin: 0 auto;
    font-size: 0.8rem;
  }
  textarea {
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    margin-bottom: 1rem;
    outline: none;
    height: 7rem;
    padding: 1rem;
    resize: none;
  }
  textarea::placeholder {
    font-family: "Inter_Regular";
    color: #a6aab4;
  }
  button {
    border: none;
    // margin-bottom: 2rem;
  }
  p {
    font-family: "Inter_Regular";
    color: #667e93;
    font-size: 0.9em;
    // padding-top: 0.8rem;
  }
  .btn-design {
    font-size: 0.85em;
    margin: 0 auto;
    text-align: center;
    .act-btn {
      font-weight: 800;
      color: #5941a9;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  h2 {
    // margin-bottom: 1em;
    font-size: 16px;
    color: #211740;
    padding: 16px 32px 4px 32px;
    font-family: "Inter_Medium";
    text-align: left;
    font-weight: 500;
    .title-style {
      font-family: "Inter_Medium";
    }
  }
  &-subtitle {
    color: #667085;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2%;
    font-weight: 400;
    font-family: "Inter_Regular";
    border-bottom: 1px solid #f9fafb;
    margin-bottom: 17px;
    padding: 0px 32px 15px 32px;
  }
  .form-content {
    padding: 24px;
  }
  .modal-footer {
    border-top: 1px solid #f9fafb;
    .form-content-button-div {
      display: flex;
      justify-content: flex-end;
      height: 40px;
      span {
        margin-right: 16px;
      }
    }
  }
}

.admin-confirmation-modal {
  padding: 0;
  // width: 30.5rem;
  max-width: 346px;
  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
  }
  background-color: white;
  border-radius: 6px;
  .svg {
    margin: 0 auto;
  }
  a {
    margin: 0 auto;
    font-size: 0.8rem;
  }
  // textarea {
  //   border: 1px solid #e4e4e4;
  //   border-radius: 6px;
  //   margin-bottom: 1rem;
  //   outline: none;
  //   height: 7rem;
  //   padding: 1rem;
  //   resize: none;
  // }
  textarea::placeholder {
    font-family: "Inter_Regular";
    color: #a6aab4;
  }
  button {
    border: none;
    // margin-bottom: 2rem;
  }
  p {
    font-family: "Inter_Regular";
    color: #667e93;
    font-size: 0.9em;
    // padding-top: 0.8rem;
  }
  .btn-design {
    font-size: 0.85em;
    margin: 0 auto;
    text-align: center;
    .act-btn {
      font-weight: 800;
      color: #5941a9;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  h2 {
    // margin-bottom: 1em;
    font-size: 16px;
    color: #211740;
    padding: 16px 32px 4px 32px;
    font-family: "Inter_Medium";
    text-align: left;
    font-weight: 500;
  }
  &-subtitle {
    color: #667085;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2%;
    font-weight: 400;
    font-family: "Inter_Medium";
    border-bottom: 1px solid #f9fafb;
    margin-bottom: 17px;
    padding: 0px 32px 15px 32px;
  }
  .form-content {
    padding: 24px;
    .confirmation-title {
      text-align: center;
      font-size: 16px;
      color: #211740;
      font-weight: 500;
      font-family: Inter_Regular;
      margin-bottom: 16px;
    }
    .confirmation-subtitle {
      text-align: center;
      font-size: 14px;
      color: #676e7e;
      font-size: 400;
      font-family: Calibre_Regular;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 24px;
    }
    .confirmation-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
  .modal-footer {
    border-top: 1px solid #f9fafb;
    .form-content-button-div {
      display: flex;
      justify-content: flex-end;
      height: 40px;
      span {
        margin-right: 16px;
      }
    }
  }
}
