.pagination-comp {
  // height: 2.5em;
  // margin-top: 1em;
  background: #fff;
  box-shadow: 0px 12px 20px 3px rgba(223, 224, 235, 0.02);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 32px;
  @media (max-width:500px) {
    padding: 13px 0px 0px 0px;
  }
  .page-count {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:500px) {
      display: none;
    }
    // .page-total{

    // }
    span {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      color: #98a2b3;
      margin-left: 8px;
      display: block;
    }
    strong {
      display: block;
      font-family: "Inter_Medium";
      font-weight: 500;
      font-size: 12px;
      color: #475467;
    }
  }
  .pagination {
    height: fit-content;
    margin-bottom: 0 !important;
    .prev,
    .next {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      border-radius: 4px;
    }
    .each-page {
      height: 20px;
      width: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5em;
      a {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 12px;
        color: #98a2b3;
      }
    }
    .break-me {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-bottom: 0.5em;
      margin: 0 0.5em;
      a {
        font-family: "Inter_Regular";
        font-size: 0.9em;
        color: #98a2b3;
      }
    }
    .active {
      border-radius: 4px;
      color: #523b9f;
      a {
        color: #523b9f;
        font-family: "Inter_Medium";
      }
    }
  }
  .go-to-page {
    span {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      color: #98a2b3;
      margin-left: 8px;
      cursor: pointer;
      &:hover {
        color: #1d2939;
      }
    }

    input {
      border: 1px solid #f2f4f7;
      box-sizing: border-box;
      border-radius: 2px;
      width: 21px;
      height: 21px;
      color: #1d2939;
      margin-left: 12px;
      text-align: center;
      font-size: 10px;
      outline: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
