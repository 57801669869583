.api-copy-comp-cover{
  &-title-and-action-cover{
    display: flex;
    margin-bottom: 16px;
    &-title{
    flex-grow: 1;
    color: #211740;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 500;
    font-family: Calibre_Medium;
    }
    &-reset{
      color: #523B9F;
      font-size: 16px;
      line-height: 19.2px;
      font-weight: 500;
      font-family: Calibre_Medium;
      cursor: pointer;
    }
  }
  &-key-and-copy-cover{
    display: flex;
    padding: 14px 16px 14px 16px;
    border: 1px solid rgba(234, 236, 240, 0.7) ;
    border-radius: 4px;
    margin-bottom: 24px;
    cursor: pointer;
    &-key-cover{
      flex-grow: 1;
      color: #667085;
      font-size: 14px;
      line-height: 16.94px;
      font-family: Inter_Regular;
      font-weight: 400;
    }
    &-copy-cover{
      color: #523B9F;
      font-size: 14px;
      line-height: 16.94px;
      font-family: Inter_Regular;
      font-weight: 400;
      cursor: pointer;

    }
  }
}