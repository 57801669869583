.dropdown-ul {
  text-decoration: none;
  list-style: none;
  padding-left: 0;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-height: 250px;
  overflow-y: scroll;
  li {
    text-align: center;
    border-bottom: 1px solid #f5f5f5;
    padding: 1em;
    font-size: 0.9em;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
