.alt-img-name {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fa5b7c;
  width: 3.2em;
  height: 3.2em;
  border-radius: 100%;
  margin: 0 auto;
  strong {
    font-family: "Inter_Regular";
    font-weight: 800;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
}
.small-type{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fa5b7c;
  width: 16px;
  height:16px;
  border-radius: 100%;
  margin: 0 auto;
  strong {
    font-family: "Inter_Regular";
    font-weight: 800;
    font-size: 8px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
}