.loading-icon {
  width: 1em;
  height: 1em;
  border: 2px solid white;
  border-bottom-color: transparent !important;
  border-radius: 100%;
  animation: rotate 0.8s linear infinite;
  margin-left: 1em;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
