.custom-select-dropdown-cover {
    .custom-select-header {
        display: flex;
        align-items: center;
        cursor: pointer;
        .header-name {
            font-family: "Inter_Regular";
            font-size: 12px;
            color: #777777;
            margin-right: 11px;
            text-transform: capitalize;
        }
    }
    .custom-select-hidden {
        opacity: 0;
        visibility: hidden;
    }
    .custom-select-dropdown {
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: 4px;
        ul {
            z-index: 10;
            max-height: 400px;
            overflow-y: scroll;
            position: absolute;
            text-decoration: none;
            filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.1));
            right: 0;
            width: 190px;
            list-style: none;
            padding: 10px 16px;
            background: #ffffff;
            border-radius: 4px;
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0 6px;
                cursor: pointer;
                span {
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #667085;
                }
                &:hover {
                    span {
                        color: #523b9f;
                        // font-family: "Inter_Medium";
                    }
                }
            }
            .disabled-li {
                color: #667085;
                opacity: 0.3;
                &:hover {
                    span {
                        color: #667085;
                        // opacity: 0.3;
                        // font-family: "Inter_Medium";
                    }
                }
            }
            .selected {
                span {
                    color: #523b9f;
                }
            }
        }
    }
}