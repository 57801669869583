* {
  font-family: "avenir";
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background: none;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: silver;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: silver;
  }
}

@font-face {
  font-family: "avenir";
  src: local("Avenir"), url(assets/fonts/Avenir.ttf) format("ttf");
}

@font-face {
  font-family: "Avenir-Heavy";
  src: local("Avenir-Heavy"), url(assets/fonts/Avenir-Heavy.ttf) format("ttf");
}

@font-face {
  font-family: "Avenir-Medium";
  src: local("AvenirLTStd-Medium"),
    url(assets/fonts/AvenirLTStd-Medium.otf) format("otf");
}

@font-face {
  font-family: "Avenir-Black";
  src: local("AvenirLTStd-Black"),
    url(assets/fonts/AvenirLTStd-Black.otf) format("otf");
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("AvenirLTStd-Roman"),
    url(assets/fonts/AvenirLTStd-Roman.otf) format("otf");
}

@font-face {
  font-family: "Recoleta_Black";
  src: url("./assets/fonts/Recoleta_Black.otf") format("opentype");
}

@font-face {
  font-family: "Recoleta_Bold";
  src: url("./assets/fonts/Recoleta_Bold.otf") format("opentype");
}

@font-face {
  font-family: "Recoleta_SemiBold";
  src: url("./assets/fonts/Recoleta_Alt_SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Recoleta_Medium";
  src: url("./assets/fonts/Recoleta_Alt_Medium.otf") format("opentype");
}

@font-face {
  font-family: "Recoleta_Regular";
  src: url("./assets/fonts/Recoleta_Alt_Regular.otf") format("opentype");
}
@font-face {
  font-family: "Lato_Regular";
  src: url("./assets/fonts/Lato-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Lato_Bold";
  src: url("./assets/fonts/Lato-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "Lato_Light";
  src: url("./assets/fonts/Lato-Light.ttf") format("opentype");
}
@font-face {
  font-family: "Calibre_Thin";
  src: url("./assets/fonts/CalibreThin.otf") format("opentype");
}
@font-face {
  font-family: "Calibre_Regular";
  src: url("./assets/fonts/CalibreRegular.otf") format("opentype");
}
@font-face {
  font-family: "Calibre_Medium";
  src: url("./assets/fonts/CalibreMedium.otf") format("opentype");
}
@font-face {
  font-family: "Calibre_Bold";
  src: url("./assets/fonts/CalibreBold.otf") format("opentype");
}
@font-face {
  font-family: "Calibre_Semibold";
  src: url("./assets/fonts/CalibreSemibold.otf") format("opentype");
}
@font-face {
  font-family: "Inter_Thin";
  src: url("./assets/fonts/Inter-Thin.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Light";
  src: url("./assets/fonts/Inter-Light.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Regular";
  src: url("./assets/fonts/Inter-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Medium";
  src: url("./assets/fonts/Inter-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Bold";
  src: url("./assets/fonts/Inter-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Semibold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Extrabold";
  src: url("./assets/fonts/Inter-ExtraBold.ttf") format("opentype");
}
@font-face {
  font-family: "Inter_Extralight";
  src: url("./assets/fonts/Inter-ExtraLight.ttf") format("opentype");
}
$primary: #5941a9;
$secondary: #212127;
$danger: #ff4136;

.MuiFilledInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.table-bordered {
  border-collapse: separate;
  border-spacing: 0 10px;
  border: none;
}

.table-bordered {
  border: none !important;
}

// table td {
//   border: 10px solid white;
//   border-right: 0;
//   border-left: 0;
// }

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
}

h3 {
  font-family: "Avenir-Heavy";
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

.table-bordered th {
  border: none !important;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: 14px;
  color: #a6aab4;
  font-family: Avenir-Book;
  border-bottom-width: 2px;
}

.table-bordered th,
.table-bordered td {
  border-top: none;
}

.error-flag {
  font-size: 12px;
  color: rgb(160, 70, 70);
  font-family: Avenir-Medium;
}

@import "../node_modules/bootstrap/scss/bootstrap";

.phone-input-comp {
  width: 27.3rem;
  display: flex;
  .text-wrap {
    flex-grow: 1;
    border-left: none;
  }
  .phonenumber-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0.7em;
    // width: 100%;
    span {
      font-weight: 500;
    }
  }
}

.p-6 {
  padding: 1.5em;
}

.p-8 {
  padding: 2em;
}
.py-2 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.py-4 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.pt-4 {
  padding-top: 1em;
}
.ml-2 {
  margin-left: 0.5em;
}

.mr-4 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 0.5em;
}

.ml-4 {
  margin-left: 1em;
}

.flexed-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexed-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flexed-row {
  display: flex;
}

.flexed-row-center {
  display: flex;
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.logo-upload {
  margin-top: 2em;
  input {
    display: none;
  }
  label {
    cursor: pointer;
  }
  .link-upload {
    cursor: pointer;
    font-family: "Avenir";
    font-size: 0.8em;
    letter-spacing: -0.5px;
    text-decoration-line: underline;
    color: #5941a9;
    // border-bottom: 1px solid #5941A9;
    width: fit-content;
    margin: 0 auto;
  }
  img {
    width: 6.5em;
    height: 6.5em;
    object-fit: contain;
  }
}

.flex-inputs {
  display: grid;
  grid-template-columns: 49% 49%;
  width: 100%;
  grid-gap: 2%;
}

.country-phone-inp {
  border-radius: 0 5px 5px 0 !important;
  border-left: none !important;
}

.app-modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0);
}

.ml-1 {
  margin-left: 0.5em;
}

.css-19n335a-ToastContainer {
  z-index: 1000;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  h6 {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3em;
    line-height: 18px;
    color: #272643;
    margin: 0;
    margin-left: 0.5em;
  }
}

.w-full {
  width: 100%;
}

.flexed-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-comp {
  display: flex;
  align-items: flex-end;
  .label-ref {
    display: flex;
    align-items: center;
    padding-bottom: 0.1em;
    h6 {
      margin: 0;
      margin-left: 0.1em;
      font-family: "Recoleta_SemiBold";
      font-weight: 600;
      font-size: 0.7em;
      color: #272643;
    }
  }
}

.disabled-link {
  cursor: not-allowed;
  opacity: 0.3;
}

.hidden {
  visibility: hidden;
}

.label-header {
  margin-bottom: 1em;
  margin-left: 0.5em;
  .name-title {
    font-size: 1.3em;
    color: #252733;
    font-family: "Recoleta_Bold" !important;
    letter-spacing: 0.3px;
    margin: 0 0.2em;
    text-transform: capitalize;
  }
  .arrow-div {
    height: 100%;
    margin-top: 0.2em;
  }
}

.py-8 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.mb-8 {
  margin-bottom: 2em !important;
}

.pointer {
  cursor: pointer;
  svg {
    cursor: pointer;
  }
}

.flex-grow {
  flex-grow: 1;
}

.twin-buttons-cover {
  max-width: 370px;
  margin: 2.4em auto 0;
  width: fit-content;
  display: flex;
  .app-buttons {
    font-size: 1em;
    margin-bottom: 0;
    padding: 0.5em 1.6em;
    border-radius: 8px;
    span {
      font-size: 0.8em;
    }
  }
  .cancel-type {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    color: #344054;
  }
  .save-type {
    background: #5941a9;
    border: 1px solid #5941a9;
    color: white;
    margin-left: 0.7em;
  }
}

.status-styles {
  .enabled-default,
  .active-status,
  .inactive-status,
  .out-of-stock,
  .pending-status,
  .transit-status,
  .credit-status,
  .debit-status,
  .defaulting-status,
  .not-due-status,
  .card-status {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 73px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 100px;
    font-size: 10px;
    width: 73px;
    height: 22px;
  }

  .active-status {
    color: #05603a;
    background: rgba(0, 196, 140, 0.07);
  }

  .defaulting-status {
    color: #312360;
    background: rgba(220, 216, 236, 0.3);
  }

  .not-due-status {
    color: #344054;
    background: #f2f4f7;
  }

  .enabled-default {
    background-color: #dceddd;
    // border-color: #2dad31;
    color: #2dad31;
  }

  .inactive-status {
    background: rgba(254, 205, 202, 0.3);
    color: #912018;
  }

  .out-of-stock {
    background-color: #f1f5ff;
    color: #a6aab4;
  }

  .pending-status {
    background: #fffbf1;
    color: #93370d;
  }

  .credit-status {
    color: #5941a9;
    background: rgba(89, 65, 169, 0.1);
  }

  .card-status {
    background-color: #fbebca;
    color: #e3a11b;
  }

  .colorize-green {
    color: #0c9923;
  }

  .colorize-red {
    color: #d90b0b;
  }

  .colorize-yellow {
    color: orange;
  }
}

.settings-btn-cover {
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 15px;
  // border: 1px solid silver !important;
}

.css-nvf14r-ToastContainer {
  z-index: 100000;
}

.general-loader {
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;

  .dot-loader {
    // transform: scale(0.5);
  }
}

.custom-nav-style {
  display: flex;
  border-bottom: 1px solid #f2f4f7;
  margin-left: 0;
  margin-right: 0;
  margin-top: 24px;
  @media (max-width: 689px) {
    overflow-x: scroll;
  }
  .active-nav-cover {
    margin-right: 23px;
    border-bottom: 2px solid white;
    .navrouter-link-comp {
      text-decoration: none;
      p {
        margin-bottom: 16px;
        font-size: 16px;
        color: #98a2b3;
        font-family: "Inter_Regular" !important;
        font-weight: 400;
        @media (max-width: 650px) {
          width: max-content;
        }
      }
    }
  }

  .settings-active-nav-cover {
    border-bottom: 2px solid #523b9f;
    .navrouter-link-comp {
      p {
        color: #523b9f;
      }
    }
  }
}

.css-nvf14r-ToastContainer {
  z-index: 999999999 !important;
}
