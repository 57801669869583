.settings {
  padding: 66px 27px 27px 30px;
  @media screen and (max-width: 500px) {
    padding: 24px;
  }

  .title {
    font-family: "Calibre_Regular";
    font-weight: 400;
    font-size: 24px;
  }

  .change-password {
    .change-password-cover {
      padding: 60px 0;
      border-bottom: 1px solid #f2f4f7;
      max-width: 920px;
      h6 {
        font-family: "Calibre_Regular";
        font-weight: 400;
        font-size: 20px;
        color: #1d2939;
        margin: 0;
        margin-bottom: 37px;
      }
    }
    .modalwrapcomp {
      .content-in-modal {
        width: 100%;
        max-width: 450px;
      }
    }
  }
}
