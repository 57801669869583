.dashboard-header {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  .title-and-search-wrap {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;

    @media (max-width: 927px) {
      align-items: flex-start;
      flex-direction: column;
    }
    .title-cover {
      display: flex;
      align-items: flex-end;
      margin-right: 40px;
      h5 {
        font-size: 24px;
        color: #211740;
        font-family: "Calibre_Regular";
        margin: 0;
      }
      small {
        font-family: Inter_Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #98a2b3;
        margin-left: 0.5em;
        margin-bottom: 0.7em;
      }
    }
    .subtitle {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #667085;
    }

    .input-with-export-button {
      width: 70%;

      @media (max-width: 650px) {
        width: 100% !important;
      }
      .search-box {
        @media (max-width: 750px) {
          margin-top: 28px;
          width: 100%;
        }
      }
    }
    .input-with-no-export-button {
      width: 70%;

      @media (max-width: 650px) {
        width: 100% !important;
      }
      .search-box {
        @media (max-width: 750px) {
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }
  .children-and-export-button {
    display: flex;
    @media (max-width: 750px) {
      flex-direction: column;
    }
    .children-btn-on-mobile {
      display: none;
      @media (max-width: 750px) {
        display: block;
      }
      div {
        display: flex;
        justify-content: flex-end;
      }
    }
    .children-btn-on-desktop {
      display: block;
      @media (max-width: 750px) {
        display: none;
      }
    }
    .export-and-filter {
      align-items: flex-end;
      .app-buttons {
        span {
          color: #475467;
        }
      }
    }
  }
  .no-children-with-export-button {
    padding-top: 30px;
  }
}
.add-flex-direction {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // margin-bottom: 32px;
  // .title-and-search-wrap{

  //   width: 100%;
  // }
}
@media (max-width: 750px) {
  .dashboard-header {
    margin-bottom: 32px;
    display: flex;

    justify-content: space-between;
    align-items: flex-start;

    .title-and-search-wrap {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-right: 12px;
      width: 246px;
      .title-cover {
        display: flex;
        align-items: flex-end;
        margin-right: 40px;
        width: 100%;
        h5 {
          font-size: 24px;
          color: #211740;
          font-family: "Calibre_Regular";
          margin: 0;
        }
        small {
          font-family: Inter_Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #98a2b3;
          margin-left: 0.5em;
          margin-bottom: 0.7em;
        }
      }
      .subtitle {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #667085;
      }
    }
    .export-and-filter {
      align-items: flex-end;

      height: 100%;
      padding-top: 16px;
      .app-buttons {
        span {
          color: #475467;
        }
      }
    }
  }
  .add-flex-direction {
    flex-direction: column;
    margin-bottom: 32px;
    .title-and-search-wrap {
      width: 100%;
    }
  }
  .hide-button {
    display: none;
  }
}
