.account-statement-cover {
  padding: 32px 40px 50px 40px !important;
  @media (max-width: 650px) {
    padding: 32px 20px 50px 20px !important;
  }
  max-width: 958px;
  &-row {
    .add-account-card {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      &-inner-cover {
        width: 100%;
        .add-account-card-icon-div {
          text-align: center;
        }
        .add-account-card-content-div {
          color: #523b9f;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: Calibre_Regular;
          text-align: center;
        }
      }
    }
    &-col-inner-card {
      height: 140px;
      border: 1px solid #f2f4f7;
      border-radius: 4px;
      padding: 16px !important;
      position: relative;
      display: flex;
      flex-direction: column;

      .col-inner-card-title {
        position: relative;

        height: 24px;
        flex-grow: 1;
        &-logo {
          width: 24px;
        }
        &-list-icon {
          position: absolute;
          top: 0%;
          right: 0%;
          cursor: pointer;
        }
      }
      .col-inner-card-tag {
        font-size: 12px;
        color: #1d2939;
        letter-spacing: -0.2%;
        line-height: 18px;
        font-weight: 400;
        font-family: Inter_Regular;
        margin-bottom: 2px;
      }
      .col-inner-card-account-number-tag {
        color: #667085;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: -0.2%;
        line-height: 16px;
        font-family: Inter_Regular;
        position: relative;
        .primary-tag {
          padding: 4px;
          position: absolute;
          color: #027a48;
          top: 0%;
          background-color: rgba(0, 196, 140, 0.07);
          border-radius: 10px;
          font-size: 9px;
          line-height: 12px;
          font-family: Inter_Medium;
          font-weight: 500;
          right: 0%;
        }
      }
      .account-statement-dropdown {
        background-color: green;
        width: 106px;
        position: absolute;

        right: 15px;
        z-index: 1;
        top: 40px;
      }
    }
    &-col:nth-child(3n + 1) {
      padding-right: 12px !important;
      margin-bottom: 24px !important;
      @media (max-width: 650px) {
        padding-right: 0px !important;
      }
    }
    &-col:nth-child(3n + 2) {
      padding-right: 12px !important;
      padding-left: 12px !important;
      margin-bottom: 24px !important;
      @media (max-width: 650px) {
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
    }
    &-col:nth-child(3n + 3) {
      padding-left: 12px !important;
      margin-bottom: 24px !important;
      @media (max-width: 650px) {
        padding-left: 0px !important;
      }
    }
  }
}
.delete-bank-details {
  .modal-card {
    h2 {
      text-align: center;
    }
    .modal-message {
      display: flex;
      justify-content: center;
      span {
        text-align: center;
      }
    }
    .modal-footer {
      display: flex;
      justify-content: center;
    }
  }
}
