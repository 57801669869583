.tab-with-route-link{
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding-top: 19px;
  padding-bottom: 28px;

  overflow: auto;
  white-space: nowrap;
  .rounderd-tab-cover{

display: flex;

      .name-and-number-inactive-div{
        margin-right:32px ;
        font-size: 13px;
        font-weight: 400;
        font-family: Inter_Regular;
        color: #98A2B3;
        cursor: pointer;
        display: flex;
        align-items: center;
        .number-cover{
          border:1px solid #98A2B3;
          margin-right: 12px;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
      .name-and-number-active-div{
        margin-right:32px ;
        font-size: 13px;
        font-weight: 400;
        font-family: Inter_Regular;
        color: #5941A9;
        cursor: pointer;
        display: flex;
        align-items: center;
        .number-cover{
          border:1px solid #5941A9;
          margin-right: 12px;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }

  }
}
