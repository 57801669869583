.sdk-modal {
  padding: 0;
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  overflow-y: auto;
  @media (max-width: 650px) {
    max-width: 100%;
    width: 100%;
  }
  background-color: white;
  border-radius: 6px;
  .svg {
    margin: 0 auto;
  }

  a {
    margin: 0 auto;
    font-size: 0.8rem;
  }

  button {
    border: none;
  }

  .btn-design {
    font-size: 0.85em;
    margin: 0 auto;
    text-align: center;

    .act-btn {
      font-weight: 800;
      color: #5941a9;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .form-content {
    padding: 24px 0;
    .confirmation-title {
      text-align: center;
      font-size: 16px;
      color: #211740;
      font-weight: 500;
      font-family: Inter_Regular;
      margin-bottom: 16px;
    }
    .confirmation-subtitle {
      text-align: center;
      font-size: 14px;
      color: #676e7e;
      font-size: 400;
      font-family: Calibre_Regular;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 24px;
    }
    .confirmation-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
  .modal-footer {
    border-top: 1px solid #f9fafb;
    .form-content-button-div {
      display: flex;
      justify-content: flex-end;
      height: 40px;
      span {
        margin-right: 16px;
      }
    }
  }
  .sdk-title {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #211740;
    font-family: "Inter_Regular";
    text-align: center;
  }
  .json-cover {
    max-height: 250px;
    overflow-y: scroll;
    background-color: rgba(220, 216, 236, 0.2);
    padding: 12px;
    .react-json-view {
      background-color: transparent !important;
      .object-content {
        span,
        div {
          color: #211740;
          font-size: 12px;
          font-family: monospace;
        }
      }
    }
  }
  .buttons-cover {
    margin: 24px 24px 0 24px;
    display: flex;
    justify-content: space-between;
  }
}

.content-in-modal {
  width: 100%;
}
