.alternative_customer_number {
    width: 416px;
    background-color: white;
    border-radius: 10px;
    padding: 20px 32px;
    @media (max-width: 650px) {
        width: 100%;
    }
    .alternative_customer_title_and_icon {
        display: flex;
        border-bottom: 1px solid rgba(230, 231, 236, 0.5);
        .alternative_customer_icon {
            margin-right: 14px;
            width: 32px;
            height: 32px;
            background-color: rgba(89, 65, 169, 0.05);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .alternative_customer_title_and_subtitle {
            .alternative_customer_title {
                font-family: "Inter_Medium";
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
                /* Black */
                color: #121212;
            }
            .alternative_customer_subtitle {
                font-family: "Inter_light";
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                /* identical to box height, or 167% */
                color: #81899e;
                margin-top: 2px;
                margin-bottom: 18px;
            }
        }
    }
    .alternative_customer_btn {
        display: flex;
        justify-content: flex-end;
    }
}