.radio-comp {
  margin-bottom: 24px;
  label {
    display: flex;

    cursor: pointer;
    .radio-icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .checked {
        background-color: transparent;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }

    .radio-info {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      strong {
        font-family: "Calibre_Regular";
        font-size: 16px;
        line-height: 16px;
        color: #121212;
        margin: 0;
        margin-bottom: 8px;
        font-weight: 500;
      }
      span {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #81899e;
      }
    }
  }
  input {
    display: none;
  }

  input:checked + label .checked {
    background-color: #5941a9;
  }
  input:checked + label .checkIcon {
    border-color: #5941a9;
    background-color: #ebecfe;
  }
}
