.settlement-transaction-card-cover{

@media (max-width:1199px) {
  margin-bottom: 30px;

}
  .settlement-transaction-card{
    height: 100%;
    border: 1px solid #F2F4F7;
    border-radius: 8px;
    padding: 24px 30px 29px 30px;
    .settlement-transaction-icon-and-details-cover{
      display: flex;
      .settlement-transaction-icon-cover{
        margin-right: 22px;
      }
      .settlement-transaction-card-name{
        font-size: 13px;
        line-height: 16px;
        font-family: Inter_Regular;
        font-weight: 400;
        color: #98A2B3;
        margin-bottom: 8px;

      }
      .settlement-transaction-card-value{
        font-size: 24px;
        line-height: 28px;
        font-family: Calibre_Regular;
        font-weight: 400;
        color: #475467;
      }
    }
  }
}