.refreshingsticker {
  top: 0.2em;
  right: 0.2em;
  position: absolute;
  padding: 0.2em 0.4em;
  border-radius: 2em;
  border: 1px solid #5941a9;
  transform: scale(0.9);
  background-color: white;
  span {
    font-size: 0.75em;
    color: #5941a9;
    font-family: "Avenir-Medium";
    margin-left: 0.5em;
    margin-right: 1em;
  }
}
