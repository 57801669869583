.delete_permission_modal {
    width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    @media (max-width: 605px) {
        width: 100%;
    }
    .delete_permission_wrapper {
        .delete_permission_title {
            font-family: "Inter_Bold";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 20px;
            /* identical to box height, or 143% */
            color: #14142b;
            text-align: center;
            margin-bottom: 16px;
        }
        .delete_permission_sub_title {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */
            color: #14142b;
            text-align: center;
            margin-bottom: 16px;
            margin-bottom: 30px;
            span {
                color: #5941a9;
                font-size: 14px;
                font-family: "Inter_Bold";
            }
        }
        .delete_permission_btn {
            display: flex;
            justify-content: center;
        }
    }
}