.empty-date-field {
  position: relative;
  .date-label {
    position: absolute;
    top: 7px;
    left: 16px;
    font-size: 10px;
    color: #98a2b3;
    z-index: 1;
  }
  .react-datepicker__input-container {
    z-index: 2;
    background-color: transparent;
    input {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      border: 1px solid rgba(234, 236, 240, 0.7);
      font-size: 13px;
      padding-left: 16px;
      color: #475467;
      font-family: Inter_Regular;
      background-color: transparent;
    }
    input:focus {
      outline: none !important;
      border-color: 1px solid rgba(234, 236, 240, 0.7) !important;
    }
    input::placeholder {
      color: #a6aab4 !important;
    }
  }
  .calender_icon {
    position: absolute;
    top: 35%;
    left: calc(90% - 5px);
    z-index: 1;
  }
}

.has-date-field {
  position: relative;
  .date-label {
    position: absolute;
    top: 7px;
    left: 16px;
    font-size: 10px;
    color: #98a2b3;
    z-index: 1;
  }
  .react-datepicker__input-container {
    z-index: 2;
    background-color: transparent;
    input {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      border: 1px solid rgba(234, 236, 240, 0.7);
      font-size: 13px;
      padding-left: 16px;
      color: #475467;
      padding-top: 18px;
      font-family: Inter_Regular;
      background-color: transparent;
    }
    input:focus {
      outline: none !important;
      border-color: 1px solid rgba(234, 236, 240, 0.7) !important;
    }
  }
  .calender_icon {
    position: absolute;
    top: 35%;
    left: calc(90% - 5px);
    z-index: 1;
  }
}

.schedule_date_cover {
  position: relative;
  .react-datepicker__input-container {
    z-index: 2;
    background-color: transparent;
    input {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      border: 1px solid rgba(234, 236, 240, 0.7);
      font-size: 13px;
      padding-left: 10px;
      color: #475467;
      font-family: Inter_Regular;
      background-color: transparent;
    }
    input:focus {
      outline: none !important;
      border-color: 1px solid rgba(234, 236, 240, 0.7) !important;
    }
  }
  .calender_icon {
    position: absolute;
    top: 16%;
    left: calc(90% - 5px);
    z-index: 1;
  }
}
