.overview-contain {
  .dashboard {
    padding-bottom: 0.5em !important;
    padding-top: 0.5em !important;
  }
  .overview {
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 220px);
    padding: 40px 30px;
    .export-and-filter {
      .exports-pdf {
        display: none;
      }
    }
    .overview-card {
      background: #ffffff;
      /* Components / Border color */
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: transparent;

      .overview-title {
        margin: 0;
        padding: 1.5em 1.7em;
        text-align: left;
        font-family: "Avenir";
        font-size: 0.9em;
        color: #272643;
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .overview-flash-cards {
      display: grid;
      flex-wrap: wrap;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      // border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      // border-right: none;
      // border-bottom: none;
      justify-content: space-between;
      row-gap: 25px;
      column-gap: 40px;

      .data-comp {
        // border-bottom: 1px solid #e5e5e5;
        background-color: #feefe2;
        padding: 24px;
        height: 270px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .top-layer {
          flex-grow: 1;
          .record-and-filter {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            .custom-select-dropdown-cover {
              height: 15px;
              margin-bottom: 10px;
              .custom-select-header {
                align-items: flex-end;
                line-height: 14px;
              }
            }
            h6 {
              font-family: "Inter_Medium";
              font-size: 14px;
              color: #555555;
              margin: 0;
              margin-bottom: 10px;
              margin-right: 10px;
            }
          }
          h4 {
            font-family: "Inter_Medium";
            font-weight: 500;
            font-size: 36px;
            color: #121212;
            margin: 0 0 10px 0;
            @media (max-width: 500px) {
              font-size: 24px;
            }
          }
        }
        .bottom-layer {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          .rate-date-info {
            display: flex;
            align-items: flex-end;
            .rate {
              display: flex;
              align-items: center;
              span {
                margin-left: 3px;
                font-family: "Inter_Medium";
                font-size: 14px;
                font-weight: 500;
              }
              .loading-rate {
                color: #333;
                font-size: 18px;
              }
            }
            .vs-span {
              color: #667085;
              font-family: "Inter_Regular";
              font-size: 13px;
              margin: 0 5px;
            }
            .date-span {
              color: #5941a9;
              font-family: "Inter_Bold";
              font-size: 14px;
            }
          }
          .graph-symbol {
            margin-top: 10px;
          }
        }
      }

      @media (max-width: 500px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
    }
  }
}
