.business-detail-cover{
  .business-owner-cover{
    .business-owner-form-cover{
      margin-top: 32px;
      border-bottom: 1px solid #F2F4F7;
      padding-bottom: 30px;
    }

  }
  .residential-details-cover{
    padding-top: 33px;


    .residential-details-form-cover{
      margin-top: 32px;
      padding-bottom: 30px;
      border-bottom: 1px solid #F2F4F7;
    }
  }

}
