.form-check-cover {
    display: flex;
    font-family: Inter_Regular;
    .form-check {
        position: relative;
    }
    input[type="checkbox"] {
        width: 16px;
        height: 16px;
    }
    input[type="checkbox"]:checked+label::before {
        content: "";
        display: block;
        position: absolute;
        text-align: center;
        height: 16px;
        width: 16px;
        left: 0;
        top: 5px;
        background-color: #fa9e57;
        font-family: "Montserrat";
        border-radius: 2px;
        // border: 1px solid rgb(150 150 150 / 30%);
    }
    input[type="checkbox"]:checked+label::after {
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
        display: block;
        position: absolute;
        left: 3px;
        top: 3px;
    }
    .label_cover {
        flex-grow: 1;
        padding-left: 10px;
        label {
            .form_check_title {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 171% */
                color: #211740;
                margin-bottom: 0px !important;
                font-family: Inter_Regular;
            }
            .form_check_subTitle {
                font-weight: 400;
                font-size: 10px;
                line-height: 20px;
                /* identical to box height, or 200% */
                color: #81899e;
                font-family: Inter_Regular;
            }
        }
    }
}