.coustomer-details-cover {
  padding: 30px;
  .title {
    color: rgba(102, 112, 133, 0.7);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "Inter_Regular";
    margin-bottom: 11px;
  }
  .customer-name-status-button-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .customer-name-and-status {
      margin-bottom: 34px;
      display: flex;
      align-items: center;
      span {
        margin-top: 5px;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: #211740;
        font-family: Calibre_Regular;
        margin-right: 10px;
        text-transform: capitalize;
      }
      .active-customer-header-status {
        padding: 5px 10px 5px 10px;
        background-color: #00c48c12;
        color: #05603a;
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 100px;
      }
      .inactive-customer-header-status {
        padding: 5px 10px 5px 10px;
        background-color: #d9dbde;
        color: #2f394b;
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 100px;
      }
      .deleted-customer-header-status {
        padding: 5px 10px 5px 10px;
        background-color: #912018;
        color: white;
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 100px;
      }
    }
  }
  .customer-page-cover {
    // margin-top: 33px;
    // background-color: green;
  }
  .notification-section {
    max-width: 1200px;
    padding: 0 24px;

    h6 {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #1d2939;
      margin: 0;
      margin-top: 16px;
    }
    .label-edit {
      column-gap: 24px;
      border-bottom: none;
    }
    .section-a {
      margin-right: 0px;
    }
    .radio-list {
      margin: 24px 0;
      padding-left: 16px;
    }
    .notification-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    aside {
      .input-box {
        margin-bottom: 16px;
        .label-edit-input {
          width: 100%;
          max-width: unset;
        }
      }
      .text-box {
        margin-bottom: 24px;
      }
      textarea {
        background: #ffffff;
        border: 1px solid rgba(234, 236, 240, 0.7);
        border-radius: 4px;
        padding: 12px 16px;
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #34373f;
        width: 100%;
        resize: none;
        height: 120px;
        outline: none;
      }
    }
  }

  @media (max-width: 450px) {
    padding: 30px 16px;
    .notification-section {
      padding: 0;
      .radio-list {
        padding-left: 8px;
      }
      .label-edit {
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      }
    }
  }
}
