.roles_page_cover {
    .roles_page_header_cover {
        display: flex;
        margin-top: 40px;
        align-items: center;
        @media (max-width: 458px) {
            flex-direction: column;
        }
        .roles_search_cover {
            flex-grow: 1;
            @media (max-width: 458px) {
                width: 100%;
            }
        }
        .create_new_role_button_div {
            @media (max-width: 458px) {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
            }
        }
    }
}