.invite_user_modal_cover,
.invite_successs_modal_cover {
    background-color: white;
    border-radius: 6px;
    .invite_user_cover {
        .invite_user_title {
            font-family: "Inter_Regular";
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            color: #14142b;
            margin-bottom: 4px;
        }
        .invite_user_subtitle {
            font-family: "Inter_Regular";
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height, or 150% */
            color: #6e7191;
            margin-bottom: 50px;
        }
        .invite_and_cancel_btn_cover {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.invite_user_modal_cover {
    width: 440px;
    padding: 16px 32px 32px 32px;
}

.invite_successs_modal_cover {
    width: 416px;
    padding: 16px 32px 62px 32px;
}