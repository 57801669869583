.disbursement-details-root {
    padding: 30px;
    .disbursement-details-cover {
        margin-bottom: 32.5px;
        .disbursement-ref-div {
            display: flex;
            .disbursement-ref-title {
                color: #211740;
                font-family: "Inter_Regular";
                font-size: 24px;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-self: center;
                @media (max-width: 580px) {
                    margin-bottom: 20px;
                }
                @media (max-width: 516px) {
                    font-size: 16px;
                }
            }
            .disbursement-ref-number {
                font-size: 24px;
                line-height: 29px;
                font-weight: 400;
                color: #98a2b3;
                font-family: "Inter_Regular";
                margin-left: 10px;
                @media (max-width: 516px) {
                    font-size: 16px;
                }
            }
            .disbursement-payment-method {
                margin-left: 10px;
                padding: 2px 8px 2px 8px;
                background-color: #f9f5ff;
                font-size: 14px;
                color: #5941a9;
                font-weight: 500;
                font-family: Inter_Regular;
                border-radius: 16px;
                text-transform: uppercase;
                width: max-content;
            }
            @media (max-width: 580px) {
                flex-direction: column;
            }
        }
    }
    .disbursement-details-card-root {
        margin-top: 30px;
        .total-amount-and-div {
            display: flex;
            margin-bottom: 28px;
            align-items: center;
            .tnx-check-sapan {
                margin-right: 16px;
            }
            .tnx-total-span {
                .title {
                    font-family: Inter_Regular;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    margin-bottom: 4px;
                    color: #98a2b3;
                }
                .value {
                    font-size: 24px;
                    font-weight: 500;
                    font-family: "Inter_Regular";
                    color: #261940;
                    line-height: 29.05px;
                    margin-right: 16px;
                }
            }
            .success,
            .failed,
            .pending,
            .successful,
            .Successful,
            .Failed,
            .Pending {
                padding: 5px 10px 5px 10px;
                border-radius: 100px;
                font-size: 10px;
                height: 22px;
                font-weight: 500;
                font-family: "Inter_Regular";
                line-height: 12px;
            }
            .success,
            .successful,
            .Successful {
                background-color: rgba(0, 196, 140, 0.07);
                color: #05603a;
            }
            .failed,
            .Failed {
                background-color: rgba(254, 205, 202, 0.3);
                color: #912018;
            }
            .pending,
            .Pending {
                background-color: #fffbf1;
                color: #93370d;
            }
        }
    }
    .disbursement-details-items-cover {
        margin-top: 20px;
    }
    .summary-details-cover {
        min-height: 200px;
    }
    .disbursement-reason-cover {
        .disbursement-reason-title {
            border-bottom: 1px solid #f2f4f7;
            padding-bottom: 19px;
            font-family: Calibre_Regular;
            font-weight: 400;
            color: #211740;
        }
        .disbursement-reason-content {
            font-family: Inter_Regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-top: 24px;
            letter-spacing: 0.2px;
            span {
                margin-right: 16px;
            }
        }
    }
}