.disbursement_partner_modal_cover {
    width: 603px;
    background-color: white;
    border-radius: 6px;
    padding: 30px;
    .disbursement_partner_modal_title_cover {
        display: flex;
        align-items: center;
        padding-bottom: 29px;
        border-bottom: 1px solid #f2f4f7;
        margin-bottom: 20px;
        .disbursement_partner_modal_title {
            flex-grow: 1;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            /* identical to box height, or 120% */
            /* Primary/800 */
            color: #211740;
            font-family: Inter_Regular;
        }
    }
    .disbursement_partner_modal_content_cover {
        border: 1px solid #f2f4f7;
        border-radius: 6px;
        padding: 8px;
        overflow-y: auto;
        max-height: 250px;
        // overflow-y: scroll;
        background-color: rgba(220, 216, 236, 0.2);
        padding: 12px;
        .react-json-view {
            background-color: transparent !important;
            .object-content {
                span,
                div {
                    color: #211740;
                    font-size: 12px;
                    font-family: monospace;
                }
            }
        }
    }
}