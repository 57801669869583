.bank-details-cover {
  .icon-comp {
    right: 1em;
  }
  .bank-details-cover-title {
    font-size: 18px;
    color: #211740;
    line-height: 21.78px;
    font-weight: 500;
    font-family: Inter_Medium;
    margin-bottom: 10px;
  }
  .bank-details-cover-subtitle {
    font-size: 14px;
    color: #98a2b3;
    line-height: 20px;
    font-weight: 400;
    font-family: Inter_Medium;
    margin-bottom: 32px;
  }
  .bank-details-cover-row {
    .bank-details-cover-row-col-left {
      padding-right: 10px !important;
    }
    .bank-details-cover-row-col-right {
      padding-left: 10px !important;
    }
    .bank-details-view-cover-form-button-div {
      width: 100%;
      margin-top: 42px !important;
      padding-top: 41px !important;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #f2f4f7;
      span {
        margin-right: 16px;
      }
    }
  }
}
@media (max-width: 767px) {
  .bank-details-cover {
    .bank-details-cover-title {
      font-size: 18px;
      color: #211740;
      line-height: 21.78px;
      font-weight: 500;
      font-family: Inter_Medium;
      margin-bottom: 10px;
    }
    .bank-details-cover-subtitle {
      font-size: 14px;
      color: #98a2b3;
      line-height: 20px;
      font-weight: 400;
      font-family: Inter_Medium;
      margin-bottom: 32px;
    }
    .bank-details-cover-row {
      .select-bank-input{
        margin-bottom: 20px !important;
      }
      .bank-details-cover-row-col-left {
        padding-right: 0px !important;
      }
      .bank-details-cover-row-col-right {
        padding-left: 0px !important;
      }
      .bank-details-view-cover-form-button-div {
        width: 100%;
        margin-top: 42px !important;
        padding-top: 41px !important;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #f2f4f7;
        span {
          margin-right: 16px;
        }
      }
    }
  }
}
