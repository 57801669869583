.push_notification_resend_modal {
    width: 346px;
    background-color: rgba(255, 255, 255, 1);
    padding: 49px 37px 37px 37px;
    border-radius: 10px;
    .resend_push_notification_icon {
        text-align: center;
        margin-bottom: 30px;
    }
    .resend_push_notification_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        /* Primary/800 */
        color: #211740;
        font-family: Inter_Regular;
        margin-bottom: 10px;
    }
    .resend_push_notification_subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        text-align: center;
        font-family: Inter_Regular;
        color: #676e7e;
        margin-bottom: 20px;
    }
    .resend_push_notification_message {
        border: 1px solid rgba(234, 236, 240, 0.7);
        padding: 12px 16px 16px 16px;
        border-radius: 4px;
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        /* Primary Gray */
        color: #475467;
        font-family: Inter_Regular;
    }
}