.payment-link-details-wrap {
    .single-payment-link-detail {
        header {
            .title-cov {
                .payment-title {
                    display: flex;
                    margin-top: 10px;
                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                    .sect-1 {
                        align-items: center;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                        h3 {
                            font-family: "Calibre_Regular";
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 24px;
                            color: #211740;
                            margin: 0;
                            padding: 0;
                            margin-right: 8px;
                            max-width: 300px;
                            @media (max-width: 500px) {
                                font-size: 20px;
                            }
                        }
                    }
                    .action-buttons {
                        display: flex;
                        @media (max-width: 600px) {
                            width: 100%;
                            justify-content: flex-end;
                            margin-top: 16px;
                        }
                        button {
                            margin-left: 12px;
                            @media (max-width: 1291px) {
                                .deactivate_text {
                                    display: none;
                                }
                                .activate_text {
                                    display: none;
                                }
                            }
                            // span {
                            //     display: none;
                            // }
                        }
                        .text-grey {
                            span {
                                color: #475467;
                                @media (max-width: 1291px) {
                                    display: none;
                                }
                            }
                        }
                        .text-red {
                            span {
                                color: #b42318;
                                @media (max-width: 1291px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        section {
            display: flex;
            flex-wrap: wrap;
            grid-column-gap: 32px;
            .card-title {
                margin: 0;
                padding: 24px 0 19px;
                border-bottom: 1px solid #f2f4f7;
                width: 100%;
                display: flex;
                justify-content: space-between;
                h5 {
                    font-family: "Calibre_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #211740;
                    margin: 0;
                }
                .card-status {
                    padding: 5px 10px;
                    width: 65px;
                    height: 22px;
                    background: rgba(0, 196, 140, 0.07);
                    border-radius: 100px;
                    font-family: "Inter_Medium";
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    color: #00c48c;
                }
            }
            .empty-text {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                flex-grow: 1;
                span {
                    letter-spacing: 0.2px;
                    color: rgba(102, 112, 133, 0.75);
                    font-family: "Calibre_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    width: 250px;
                    text-align: center;
                }
            }
            .payment-info {
                margin-top: 32px;
                border: 1px solid #f2f4f7;
                box-sizing: border-box;
                border-radius: 6px;
                padding: 32px;
                flex-grow: 1;
                @media (max-width: 500px) {
                    padding: 0px;
                }
                .payment-info-header {
                    display: flex;
                    align-items: center;
                    @media (max-width: 500px) {
                        padding: 16px 16px 0px 16px;
                    }
                    h5 {
                        font-family: "Inter_Regular";
                        font-weight: 400;
                        font-size: 23px;
                        line-height: 28px;
                        color: #261940;
                        margin: 0;
                        margin-left: 16px;
                    }
                }
                aside {
                    display: flex;
                    flex-direction: column;
                    // grid-template-columns: repeat(auto-fill, minmax(190px, 220px));
                    .flexed-comp {
                        display: flex;
                        // grid-template-columns: repeat(auto-fill, minmax(190px, 190px));
                        column-gap: 1.5em;
                        @media (max-width: 1305px) {
                            flex-direction: column;
                        }
                        @media (max-width: 500px) {
                            flex-direction: column;
                        }
                    }
                    .label-value-comp {
                        margin-top: 32px;
                        border-right: 1px solid #eaecf0;
                        width: 30%;
                        min-width: 180px;
                        max-width: 500px;
                        @media (max-width: 1305px) {
                            border-right: none;
                        }
                        &:last-child {
                            border: none;
                        }
                    }
                    .additional-info {
                        max-width: 700px;
                        width: 100%;
                        border: none;
                        @media (max-width: 500px) {
                            padding: 0px 16px 0px 16px;
                        }
                        .additional-info-cover {
                            display: flex;
                            margin-top: 12px;
                            small {
                                padding: 2px 8px;
                                background: #f9f5ff;
                                border-radius: 16px;
                                font-family: "Inter_Regular";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 18px;
                                text-align: center;
                                color: #5941a9;
                                margin-right: 10px;
                                display: block;
                            }
                        }
                    }
                }
            }
            .more-details-card {
                margin-top: 32px;
                padding: 20px;
                min-width: 300px;
                max-width: 300px;
                background: rgba(220, 216, 236, 0.12);
                border: 1px solid rgba(220, 216, 236, 0.2);
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                @media (max-width: 621px) {
                    min-width: 100%;
                    max-width: 100%;
                }
                h5 {
                    font-family: "Calibre_Regular";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #211740;
                    margin: 0;
                    padding-bottom: 8px;
                    border-bottom: 1px solid rgba(220, 216, 236, 0.2);
                    margin: 0;
                }
                .comment-div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    flex-grow: 1;
                    small {
                        font-family: "Inter_Regular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 16px;
                        color: #98a2b3;
                        margin-top: 8px;
                    }
                    div {
                        margin-top: 10px;
                        span {
                            font-family: "Inter_Regular";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #344054;
                        }
                    }
                }
                button {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #ffffff;
                    height: 36px;
                    width: 100%;
                    border-radius: 4px;
                    span {
                        font-family: "Inter_Regular";
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #ffffff;
                        margin-left: 8px;
                    }
                }
                .deactivate-col {
                    background: #b42318;
                }
                .activate-col {
                    background: #12b76a;
                }
            }
            .hide-reason {
                h5,
                .comment-div {
                    visibility: hidden;
                }
                background: transparent;
                border: 1px solid transparent;
            }
            .payment_link_image_list_cover {
                width: 256px;
                padding-top: 33px;
                .payment_link_image_empty_cover {
                    .payment_link_image_empty_content {
                        margin-top: 5px;
                        font-family: "Inter_Regular";
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        /* or 125% */
                        /* Gray/Gray 500 */
                        color: #667085;
                    }
                }
                @media (max-width: 650px) {
                    width: 100%;
                }
            }
        }
    }
    .payment-link-history {
        border: 1px solid #f2f4f7;
        margin-top: 32px;
        padding-top: 16px;
        .dashboard-header {
            margin-bottom: 16px;
            margin-left: 16px;
        }
    }
    .copy-link {
        display: flex;
        align-items: center;
        max-width: 300px;
        p {
            font-size: 10px;
            font-family: "Inter_Regular";
            color: #555555;
            margin: 0;
            margin-right: 0.5em;
            padding: 9px 14px;
            background: #f1f5f9;
            border-radius: 6px;
        }
        .icon {
            cursor: pointer;
        }
    }
    .status-comp {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1em;
        padding: 8px 12px;
        border-radius: 16px;
        height: 1.2em;
        .status-circle {
            width: 4px;
            height: 4px;
            border-radius: 50%;
        }
        span {
            font-size: 11px;
            font-family: "Inter_Medium";
            margin: 0;
            margin-left: 0.5em;
        }
    }
    .active-sty {
        background: #ecfdf3;
        span {
            color: #027a48;
        }
        .status-circle {
            background-color: #027a48;
        }
    }
    .inactive-sty {
        background: #f2f4f7;
        span {
            color: #101828;
        }
        .status-circle {
            background-color: #d92d20;
        }
    }
    .modal-card {
        h2,
        .modal-card-subtitle {
            text-align: center;
            margin: 0;
        }
        // .empty-
        .payment-link-modal {
            .additional-info-header {
                h6 {
                    font-family: "Calibre_Medium";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 17px;
                    color: #000000;
                    margin: 0;
                    margin-bottom: 4px;
                    text-align: center;
                }
                span {
                    display: block;
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    margin: 0;
                    color: #555555;
                    text-align: center;
                }
            }
            .additional-info-comp {
                background: #ffffff;
                border: 1px solid rgba(234, 236, 240, 0.7);
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 8px 14px;
                margin-top: 20px;
                span {
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 18px;
                    display: block;
                    margin: 0;
                    margin-bottom: 3px;
                    color: #98a2b3;
                    text-transform: capitalize;
                }
                strong {
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.002em;
                    color: #475467;
                }
            }
            .empty-record {
                border: none;
                padding-bottom: 0;
                svg {
                    height: 100;
                    width: 70px;
                }
                h4 {
                    font-size: 16px;
                    margin-top: 10px;
                    line-height: normal;
                    margin-bottom: 5px;
                }
                h5 {
                    font-size: 13px;
                }
            }
        }
    }
}