.link-back {
  text-decoration: none;
  span {
    font-family: "Inter_Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(102, 112, 133, 0.7);
  }
}
