.overview-contain {
  .dashboard {
    padding-bottom: 0.5em !important;
    padding-top: 0.5em !important;
  }
  .activate-acct {
    background: #fffcf5;
    border: 1px solid #f79009;
    border-radius: 40px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    span {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 14px;
      color: #93370d;
      margin-right: 4px;
    }
  }

  .overview-merchant {
    display: flex;
    flex-direction: column;
    .card-overview-title {
      margin: 0;
      padding: 0;
      text-align: left;
      font-family: "Calibre_Regular";
      font-size: 18px;
      color: #272643;
    }
    .table-cover-top {
      border-bottom: none;
    }
    .empty-record {
      border: none;
      .empty-wrap {
        width: 270px;
        max-width: 270px;
      }
    }
    .trx-card {
      width: 100%;
      flex-grow: 1;
      display: flex;
      .volume-graph {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;

        .graph-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 24px 32px;
          padding-bottom: 12px;
        }
        .trx-vol-rec {
          display: flex;
          align-items: flex-end;
          padding: 0px 32px;
          strong {
            font-family: "Inter_Medium";
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: #1d2939;
            margin-right: 6px;
          }
          span {
            margin-left: 0px;
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #039855;
          }
        }
        .line-graph {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          canvas {
            width: 100% !important;
            height: 290px !important;
          }
        }
      }
      .trx-info {
        width: 40%;
        display: grid;
        grid-template-rows: 50% 50%;
        border-left: 1px solid #f0f0f0;
        @media (max-width:1113px) {
          width: fit-content;
        }
        .info-num,
        .info-avg {
          border-bottom: 1px solid #f0f0f0;
          padding: 24px;
        }
      }
    }

    .balance-cover {
      width: 100%;
      display: flex;
      margin-top: 1.2em;
      justify-content: space-between;
      .customer-card {
        min-height: 350px;
        flex-grow: 1;
        margin-left: 0.65em;
        width: 49%;
        .div-table {
          border: none;
          td {
            padding: 1.2em 0.8em;
            height: 4em;

          }
          .table-cover-top{
            table{
              tbody{

                tr{
                  td{
                    .alt-img-name{
                      @media (max-width:1090px) {
                        display: none;
                                          }
                                          @media (max-width:740px) {
                                            font-size: inherit !important;
                                            display: flex;
                                                              }

                    }
                  }
                  .double-cell{
                    p{
                      @media (max-width:1090px) {
    font-size: 10px !important;
                      }
                      @media (max-width:740px) {
                        font-size: inherit !important;
                                          }

                    }

                  }
                }

              }
            }
          }
          // tbody{
          //   font-size: 10px;
          // }
        }
        .empty-cover {
          font-size: 0.7em;
          .empty-icon {
            margin-bottom: 1em;
            svg {
              width: 90px;
              height: 110px;
            }
          }
        }
        @media (max-width:740px) {
          margin-left: 0px;
          width: 100%;
        }
      }
      .balance-card {
        width: 100%;
        max-width: 550px;
        // height: 400px;
        .balance-card-title {
          padding: 24px 24px 16px 24px;
          border-bottom: 1px solid #e5e5e5;
          color: #211740;

        }
        .balance-record {
          padding: 24px;
          .border-b {
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 32px;
            margin-bottom: 24px;
          }
          .trx-label-wrap {
            .transaction-labels {
              &:first-child {
                border-right: 1px solid #e5e5e5;
                padding-right: 16px;
              }
              &:last-child {
                padding-left: 16px;
              }
            }
          }

        }
        @media (max-width:1360px) {
          max-width: 50%;
          width: 50%;
        }
        @media (max-width:1290px) {
          max-width: 45%;
          width: 45%;
        }
        @media (max-width:1081px) {
          max-width: 50%;
          width: 50%;
                            }
        @media (max-width:740px) {
          max-width: 100%;
          width: 100%;
          margin-bottom: 24px;
        }
      }
      @media (max-width:740px) {
        flex-direction: column;
      }
    }

    .overview-card {
      background: #ffffff;
      /* Components / Border color */
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: transparent;

      .overview-title {
        margin: 0;
        padding: 24px 32px 16px 32px !important;
        font-family: "Calibre_Regular";
        font-size: 18px;
        color: #211740;
        border-bottom: 1px solid #e5e5e5;
        font-weight: 400;
        line-height: 21.6px;
        display: flex;
          .overview-title-span-title{
            font-family: "Calibre_Regular";
            font-size: 18px;
            color: #211740;

            flex-grow: 1;
          }
          .overview-title-span-view-more{
            font-family: "Calibre_Regular";
            font-size: 12px;
            color: #523B9F;
            cursor: pointer;


          }


      }
      .data-comp-overview {
        .icon-wrap {
          display: flex;
          align-items: center;
          h6 {
            font-family: "Avenir";
            font-size: 0.9em;
            color: #272643;
            margin: 0;
            margin-right: 0.6em;
          }
          span {
            font-family: "Avenir";
            font-size: 0.9em;
            color: #00c48c;
            margin-left: 0.2em;
          }
        }
        h4 {
          font-family: "Recoleta_Bold";
          font-weight: bold;
          font-size: 1.6em;
          color: #333333;
          margin: 0.3em 0;
        }
      }
      .table-container {
        td {
          .double-cell {
            display: flex;
            flex-direction: column;
            p {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
.transaction-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  span {
    font-family: "Inter_Regular";
    font-weight: 400;
    font-size: 12px;
    color: #475467;
    @media (max-width:1290px) {
      font-size: 11px;
    }
    @media (max-width:1049px) {
      font-size: 12px;
    }
  }
  strong {
    font-family: "Inter_Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    color: #1d2939;
    margin: 14px 0;
    @media (max-width:1290px) {
      font-size: 16px;
    }
  }
  span,
  strong {
    text-align: left;
  }
}

.center-text {
  align-items: center;
  margin: 0 auto;
  span,
  strong {
    text-align: center;
  }
}
@media (max-width:650px) {
  .overview-contain {

    .dashboard {
      padding-bottom: 0.5em !important;
      padding-top: 0.5em !important;

    }
    .activate-acct {
      background: #fffcf5;
      border: 1px solid #f79009;
      border-radius: 40px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 32px;

      span {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 14px;
        color: #93370d;
        margin-right: 4px;

        text-align: center;
        flex-grow: 1;
      }
    }

    .overview-merchant {
      display: flex;
      flex-direction: column;
      .card-overview-title {
        margin: 0;
        padding: 0;
        text-align: left;
        font-family: "Calibre_Regular";
        font-size: 18px;
        color: #272643;
      }
      .table-cover-top {
        border-bottom: none;
      }
      .empty-record {
        border: none;
        .empty-wrap {
          width: 270px;
          max-width: 270px;
        }
      }
      .trx-card {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .volume-graph {
          flex-grow: 1;
          height: 100%;
          display: flex;
          flex-direction: column;

          .graph-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 24px 24px 7px 24px;
            padding-bottom: 12px;
            .graph-inner-title{
              flex-grow: 1;
              font-size: 16px;
              color: #211740;
            }
          }
          .trx-vol-rec {
            display: flex;
            align-items: flex-end;
            padding: 0px 24px;
            strong {
              font-family: "Inter_Medium";
              font-weight: 500;
              font-size: 20px;
              line-height: 29px;
              color: #1d2939;
              margin-right: 6px;
            }
            span {
              margin-left: 0px;
              font-family: "Inter_Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 19px;
              color: #039855;
            }
          }
          .line-graph {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            canvas {
              width: 100% !important;
              height: 290px !important;
            }
          }
        }
        .trx-info {
          width: 40%;
          display: grid;
          grid-template-rows: 50% 50%;
          border-left: 1px solid #f0f0f0;
          .info-num,
          .info-avg {
            border-bottom: 1px solid #f0f0f0;
            padding: 24px;
          }
        }
      }

      .balance-cover {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1.2em;
        justify-content: space-between;
        .customer-card {
          min-height: 350px;
          flex-grow: 1;
          margin-left: 0px;
          width: 100%;
          .div-table {
            border: none;
            td {
              padding: 1.2em 0.8em;
              height: 4em;
            }
          }
          .empty-cover {
            font-size: 0.7em;
            .empty-icon {
              margin-bottom: 1em;
              svg {
                width: 90px;
                height: 110px;
              }
            }
          }
        }
        .balance-card {
          width: 100%;
          // max-width: 550px;
          // height: 400px;
          .balance-card-title {
            padding: 16px 25px;
            border-bottom: 1px solid #e5e5e5;
          }
          .balance-record {
            padding:33px 0px;
            .border-b {
              border-bottom: 1px solid #e5e5e5;
              padding-bottom: 32px;
              margin-bottom: 24px;
            }
            .trx-label-wrap {
              flex-direction: column;
              .transaction-labels {

                &:first-child {
                  border-right: none;
                  padding-right: 0px !important;
                  align-items: center;
                  padding-bottom: 31px;
                  border-bottom: 1px solid #F2F4F7;
                  span{
                    text-align: center;
                  }
                }
                &:last-child {
                  padding-top: 24px;
                  padding-left: 0px;
                  align-items: center;
                  span{
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .overview-card {
        background: #ffffff;
        /* Components / Border color */
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: transparent;
        margin-bottom: 32px;


        .overview-title {

          margin: 0;
          padding: 24px 0px 16px 24px;
          text-align: left;
          font-family: "Calibre_Regular";
          font-size: 18px;
          color: #211740;
          border-bottom: 1px solid #e5e5e5;

        }
        .data-comp-overview {
          .icon-wrap {
            display: flex;
            align-items: center;
            h6 {
              font-family: "Avenir";
              font-size: 0.9em;
              color: #272643;
              margin: 0;
              margin-right: 0.6em;
            }
            span {
              font-family: "Avenir";
              font-size: 0.9em;
              color: #00c48c;
              margin-left: 0.2em;
            }
          }
          h4 {
            font-family: "Recoleta_Bold";
            font-weight: bold;
            font-size: 1.6em;
            color: #333333;
            margin: 0.3em 0;
          }
        }
        .table-container {
          td {
            .double-cell {
              display: flex;
              flex-direction: column;
              p {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .transaction-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 300px;

    span {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      color: #475467;
    }
    strong {
      font-family: "Inter_Medium";
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      color: #1d2939;
      margin: 14px 0;
    }
    span,
    strong {
      text-align: left;
    }
  }

  .center-text {
    align-items: center;
    margin: 0 auto;
    span,
    strong {
      text-align: center;
    }
  }

}