.exports-pdf {
  position: relative;
  .exports-dropdown {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    z-index: 110;
    right: 0;
    width: 180px;
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.1));
    display: flex;
    flex-direction: column;
    margin: 0;
    .dropdown-ul {
      width: 100%;
      margin-top: 0.3em;
      background: #ffffff;
      box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
      border-radius: 4px;
      padding: 10px 0;
      li {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 13px;
        line-height: 12px;
        color: #667085;
        text-transform: capitalize;
        &:hover {
          background: rgba(220, 216, 236, 0.2);
          color: #523b9f;
        }
      }
    }
  }
  .exports-pdf-hidden {
    opacity: 0;
    visibility: hidden;
  }
}
