.settings_configuration_comp {
  border-top: 1px solid #f2f4f7;
  padding: 25px 0 0 0;
  .settings_configuration_details {
    .settings_configuration_details_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      color: #1d2939;
      font-family: "Calibre_Regular";
    }
    .settings_configuration_details_subtitle {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      color: rgba(102, 112, 133, 0.8);
    }
  }
  .settings_configuration_input_cover {
    .edit-wrapper {
      position: relative;
      max-width: 500px;
      .select-custom-styles .css-yk16xz-control {
        border: 1px solid rgba(89, 65, 169, 1);
      }
    }
    .wrapper {
      position: relative;
      max-width: 500px;
    }

    .label-edit-custom-class {
      display: grid;
      section {
        h6 {
          font-family: "Calibre_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #1d2939;
          margin-bottom: 16px;
        }
        small {
          font-family: "Calibre_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: rgba(102, 112, 133, 0.8);
        }
        .label {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 13px;
          letter-spacing: -0.002em;
          color: #98a2b3;
          margin-bottom: 10px;
          display: block;
        }
        .section-input-cover {
          display: flex;
          justify-content: space-between;
          max-width: 506px;
          margin-right: 0;
          margin-bottom: 24px;
        }
        .label-edit-input {
          width: 100%;
          max-width: 356px;
          height: 48px;
          border-radius: 4px;
          padding: 15px 16px;
          font-family: "Inter_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          letter-spacing: -0.002em;
          outline: none;
          border: 1px solid rgba(234, 236, 240, 0.7);
          max-width: 39em;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      .select-custom-styles {
        .css-1uccc91-singleValue {
          padding-top: 0px;
          font-size: 13px;
          color: #475467 !important;
        }
        .css-107lb6w-singleValue {
          margin-top: 0px;
        }
      }

      .select-search-with-value {
        .select-search {
          .css-b8ldur-Input {
            margin-top: 0px;
          }
          .css-1wa3eu0-placeholder {
            margin-top: 0;
          }
        }
      }
    }

    .settings_configuration_submit_button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;
    }
  }
}
