.success_invite_pop_cover {
    .icon_cover_div {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        .check_icon_circle {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background-color: rgba(89, 65, 169, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .pop_up_card_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        font-family: Calibre_Regular;
        /* identical to box height */
        /* Black */
        color: #121212;
        text-align: center;
        width: 100%;
        margin-bottom: 12px;
    }
    .pop_up_card_subTitle {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */
        text-align: center;
        color: #667085;
    }
}