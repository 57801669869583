.single-order-detail {
  header {
    .title-cov {
      .order-title {
        display: flex;
        margin-top: 10px;
        h3 {
          font-family: "Calibre_Regular";
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          color: #211740;
          margin: 0;
          padding: 0;
          margin-right: 8px;
          @media (max-width: 500px) {
            font-size: 20px;
          }
        }
        h5 {
          font-family: "Calibre_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          color: #98a2b3;
          margin: 0;
          padding: 0;
          @media (max-width: 500px) {
            font-size: 20px;
          }
        }
      }
    }
  }
  section {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 32px;

    .card-title {
      margin: 0;
      padding: 24px 0 19px;
      border-bottom: 1px solid #f2f4f7;
      width: 100%;
      display: flex;
      justify-content: space-between;
      h5 {
        font-family: "Calibre_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #211740;
        margin: 0;
      }
      .card-status {
        padding: 5px 10px;
        width: 65px;
        height: 22px;
        background: rgba(0, 196, 140, 0.07);
        border-radius: 100px;
        font-family: "Inter_Medium";
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #00c48c;
      }
    }
    .empty-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      flex-grow: 1;
      span {
        letter-spacing: 0.2px;
        color: rgba(102, 112, 133, 0.75);
        font-family: "Calibre_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        width: 250px;
        text-align: center;
      }
    }
    .order-info {
      margin-top: 32px;
      border: 1px solid #f2f4f7;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 32px;
      flex-grow: 1;
      @media (max-width: 1181px) {
        padding: 16px 0px 16px 16px;
      }
      @media (max-width: 500px) {
        padding: 16px 0px 16px 0px;
      }
      .order-info-header {
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          padding-left: 16px;
          padding-right: 16px;
        }

        h5 {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 23px;
          line-height: 28px;
          color: #261940;
          margin: 0;
          margin-left: 16px;
        }
      }
      aside {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(190px, 220px));
        column-gap: 2em;
        @media (max-width: 1133px) {
          grid-template-columns: repeat(auto-fill, minmax(150px, 170px));
          column-gap: 16px;
        }
        @media (max-width: 600px) {
          margin-top: 8px;
          grid-template-columns: repeat(auto-fill, minmax(100%, 100%));
        }
        .label-value-comp {
          margin-top: 32px;
          border-right: 1px solid #eaecf0;

          &:last-child {
            border: none;
          }
          @media (max-width: 737px) {
            border-right: none;
          }
        }
      }
    }
    .order-product-card {
      margin-top: 32px;
      padding: 20px;
      min-width: 200px;
      max-width: 300px;
      background: rgba(220, 216, 236, 0.12);
      border: 1px solid rgba(220, 216, 236, 0.2);
      border-radius: 6px;
      @media (max-width: 600px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
      h5 {
        font-family: "Calibre_Regular";
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #211740;
        margin: 0;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(220, 216, 236, 0.2);
        margin: 0;
      }

      .image-and-name {
        display: flex;
        margin: 16px 0 20px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          object-fit: cover;
        }
        h6 {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #1d2939;
          margin: 0;
          margin-left: 16px;
        }
      }
      .product-info {
        .product-label-value {
          display: flex;
          margin-bottom: 8px;
          span,
          strong {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
          }
          span {
            color: #98a2b3;
            margin-right: 20px;
          }
          strong {
            color: #667085;
            flex-grow: 1;
            text-align: right;
            width: 100%;
          }
        }
      }
      button {
        margin-top: 8px;
        background: #523b9f;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        height: 36px;
        width: 100%;
        border-radius: 4px;
        span {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
    }
    .loan-schedules {
      border: 1px solid #f2f4f7;
      box-sizing: border-box;
      border-radius: 6px;
      min-width: 550px;
      flex-grow: 1;
      padding: 0 32px;
      display: flex;
      flex-direction: column;
      min-height: 150px;
      margin-top: 32px;
      @media (max-width: 600px) {
        padding-left: 16px;
        width: 100%;
        min-width: 100%;
        padding-right: 16px;
      }
      .loan-schedule-card {
        display: grid;

        grid-template-columns: calc(50% - 23px) calc(50% - 23px);
        grid-column-gap: 46px;
        flex-grow: 1;
        max-width: 600px;
        min-width: 400px;
        width: 100%;
        padding: 26px 0;
        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;

          max-width: 100%;
          min-width: 100%;
        }
        .halves {
          padding: 0;
          padding-right: 32px;
          .label-value-comp {
            margin-bottom: 19px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .half-1 {
          border-right: 1px solid #f2f4f7;
        }
      }
    }
    .shipping-card {
      margin-top: 32px;
      border: 1px solid #f2f4f7;
      box-sizing: border-box;
      border-radius: 6px;
      min-width: 400px;
      flex-grow: 1;
      padding: 0 32px;
      display: flex;
      flex-direction: column;
      min-height: 150px;
      @media (max-width: 600px) {
        min-width: 100%;
      }
      .address-info {
        display: flex;
        margin-top: 24px;
        .address-text {
          margin-left: 20px;
          font-family: "Inter_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.2px;
          color: #344054;
        }
      }
      .shipping-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        flex-grow: 1;
        span {
          letter-spacing: 0.2px;
          color: rgba(102, 112, 133, 0.75);
          font-family: "Calibre_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: 250px;
          text-align: center;
        }
      }
    }
  }
}
