.provide_user_eligibility_cover {
    .provide_user_eligibility_title {
        border-bottom: 1px solid #f2f4f7;
        padding-bottom: 19px;
        margin-bottom: 20px;
        font-family: "Calibre_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #1d2939;
    }
    .provide_user_eligibility_div {
        display: flex;
        .provide_user_input_div {
            flex-grow: 1;
            margin-right: 5px;
        }
    }
}