.check_eligibility_page_cover {
    padding: 0px 40px 0px 40px !important;
    @media (max-width: 927px) {
        padding-top: 20px !important;
    }
    .check_eligibility_select_div {
        display: flex;
        margin-bottom: 32px;
        // align-items: center;
        .select_disburement_div {
            width: 159px;
            margin-right: 32px;
        }
        .check_eligibility_amount_div {
            width: 133px;
        }
    }
}