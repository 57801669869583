.redemption-history-root-cover {
    padding-top: 30px;
    padding-left: 31px;
    padding-right: 31px;
    .redemption-history-header-wrapper {
        display: flex;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .redemption-history-header {
            flex-grow: 1;
        }
        .redemption-history-btn {
            @media (max-width: 767px) {
                margin-bottom: 30px;
                text-align: right;
            }
        }
    }
    .redemption-history-table-details-cover {
        margin-top: 13px;
        padding: 16px;
        border: 1px solid #f2f4f7;
        border-radius: 4px;
    }
    .div-table {
        table {
            tbody {
                tr {
                    td {
                        .double-cell {
                            align-items: center;
                            p {
                                font-weight: 500;
                                font-family: "Inter_Medium" !important;
                                text-transform: capitalize;
                                color: #475467 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}