.document-view-cover {
  &-title {
    color: #211740;
    font-size: 18px;
    line-height: 21.78px;
    font-weight: 500;
    font-family: Inter_Medium;
    margin-bottom: 10px;
  }
  &-subtitle {
    color: #98a2b3;
    line-height: 20px;
    font-size: 14px;
    font-family: Inter_Regular;
    font-weight: 400;
    margin-bottom: 32px;
  }
  .document-view-cover-form {
    width: 100%;
    .document-view-cover-form-profile-col-left {
      padding-right: 10px !important;
    }
    .document-view-cover-form-profile-col-right {
      padding-left: 10px !important;
    }
    .document-view-cover-form-button-div {
      margin-top: 42px !important;
      padding-top: 41px !important;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #f2f4f7;
      span {
        margin-right: 16px;
      }
    }
  }
  .identity-docs-title-cover {
    padding: 0;
    margin: 34px 0 8px;
    flex-wrap: wrap;
    .identity-docs-title {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #211740;
      margin-right: 8px;
    }
    .identity-docs-subtitle {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #98a2b3;
    }
  }
  .upload-warning {
    display: none;
  }
  .upload-input-kyc-cov {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .document-view-cover {
    &-title {
      color: #211740;
      font-size: 18px;
      line-height: 21.78px;
      font-weight: 500;
      font-family: Inter_Medium;
      margin-bottom: 10px;
    }
    &-subtitle {
      color: #98a2b3;
      line-height: 20px;
      font-size: 14px;
      font-family: Inter_Regular;
      font-weight: 400;
      margin-bottom: 32px;
    }
    .document-view-cover-form {
      width: 100%;
      .document-view-cover-form-profile-col-left {
        padding-right: 0px !important;
      }
      .document-view-cover-form-profile-col-right {
        padding-left: 0px !important;
      }
      .document-view-cover-form-button-div {
        margin-top: 42px !important;
        padding-top: 41px !important;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #f2f4f7;
        span {
          margin-right: 16px;
        }
      }
    }
  }
}
