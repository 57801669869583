.error_permission__modal {
    width: 340px;
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    .error_icon_cover {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }
    .error_content_div_cover {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        font-family: "Inter_Regular";
        // display: flex;
        align-items: center;
        text-align: center;
        /* Black */
        margin-bottom: 12px;
        color: #121212;
    }
    .error_sub_content_div_cover {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 125% */
        text-align: center;
        color: #667085;
        margin-bottom: 16px;
        width: 100%;
    }
    .close_button_cover {
        display: flex;
        justify-content: center;
    }
}