.comingsoon-overlay-cover{
  background-color:rgba(33, 23, 64, 0.55);
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  .overlay-modal-cover{
    display: flex;
    justify-content: center;

    .comingsoon-overlay-modal{
      background-color:white;
      width: 346px;

      border-radius: 10px;
      .top-modal-cover-icon{
        padding-top: 16px;
        padding-right: 16px;
        justify-content: flex-end;
        display: flex;
        .modal-cover-icon{
          cursor: pointer;
          width: 24px;
          height: 24px;
          background-color: rgba(242, 244, 247, 0.5);
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

}
