.merchant-settlement-cover{

  padding-right: 40px;
  @media (max-width:927px) {
    padding-right: 30px;
  }
  .settlement-table-cover{
    @media (max-width:927px) {
      margin-top: 20px;
    }
  }
}