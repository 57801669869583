.providus_retries_comp {
  // display: flex;
  padding: 20px 0;
  .providus_retries_details {
    margin-bottom: 20px;
    .providus_retries_details_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      color: #1d2939;
      font-family: "Calibre_Regular";
    }
    .providus_retries_details_subtitle {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      color: rgba(102, 112, 133, 0.8);
    }
  }
  .providus_retries_input_cover {
    .providus_retries_input_div {
      margin-bottom: 20px;
      .providus_input_label {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        /* identical to box height, or 138% */
        letter-spacing: -0.002em;
        /* Gray/Gray 400 */
        color: #98a2b3;
        margin-bottom: 10px;
      }
    }
    .providus_retries_submit_button {
      padding-top: 30px;
    }
  }
}
