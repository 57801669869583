.group_title_and_list_of_permissions {
    padding-top: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(222, 230, 238, 1);
    .group_name {
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        /* Primary */
        color: #5941a9;
    }
    .permission_check_and_content_cover {
        margin-top: 16px;
        position: relative;
        cursor: pointer;
        .checkbox_content {
            color: #121212;
            margin-left: 0px;
            font-size: 12px;
            font-family: "Inter_Regular";
            font-weight: 500;
            line-height: 20px;
            /* identical to box height, or 167% */
            /* Black */
        }
        .delete_permission_icon_cover {
            position: absolute;
            top: -20%;
            right: 2%;
            display: none;
        }
    }
    .permission_check_and_content_cover:hover {
        .delete_permission_icon_cover {
            position: absolute;
            top: -20%;
            right: 2%;
            display: block;
        }
    }
}