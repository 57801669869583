.settlement-root-cover {
  padding-top: 30px;
  padding-left: 31px;
  padding-right: 31px;
  .settlement-table-details-cover {
    margin-top: 13px;
    padding: 16px;
    border: 1px solid #f2f4f7;
    border-radius: 4px;

    .settlement-update {
      margin: 0.5em 0;
      .title-span {
        font-family: "Calibre_Regular";
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        color: #211740;

        small {
          font-family: Inter_Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #98a2b3;
          margin-left: 0.5em;
          margin-bottom: 0.7em;
        }
      }

      .update-settlement-btn {
        position: relative;
        .update-dropdown {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s ease-in-out;
          position: absolute;
          z-index: 110;
          right: 0;
          width: 180px;
          filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.1));
          display: flex;
          flex-direction: column;
          margin: 0;
          .dropdown-ul {
            width: 100%;
            margin-top: 0.3em;
            background: #ffffff;
            box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
            border-radius: 4px;
            padding: 10px 0;
            li {
              font-family: "Inter_Regular";
              font-weight: 400;
              font-size: 13px;
              line-height: 12px;
              color: #667085;
              text-transform: capitalize;
              &:hover {
                background: rgba(220, 216, 236, 0.2);
                color: #523b9f;
              }
            }
          }
        }
        .update-hidden {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  .div-table {
    table {
      tbody {
        tr {
          td {
            .double-cell {
              // align-items: center;
              p {
                font-weight: 500;
                font-family: "Inter_Medium" !important;
                text-transform: capitalize;
                color: #475467 !important;
              }
            }
            .pending {
              background-color: #fada88;
            }
            .settled {
              background-color: #69e0af;
            }

            .inactive-status {
              background-color: rgba(254, 205, 202, 0.3);
              color: #912018;
            }
          }
        }
      }
    }
  }
}
