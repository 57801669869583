.configurations {
  padding: 66px 27px 27px 30px;
  @media (max-width: 500px) {
    padding: 24px;
  }

  .title {
    font-family: "Calibre_Regular";
    font-weight: 400;
    font-size: 24px;
  }
}
