.customer_reward_history_wrapper {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #edf2f7;
    background: #fff;
    padding: 40px 25px;
    .customer_reward_history_row {
        .customer_reward_history_card_wrapper {
            @media (max-width: 1199px) {
                margin-bottom: 32px;
            }
            .customer_reward_history_card {
                display: flex;
                .customer_reward_history_icon_wrapper {
                    width: 48px;
                    height: 48px;
                    border-radius: 100%;
                    background: #f5f7f9;
                    margin-right: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .customer_reward_history_title_and_subTitle {
                    .customer_reward_history_title {
                        color: #a2aab5;
                        /* Regular/Type@14 */
                        font-family: "Inter_Regular";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        margin-bottom: 5px;
                        /* 19.6px */
                    }
                    .customer_reward_history_subTitle {
                        color: #000;
                        /* Semibold/Type@18 */
                        font-family: "Inter_SemiBold";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;
                        /* 25.2px */
                        span {
                            color: #a2aab5;
                            /* Regular/Type@14 */
                            font-family: Inter_Regular;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 140%;
                            /* 19.6px */
                        }
                    }
                }
            }
        }
    }
}