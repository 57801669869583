.sdk-activities-details-root {
  padding: 30px;
  .sdk-activities-details-cover {
    margin-bottom: 32.5px;
    .sdk-activities-ref-div {
      display: flex;
      .sdk-activities-ref-title {
        color: #211740;
        font-family: "Inter_Regular";
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-self: center;
        @media (max-width: 580px) {
          margin-bottom: 20px;
        }
        @media (max-width: 516px) {
          font-size: 16px;
        }
      }
      .sdk-activities-ref-number {
        font-size: 24px;
        line-height: 29px;
        font-weight: 400;
        color: #98a2b3;
        font-family: "Inter_Regular";
        margin-left: 10px;
        @media (max-width: 516px) {
          font-size: 16px;
        }
      }
      .sdk-activities-payment-method {
        margin-left: 10px;
        padding: 2px 8px 2px 8px;
        background-color: #f9f5ff;
        font-size: 14px;
        color: #5941a9;
        font-weight: 500;
        font-family: Inter_Regular;
        border-radius: 16px;
        text-transform: uppercase;
        width: max-content;
      }
      @media (max-width: 580px) {
        flex-direction: column;
      }
    }
  }
  .sdk-activities-details-card-root {
    margin-top: 30px;
    .total-amount-and-div {
      display: flex;
      margin-bottom: 28px;
      align-items: center;
      .tnx-check-sapan {
        margin-right: 16px;
      }
      .tnx-total-span {
        .title {
          font-family: Inter_Regular;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          margin-bottom: 4px;
          color: #98a2b3;
        }
        .value {
          font-size: 24px;
          font-weight: 500;
          font-family: "Inter_Regular";
          color: #261940;
          line-height: 29.05px;
          margin-right: 16px;
        }
      }
      .success,
      .failed,
      .pending,
      .successful {
        padding: 5px 10px 5px 10px;
        border-radius: 100px;
        font-size: 10px;
        height: 22px;
        font-weight: 500;
        font-family: "Inter_Regular";
        line-height: 12px;
      }
      .success,
      .successful {
        background-color: rgba(0, 196, 140, 0.07);
        color: #05603a;
      }
      .failed {
        background-color: rgba(254, 205, 202, 0.3);
        color: #912018;
      }
      .pending {
        background-color: #fffbf1;
        color: #93370d;
      }
    }
  }
  .sdk-activities-details-items-cover {
    margin-top: 20px;
    .info-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .disbursement-partner {
        margin: 32px 0;
        h6 {
          font-family: "Inter_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #121212;
          margin: 0;
          margin-bottom: 9px;
        }
        strong {
          font-family: "Inter_Medium";
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #5941a9;
        }
      }
    }

    .disbursement-table-details-cover {
      margin-top: 13px;
      padding: 16px;
      border: 1px solid #f2f4f7;
      border-radius: 4px;

      .title {
        padding-bottom: 8px;
        font-size: 16px;
        line-height: 19.36px;
        font-family: Inter_Regular;
        font-weight: 400;
        color: #211740;
      }
    }
  }
  .summary-details-cover {
    min-height: 200px;
    margin-bottom: 32px;
  }
  .sdk-activities-reason-cover {
    .sdk-activities-reason-title {
      border-bottom: 1px solid #f2f4f7;
      padding-bottom: 19px;
      font-family: Calibre_Regular;
      font-weight: 400;
      color: #211740;
    }
    .sdk-activities-reason-content {
      font-size: Inter_Regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 24px;
      letter-spacing: 0.2px;
      span {
        margin-right: 16px;
      }
    }
  }
}

.user-activities-comp {
  .single-user-activity {
    max-width: 400px;
    display: flex;
    .connector {
      display: flex;
      flex-direction: column;
      align-items: center;
      .circle {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(-2px 2px 6px rgba(58, 70, 93, 0.11965));
        .circle-inner {
          width: 8px;
          height: 8px;
          background: #5941a9;
          border-radius: 50%;
        }
      }
      .border-line {
        width: 1px;
        height: 70px;
        background: #e7edf3;
      }
    }
    .activity-info {
      margin-left: 16px;
      flex-grow: 1;
      .activity-text {
        font-family: "Inter_Medium";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #1f1f2e;
        text-transform: capitalize;
        margin-bottom: 12px;
        display: block;
      }
      .activity-status {
        background: #f5f4f9;
        border-radius: 4px;
        padding: 4px 8px;
        min-width: 83px;
        max-width: fit-content;
        text-align: center;
        span {
          font-family: "Inter_Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #5941a9;
        }
      }
      .style-failed {
        background: rgba(254, 205, 202, 0.3);
        span {
          color: #912018;
        }
      }
      .style-not-available {
        background: rgba(220, 216, 236, 0.2);
        span {
          color: #475467;
        }
      }
    }
    .activity-period {
      .activity-time {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: right;
        color: #98a2b3;
      }
    }
  }

  .show-more-less {
    margin-top: 32px;
    .show-cover {
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      span {
        font-family: "Inter_Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #5941a9;
        margin-right: 5px;
      }
      .show-icon {
        margin-bottom: 2px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}
