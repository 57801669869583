@media (max-width:654px) {
  tbody{
    padding:0px 0px 0px 16px !important;
    display:flex;
    border-bottom: 1px solid rgba(242, 244, 247, 0.8) !important;
    min-width: 350px !important;
    max-width: 100% !important;
    width: 100%;
    // border: 1px solid #EAECF0;


tr{
  width: 100% !important;
  padding-right:0px !important;
  margin: 0px !important;
  position: relative;
  // display: flex;
  .td-image-cover{

    width: 0%;
    padding:0px !important;
    .alt-img-name{
      @media (max-width:500px) {
        width: 32px;
        height: 32px;
      }
    }


  }
.mobile-double-column:nth-child(1){
  padding: 0px !important;

div{

padding: 0px !important;
margin:0px !important;
margin-right: 6px !important;
width: 6px;
height: 6px;
border-radius: 100%;
background-color: #F97066;
}
.ACTIVE{
  background-color: #00C48C !important;
}
.Active{
  background-color: #00C48C !important;
}
.SUCCESSFUL{
  background-color: #00C48C;
}
.FAILED{
  background-color: #F97066;
}
.PENDING{
  background-color:#FDB022
}
  .double-cell{
    display: flex !important;
    flex-direction: column !important;


  }


  // p{
  //   min-width: 6px !important;
  //   max-width: 6px !important;
  //   height: 6px !important;
  //   width: 6px !important;
  // }
}
.mobile-double-column:nth-child(2){
flex-grow: 1;
text-align: left;

// background: green;

.amount-charges-style{
  color: #B42318;
}
.double-cell{

  display: flex !important;
  flex-direction: column !important;
  :nth-child(1){
    display: flex;


  }
:nth-child(2){
  display: flex;
  // background: green;

  font-size: 12px !important;

margin-left: 0px !important;

:nth-child(1){
  margin-right: 5px;
  font-size: 12px !important;
}
}
:nth-child(3){

  display: flex;


}
}
    }
  .mobile-double-column:last-child{
    width: 100% !important;

.double-cell{

  display: flex !important;
  flex-direction: column !important;
  font-size: 13px !important;
  :nth-child(1){
    display: flex;
    justify-content: right;
    font-size: 13px !important;


  }
:nth-child(2){
  display: flex;
  justify-content: right;
  font-size: 12px !important;


  :nth-child(1){
    margin-right: 5px;
    font-size: 12px !important;

  }

}
:nth-child(3){
  display: flex;
  justify-content: right;

}
}
  }
  .double-cell-list{

    .single-rec{

p{
  font-size: 12px;
color: #98A2B3;
}
    }
    .double-rec{
      display: flex;
      width: 100%;

      font-size: 13px;
      color: #475467;

      :last-child{
        margin-left: 8px;
        font-size: 10px;
        background-color: rgba(82, 59, 159, 0.05);
        padding: 2px 6px 2px 6px;
        border-radius: 24px;
        color: #523B9F;

      }
    }

  }
  .mobile-list-icon-cover{
    position: absolute;
    right: 22px;
    top: 22px;
  }
}
}
}
.add-top-bottom-padding{
  padding-top: 16px !important;
  padding-bottom: 16px !important;

}