.employment-details-cover {
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 12px;
  .employment-details-title {
  }
  .select-search {
    .css-1fhf3k1-control {
      background: white;
    }
    .css-107lb6w-singleValue {
      color: #475467;
    }
  }
}
