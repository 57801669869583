.get-started-cover {
  padding: 80px 160px 93px 160px;
  &-title {
    color: #1d2939;
    font-size: 20px;
    line-height: 24.2px;
    font-family: Inter_Medium;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &-subtitle {
    color: #667085;
    font-size: 13px;
    line-height: 15.73px;
    font-weight: 400;
    font-family: Inter_Regular;
    margin-bottom: 56px;
  }
  &-contents {
    &-progress-card {
      width: 100%;
      border: 1px solid rgba(220, 216, 236, 0.6);
      padding: 24px 29px 23px 24px;
      border-radius: 10px;
      display: flex;
      &-content {
        flex-grow: 1;
        .progress-card-title {
          color: #1d2939;
          font-size: 18px;
          line-height: 21.78px;
          font-family: Inter_Medium;
          font-weight: 500;
          margin-bottom: 16px;
        }
        .progress-card-subtitle {
          margin-top: 16px;
          color: #667085;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          font-family: Inter_Medium;
        }
      }
      &-button {
        display: flex;
        align-self: center;
        color: #523b9f;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        font-family: Inter_Medium;
        &-link {
          text-decoration: none;
          cursor: pointer;
        }
      }
      .progress-bar {
        .progress-bar-rect-wrap-container {
          background: white;
          h2 {
            display: none !important;
          }
        }
      }
    }
    &-card-list {
      margin-top: 24px;
      &-col {
        .get-started-card-cover {
          height: 100%;

          background: linear-gradient(259.24deg, #fcf9fd 0.81%, #ffffff 72.62%);
          border-radius: 4px;
          border: 1px solid #f2f4f7;
          padding-top: 32px;
          padding-left: 24px;
          padding-right: 24px;
          padding-bottom: 32px;
          position: relative;
          &-title {
            color: #475467;
            line-height: 19.36px;
            font-size: 16px;
            font-weight: 500;
            font-family: Inter_Medium;
            margin-bottom: 16px;
          }
          &-subtitle {
            color: #667085;
            font-size: 12px;
            line-height: 19.2px;
            font-weight: 400;
            font-family: Inter_Regular;
            margin-bottom: 43px;
          }
          &-link {
           span{
             .span-link{
              color: #523b9f;
              font-size: 13px;
              line-height: 16px;
              font-weight: 500;
              font-family: Inter_Medium !important;
              cursor: pointer;
              text-decoration: none;
             }
            color: #523b9f;
            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
            font-family: Inter_Medium;
            cursor: pointer;
           }
            label{
              color: #523b9f;
              font-size: 13px;
              line-height: 16px;
              font-weight: 500;
              font-family: Inter_Medium;
              cursor: pointer;
            }
          }
          &-icon {
            position: absolute;
            bottom: 0%;
            right: 5px;
          }
        }
      }
      .explore-title-div {
        margin-top: 56px;
        margin-bottom: 32px;
        font-size: 16px;
        color: #1d2939;
        font-weight: 500;
        font-family: Inter_Medium;
        line-height: 19.36px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .get-started-cover {
    padding: 80px 50px 93px 50px;
    &-title {
      color: #1d2939;
      font-size: 20px;
      line-height: 24.2px;

      font-weight: 500;
      margin-bottom: 8px;
    }
    &-subtitle {
      color: #667085;
      font-size: 13px;
      line-height: 15.73px;
      font-weight: 400;

      margin-bottom: 56px;
    }
    &-contents {
      &-progress-card {
        width: 100%;
        border: 1px solid rgba(220, 216, 236, 0.6);
        padding: 24px 29px 23px 24px;
        border-radius: 10px;
        display: flex;
        &-content {
          flex-grow: 1;
          .progress-card-title {
            color: #1d2939;
            font-size: 18px;
            line-height: 21.78px;

            font-weight: 500;
            margin-bottom: 16px;
          }
          .progress-card-subtitle {
            margin-top: 16px;
            color: #667085;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .get-started-cover {
    padding: 40px 20px 93px 20px;
    &-title {
      color: #1d2939;
      font-size: 18px;
      line-height: 21.78px;

      font-weight: 500;
      margin-bottom: 8px;
    }
    &-subtitle {
      color: #667085;
      font-size: 12px;
      line-height: 19.2px;
      font-weight: 400;
      width: 75%;

      margin-bottom: 23px;
    }
    &-contents {
      &-progress-card {
        width: 100%;
        border: 1px solid rgba(220, 216, 236, 0.6);
        padding: 24px 24px 24px 24px;
        border-radius: 10px;
        display: grid;
        &-content {
          width: 100%;
          .progress-card-title {
            color: #1d2939;
            font-size: 15px;
            line-height: 18.15px;

            font-weight: 500;
            margin-bottom: 16px;
          }
          .progress-card-subtitle {
            margin-top: 16px;
            color: #667085;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            margin-bottom: 24px;
          }
        }
        &-button {
          display: flex;
          align-self: center;
          color: #523b9f;
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          font-family: Inter_Medium;
          &-link {
            text-decoration: none;
          }
        }
        .progress-bar {
          .progress-bar-rect-wrap-container {
            background: white;
            h2 {
              display: none !important;
            }
          }
        }
      }
      &-card-list {
        margin-top: 24px;
        &-col {
          margin-bottom: 20px;
          .get-started-card-cover {
            height: 100%;

            background: linear-gradient(
              259.24deg,
              #fcf9fd 0.81%,
              #ffffff 72.62%
            );
            border-radius: 4px;
            border: 1px solid #f2f4f7;
            padding-top: 32px;
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 32px;
            position: relative;
            &-title {
              color: #475467;
              line-height: 19.36px;
              font-size: 15px;
              font-weight: 500;
              font-family: Inter_Medium;
              margin-bottom: 16px;
            }
            &-subtitle {
              color: #667085;
              font-size: 12px;
              line-height: 19.2px;
              font-weight: 400;
              font-family: Inter_Regular;
              margin-bottom: 24px;
            }
            &-link {
              span {
                color: #523b9f;
                font-size: 13px;
                line-height: 16px;
                font-weight: 500;
                font-family: Inter_Medium;
                cursor: pointer;
              }
              label {
                color: #523b9f;
                font-size: 13px;
                line-height: 16px;
                font-weight: 500;
                font-family: Inter_Medium;
                cursor: pointer;
              }
            }
            &-icon {
              position: absolute;
              bottom: 0%;
              right: 5px;
            }
          }
        }
        .explore-title-div {
          margin-top: 12px;
          margin-bottom: 32px;
          font-size: 16px;
          color: #1d2939;
          font-weight: 500;
          font-family: Inter_Medium;
          line-height: 19.36px;
        }
      }
    }
  }
}
