.empty-payment-link {
  width: 576px;
  // height: 330px;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h6 {
    font-family: "Avenir-Medium";
    font-size: 1em;
    color: #121212;
    max-width: 350px;
  }
  .description {
    font-family: "Avenir-Roman";
    font-size: 0.8em;
    color: #555555;
    max-width: 370px;
    text-align: center;
  }
}
