.label-value-comp {
    display: flex;
    align-items: flex-start;
    .bullet-style {
        width: 4px;
        height: 4px;
        background: #5941a9;
        border-radius: 16px;
        margin-right: 8px;
        margin-top: 5px;
    }
    .info-cover {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        // flex-grow: 1;
        span,
        strong {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
        }
        span {
            color: #98a2b3;
        }
        strong {
            color: #344054;
            margin-top: 8px;
        }
    }
    .status-span {
        margin-left: 10px;
        min-width: 42px;
        width: fit-content;
        padding: 5px 10px;
    }
}

.mobile-label-value-comp {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid rgba(242, 244, 247, 0.8);
    &:last-child {
        border-bottom: none;
    }
    .info-cover {
        display: flex;
        span {
            color: #98a2b3;
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            // text-transform: uppercase;
        }
        strong {
            flex-grow: 1;
            color: #344054;
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: end;
        }
    }
    width: 100%;
}