.bank-page-cover {
  .bank-records-cover {
    margin-top: 24px !important;
    display: flex;
    gap: 16px;
    .bank-details-card-cover {
      padding-top: 19px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
      border-radius: 4px;
      border: 1px solid #f2f4f7;
      // width: 264px;
      .bank-name-and-logo-div {
        display: flex;
        align-items: center;
        margin-bottom: 51px;
        .bank-logo-div {
          margin-right: 10px;
          width: 24px;
          height: 24px;
        }
        .bank-name-div {
          font-size: 12px;
          color: #1d2939;
          font-weight: 400;
          font-family: "Inter_Regular";
        }
      }
      .accountNumber-and-name-div {
        .account-name-div {
          font-size: 12px;
          color: #1d2939;
          letter-spacing: -0.002em;
          font-weight: 400;
          font-family: "Inter_Regular";
          margin-bottom: 2px;
        }
        .account-number-div {
          display: flex;

          .account-number-span {
            flex-grow: 1;
            color: #667085;
            font-size: 11px;
            font-weight: 400;
            font-family: "Inter_Regular";
          }
          .primary-span {
            font-size: 9px;
            font-weight: 500;
            color: #027a48;
            font-family: "Inter";
            padding: 4px 8px 4px 8px;
            background-color: rgba(0, 196, 140, 0.07);
            border-radius: 10px;
          }
          .hide-primary {
            visibility: hidden;
          }
        }
      }
    }
  }
}
