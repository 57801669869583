.loan-summary-cover {
  margin-top: 39px;
  margin-bottom: 39px;
  .loan-summary-title-and-button-div {
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f4f7;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .loan-summary-title {
      font-family: "Inter_Regular";

      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      color: #121212;
      flex-grow: 1;
    }
  }
  .loan-summary-amount-and-status {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    .loan-summary-amount {
      margin-right: 16px;
      .loan-summary-amount-label {
        margin-bottom: 4px;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #98a2b3;
      }
      .loan-summary-amount-div {
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 23px;
        line-height: 28px;
        color: #261940;
      }
    }
    .status {
      width: fit-content;
      padding: 5px 12px 5px 12px;
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      border-radius: 100px;
    }
    .APPROVED {
      background: rgba(0, 196, 140, 0.07);
      color: #05603a;
    }
    .ONGOING {
      background-color: #fbebca;
      color: #93370d;
    }
    .PENDING {
      background-color: #fbebca;
      color: #93370d;
    }
    .CANCELLED {
      background: #d9dbde;
      color: #2f394b;
    }
    .PENDING_REFUND {
      background-color: #fbebca;
      color: #93370d;
    }
    .REFUND_COMPLETE {
      background: rgba(0, 196, 140, 0.07);
      color: #05603a;
    }
  }
}
