.loan-schedule-for-mobile-cover{
  display: flex;
  margin-bottom: 19px;
  .circle{
    width: 4px;
    height: 4px;
    border-radius: 16px;
    background-color: #523B9F;
    margin-right: 8px;
    margin-top: 9px;

  }
  .contents{
    margin-right:30px ;
    :first-child{
      color: #98A2B3;
      font-size: 13px;
      font-family: Inter_Regular;
      line-height: 16px;
      margin-bottom: 4px;
      font-weight: 400;

    }
    :last-child{
      color: #344054;
      font-size: 13px;
      font-family: Inter_Regular;
      line-height: 16px;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }
  .paid{
    background: rgba(0, 196, 140, 0.07) !important;
    display: flex;
    align-items: center;
    width: 41px;
    justify-content: center;
    color: #05603A;
    border-radius: 10px;
    height: 22px;
    font-size: 10px;
    font-family: Inter_Regular;
    font-weight: 500;
    line-height: 12.1px;
  }
  .not{
    background: #F2F4F7 !important;
    display: flex;
    padding: 5px 10px 5px 10px;
    align-items: center;
    width: fit-content;
    justify-content: center;
    color: #344054;
    border-radius: 10px;
    height: 22px;
    font-size: 10px;
    font-family: Inter_Regular;
    font-weight: 500;
    line-height: 12.1px;
  }
  .default{
    background: rgba(220, 216, 236, 0.3);
    display: flex;
    padding: 5px 10px 5px 10px;
    align-items: center;
    width: fit-content;
    justify-content: center;
    color: #312360;
    border-radius: 10px;
    height: 22px;
    font-size: 10px;
    font-family: Inter_Regular;
    font-weight: 500;
    line-height: 12.1px;
  }
}