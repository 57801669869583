.document-cover-page{
  padding-top: 30px;
  .customer-upload-document-button{
    width: 268px;
    height: 76px;
    background: #FFFFFF;
    border: 1px solid #F2F4F7;
    border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width:350px) {
    width: 100%;
  }
  .customer-upload-document-inner-cover{
    text-align: center;
    div{

     font-family: 'Calibre_Regular';
     font-style: normal;
     font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #523B9F;
    }
  }
  }
  // div{
  //   div{
  //     div{
  //       .file-size-cover{
  //         margin-top: 10px;
  //         font-family: 'Inter_Regular';
  //         font-weight: 400;
  //         font-size: 12px;
  //         line-height: 18px;
  //         color: #98A2B3;
  //       }
  //     }
  //   }
  // }
}