.admin-merchant-cover{

  display: flex;
  overflow-x: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    height: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background: none;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
  .admin-merchant-inner-cover{
    display: flex;
    .admin-tab-link{
      margin: 0px;
      margin-right: 20px;
      width: max-content;
      color: #757D8A;
      font-size: 16px;
      line-height: 24px;
      font-family: Inter_Regular;
      padding: 8px 24px 8px 0px !important;
      // background-color: green;
      cursor: pointer;
    }
    .admin-active-tab{
      margin: 0px;
      margin-right: 20px;
      width: max-content;
      color: #5941A9;
      font-size: 16px;
      line-height: 24px;
      font-family: Inter_Regular;
      padding: 8px 24px 8px 24px;
      cursor: pointer;
      background-color: #F5F4F9;
      border-radius: 8px;
    }
  }

}