.customer-details-wallet-cover {
  margin-top: 46px;
  .customer-wallet-info-to-check-eligibility-cover {
    .customer-wallet-info-col-cover {
      @media (max-width: 767px) {
        margin-bottom: 32px;
      }
    }
  }
}

.virtual-account {
  margin-top: 32px;
  border: 1px solid rgba(220, 216, 236, 0.2);
  background: rgba(220, 216, 236, 0.12);
  padding: 20px;
  border-radius: 6px;
  max-width: 256px;
  width: 100%;
  &-title-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #f2f4f7;
    h6 {
      font-family: "Calibre_Regular";
      color: #211740;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      padding: 0;
    }
    span {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      text-align: center;
      font-family: "Inter_Regular";
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
    }
    &-active {
      background: rgba(223, 248, 231, 0.7);
      color: #4caf6e;
    }
    &-inactive {
      background-color: #9120181a;
      color: #d92c20;
    }
  }
  &-account-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    margin-bottom: 25px;
    &-content {
      h4 {
        color: #0e2354;
        text-align: center;
        font-family: "Inter_SemiBold";
        font-size: 20px;
        padding: 0;
        margin: 0;
      }
      h6 {
        color: #81899e;
        text-align: center;
        font-family: "Inter_Regular";
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 175% */
        letter-spacing: 0.48px;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
      }
    }
  }
  &-balance-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      font-family: "Inter_SemiBold";
      h4 {
        color: #98a2b3;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      h6 {
        color: #0e2354;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
  &-activate-deactivate-button {
    margin-top: 6px;
  }
}

.check-eligibility {
  margin-top: 32px;
  border: 1px solid #eaecf0;
  padding: 30px;
  border-radius: 6px;
  max-width: 588px;
  width: 100%;
  h6 {
    font-family: "Calibre_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #1d2939;
    border-bottom: 1px solid #f2f4f7;
    padding-bottom: 19px;
    margin-bottom: 20px;
  }
  small {
    font-family: "Calibre_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(102, 112, 133, 0.8);
  }
  section {
    display: flex;
    margin-top: 16px;
    .text-wrap {
      width: 150px;
      margin-right: 12px;
    }
    .select-search-with-value {
      width: 150px;
      margin-bottom: 20px;
      margin-right: 12px;
    }
  }
  .response-modal {
    max-width: 400px;
    .json-cover {
      max-height: 250px;
      overflow-y: scroll;
      background-color: rgba(220, 216, 236, 0.2);
      padding: 12px;
      margin-bottom: 16px;
      .react-json-view {
        background-color: transparent !important;
        .object-content {
          span,
          div {
            color: #211740;
            font-size: 12px;
            font-family: monospace;
          }
        }
      }
    }
  }
}
