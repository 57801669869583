.customer-details-wallet-info-title {
  font-family: "Inter_regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #121212;
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 19px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  .admin-user-dropdown-cover {
    position: absolute;
    width: 120px;
    z-index: 1000;
    top: 30px;
    right: 0px;
  }
  .wallet-dropdown-details-list {
    background: #ffffff;
    box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
    border-radius: 4px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    div {
      font-size: 12px;
      line-height: 12px;
      font-family: Inter_Regular;
      font-weight: 400;
      padding: 8px 8px 9px 8px;
      height: 32px;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
    div:hover {
      background: rgba(220, 216, 236, 0.3);
      color: #523b9f !important;

      span {
        svg {
          path {
            fill: #523b9f;
          }
        }
      }
    }
  }
  .active-priority-cover {
    width: fit-content;
    height: 30px;
    background-color: #027a48;
    margin-left: 20px;
    border-radius: 23.94px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    min-width: 84px;
    span {
      flex-grow: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      font-family: Inter_Regular;
      color: #ffffff;
    }
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #ffffff;
    }
  }
  .inactive-priority-cover {
    width: fit-content;
    height: 30px;
    background-color: #98a2b3;
    margin-left: 20px;
    border-radius: 23.94px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 120px;
    cursor: pointer;

    span {
      flex-grow: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      font-family: Inter_Regular;
      color: #ffffff;
    }
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #ffffff;
      margin-right: 6px;
    }
  }

  .provider-cover {
    position: relative;
    .provider {
      border: 1px solid rgb(82, 59, 159);
      border-radius: 16px;
      margin-left: 16px;
      padding: 4px;
      min-width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      cursor: pointer;
      span {
        font-family: Inter_Regular;
        font-size: 12px;
        line-height: 15px;
        color: #523b9f;
        margin-right: 2px;
      }
    }
  }
}
