* {
  font-family: "Inter_Medium", Helvetica;
}
.card-wrapper {
  height: 176px;
  width: 100%;
  max-width: fit-content;
  border: 1px solid rgba(241, 235, 235, 0.76);
  border-radius: 4px;
  padding: 24px;

  .card-header-title-and-icon {
    display: flex;
    justify-content: space-between;
  }
  .card-header-title {
    align-items: center;
    background-color: #5941a90d;
    border-radius: 15px;
    display: inline-flex;
    justify-content: center;
    min-width: 110px;
    min-height: 32px;
    position: relative;
    width: fit-content;
    padding: 0px 9px;
  }
  .card-header-title-text {
    color: #5941a9;
    font-weight: 600;
    font-size: 20px;
    margin-left: -1px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
  }

}
.card-line-icon {
  height: 32px;
  width: auto;
  position: relative;
}
.card-balance {
  font-family: "Inter_Medium";
  font-weight: 500;
  font-size: 22px;
  color: #173e66;
  line-height: 24px;
  position: relative;
  height: 24px;
  width: 183px;
  margin-top: 12px;

  .card-content-label-text {
    font-size: 14px;
  }
  .card-kobo-balance {
    font-size: 16px;
  }
}
