.admin-sidebar-cover {
  background-color: #fbfbfc;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .admin-logo-cover {
    padding-top: 37px;
    padding-bottom: 41px;
    display: flex;
    justify-content: center;
    @media (max-width: 991px) {
      justify-content: flex-start;
      padding-left: 16px;
    }
  }
  .admin-tabs-root-cover {
    overflow-y: auto;
    .admin-tabs-cover {
      flex-grow: 1;
      margin-bottom: 10px;
      &::-webkit-scrollbar {
        width: 3px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background: none;
        border-radius: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: silver;
        border-radius: 10px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: silver;
      }
      ul {
        list-style-type: none;
        padding: 20px 16px 0px 16px;
        margin: 0px;
        a {
          color: white;
          text-decoration: none;
        }
      }

      .nav-sub-link-comp {
        cursor: pointer;
        .nav-link-custom {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-bottom: 0px;
          padding: 14.5px 20px 14.5px 20px;
          .nav-link-custom-p {
            font-size: 13px;
            color: #475467;
            font-family: Inter_Regular;
            padding-left: 18px;
            padding-right: 17px;
            margin-bottom: 0;
            text-decoration: none;
            line-height: 15.73px;
            letter-spacing: -0.1px;
          }
          .nav-link-custom-p-active {
            font-size: 14px;
            color: #523b9f;
          }
        }
        .nav-link-custom-active {
          padding-bottom: 7px;
        }
        .nav-sub-link {
          .nav-sub-link-p {
            font-size: 13px;
            color: #475467;
            font-family: Inter_Regular;
            margin-left: 20px;
            padding-left: 32px;
            padding-bottom: 7px;
            margin-bottom: 0;
            text-decoration: none;
            letter-spacing: -0.1px;
          }
        }
        .active-sub-link {
          .nav-sub-link-p {
            font-size: 14px;
            color: #523b9f;
          }
        }
      }
      .nav-sub-link-comp-active {
        background-color: rgba(220, 216, 236, 0.2);
        border-radius: 4px;
        padding: 7px 0px 13px 0px;
      }
      .nav-link-comp {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        padding: 0px 20px 0px 20px;
        .nav-p {
          font-size: 13px;
          color: #475467;
          font-family: Inter_Regular;
          // margin-left: 16px;
          padding: 14.5px 16px 14.5px 17px;
          margin-bottom: 0;
          text-decoration: none;
          line-height: 15.73px;
          letter-spacing: -0.1px;
        }
        svg {
          // fill: #523b9f;
          path {
            stroke: #475467;
          }
        }
      }
      .active-link {
        background: rgba(220, 216, 236, 0.2);
        padding: 8px 16px 8px 17px;
        .nav-p {
          font-size: 14px;
          color: #523b9f;
        }
        svg {
          // fill: #523b9f;
          path {
            stroke: #523b9f;
          }
        }
      }
      // .active {
      //   background: rgba(220, 216, 236, 0.2);
      //   padding: 8px 16px 8px 17px;
      //   border-radius: 4px;
      //   // svg {
      //   //   fill: #523b9f;
      //   //   path {
      //   //     fill: #523b9f;
      //   //   }
      //   // }
      //   .nav-p {
      //     font-size: 14px;
      //     color: #523b9f;
      //   }
      // }
      .inactive {
        background: transparent !important;
        svg {
          fill: initial;
          path {
            fill: initial;
          }
        }
        .nav-p {
          font-size: 14px;
          color: initial;
        }
      }
    }
    .admin-footer-cover {
      padding-left: 16px;
      padding-right: 24px;
      .admin-footer-line {
        width: 100%;
        height: 1px;
        background-color: #e6e7ec;
        opacity: 0.5;
      }
      .admin-footer-links {
        padding-top: 25px;
        ul {
          list-style-type: none;
          padding: 20px 0px;
          margin: 0px;
          a {
            color: white;
            text-decoration: none;
          }
        }
        .nav-link-comp {
          display: flex;
          align-items: center;
          margin-bottom: 0px;
          padding: 0px 20px 0px 20px;
          .nav-p {
            font-size: 13px;
            color: #475467;
            font-family: Inter_Regular;
            // margin-left: 16px;
            padding: 14.5px 16px 14.5px 17px;
            margin-bottom: 0;
            text-decoration: none;
            line-height: 15.73px;
            letter-spacing: -0.1px;
          }
          svg {
            // fill: #523b9f;
            path {
              stroke: #475467;
            }
          }
        }
        .active-link {
          background: rgba(220, 216, 236, 0.2);
          padding: 8px 16px 8px 17px;
          .nav-p {
            font-size: 14px;
            color: #523b9f;
          }
          svg {
            // fill: #523b9f;
            path {
              stroke: #523b9f;
            }
          }
        }
        // .active {
        //   background: rgba(220, 216, 236, 0.2);
        //   padding: 8px 16px 8px 17px;
        //   .nav-p {
        //     font-size: 14px;
        //     color: #523b9f;
        //   }
        // }

        // .active {
        //   background: rgba(220, 216, 236, 0.2);
        //   padding: 8px 16px 8px 17px;
        //   border-radius: 4px;
        //   // svg {
        //   //   fill: #523b9f;
        //   //   path {
        //   //     fill: #523b9f;
        //   //   }
        //   // }
        //   .nav-p {
        //     font-size: 14px;
        //     color: #523b9f;
        //   }
        // }
        .nav-link-comp-active {
          background-color: rgba(220, 216, 236, 0.2);
          border-radius: 4px;
          padding: 8px 16px 8px 17px;
        }
        .inactive {
          background: transparent !important;
          svg {
            fill: initial;
            path {
              fill: initial;
            }
          }
          .nav-p {
            font-size: 14px;
            color: initial;
          }
        }
      }
    }
  }
}
