.upload-input-kyc-cov {
  margin: 1em 0;
  width: 100%;
  max-width: 500px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  cursor: pointer;
  input {
    display: none;
  }
  .upload-input {
    width: 100%;
    position: relative;
    background: transparent;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23E6E7ECFF' stroke-width='2' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-clip: padding-box;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1em;
    cursor: pointer;
    height: 48px;

    .icon-comp {
      cursor: pointer;
      &:hover {
        svg {
          transform: scale(1.2);
        }
      }
    }

    .doc-name {
      align-items: center;
      overflow-x: hidden;
      .icon {
        margin-right: 0.6em;
      }
      small {
        font-family: "Inter_Regular";
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #d0d5dd;
        width: 60px;
        margin-left: 8px;
      }
      .placeholder {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #a6aab4;
        flex-grow: 1;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    label {
      width: 60px;
      margin-left: 8px;
      .upload-text {
        color: #523b9f;
        font-weight: 600;
        font-size: 12px;
        // line-height: 20px;
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
      }
    }
  }
  .upload-warning {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #98a2b3;
    margin: 0.7em 1em;
    display: block;
  }
}
