.header-details-cover{
  .title-cover{
    color: #667085;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'Inter_Regular';
    margin-bottom: 12.5px;

  }
}