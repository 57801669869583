.input-creator {
  width: 100%;
  margin-bottom: 1em;
  .input-label {
    font-size: 0.8em;
    font-family: "Inter_Regular";
    color: #555;
    margin-bottom: 0.2em;
    padding-left: 0.3em;
    display: block;
  }
  input {
    width: 100%;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
    margin-bottom: 1em;
    padding: 0.5em;
    font-size: 1em;
    font-family: "Inter_Regular";
    color: #344054;
    outline: none;
  }
}
