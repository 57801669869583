.single-customer-detail {
  header {
    .title-cov {
      .trx-title {
        display: flex;
        margin-top: 10px;
        h5 {
          font-family: "Calibre_Regular";
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          color: #211740;
          margin: 0;
          padding: 0;
          margin-right: 8px;
          text-transform: capitalize;
        }
      }
    }
  }
  section {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 32px;
    .customer-info {
      margin-top: 32px;
      border: 1px solid #f2f4f7;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 32px;
      @media (max-width:1189px) {
        padding: 32px 0px 32px 16px;
      }
      flex-grow: 1;
      @media (max-width:500px) {
        padding: 0px;
      }
      .customer-info-header {
        h5 {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #261940;
          margin: 0;
          margin-left: 16px;
          @media (max-width:500px) {
            font-size: 14px;
            margin-top: 16px;
          }
        }
      }
      aside {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(190px, 220px));
        column-gap: 2em;

        @media (max-width:1189px) {
          grid-template-columns: repeat(auto-fill, minmax(120px, 120px));
        }
        @media (max-width:600px) {
          grid-template-columns: repeat(auto-fill, minmax(100%, 100%));
        }
        .label-value-comp {
          margin-top: 32px;
          border-right: 1px solid #eaecf0;
          @media (max-width:661px) {
            border-right: none;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    .blacklist-customer {
      height: fit-content;
      margin-top: 32px;
      padding: 26px;
      min-width: 200px;
      max-width: 300px;
      background: #211740;
      border-radius: 6px;
      @media (max-width:600px) {
        min-width: 100%;
        max-width: 100%;
      }
      .blacklist-title {
        display: flex;
        align-items: center;
        h5 {
          font-family: "Calibre_Medium";
          font-weight: 500;
          font-size: 16px;
          color: white;
          margin: 0;
          margin-left: 8px;
          margin-top: 8px;
          line-height: 19px;
        }
      }
      .info {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #fdfdff;
        margin-top: 16px;
        display: block;
      }
      .dotted-info {
        display: flex;
        align-items: center;

        .dot {
          width: 4px;
          min-width: 4px;
          height: 4px;
          background: #fdfdff;
          border-radius: 50%;
          box-sizing: border-box;

        }
        .info {
          flex-grow: 1;
          margin-left: 6px;
        }
      }

      button {
        margin-top: 24px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        height: 36px;
        width: 100%;
        border-radius: 4px;
        span {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #211740;
          margin-left: 8px;
        }
      }
    }
  }
  .customer-trx {
    border: 1px solid #f2f4f7;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px;
    margin-top: 32px;
    .dashboard-header {
      margin-bottom: 4px;
    }
    .empty-record {
      border: none;
    }
  }
}
