.title_and_date_comp_cover {
    display: flex;
    align-items: flex-end;
    .title {
        flex-grow: 1;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #211740;
        font-family: Inter_Regular;
    }
    .date_container {
        display: flex;
        .date_cover {
            width: 113px;
            margin-left: 5px;
        }
    }
}