div {
  .verified-bank-details {
    margin-bottom: 44px;
    .verified-bank-tag-cover {
      margin-bottom: 24px;
      .verified-bank-tag {
        background-color: #f5f4f9;
        color: #5941a9;
        padding: 8px 24px 8px 24px !important;
        border-radius: 8px;
        width: fit-content;
        height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        font-family: Inter_Regular;
        margin-right: 16px;
      }
    }
  }
}
