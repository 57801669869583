.save-button {
  button {
    background: #5941a9;
    border-radius: 6px;
    height: 48px;
    span {
      font-family: Inter_Medium;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      color: #ffffff;
    }
  }
}
