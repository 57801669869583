.assign_permission_page {
    padding: 32px;
    .back_to_roles {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */
        color: rgba(102, 112, 133, 0.7);
        margin-bottom: 32px;
    }
}