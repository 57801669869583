.permission_list_group_cover {
    .permission_group_name {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        /* identical to box height, or 125% */
        word-wrap: break-word;
        color: #494a53;
        border-right: 1px solid rgba(222, 230, 238, 1);
        padding: 24px 23px !important;
        border-bottom: 1px solid rgba(222, 230, 238, 1);
    }
    .permission_list_name {
        border-bottom: 1px solid rgba(222, 230, 238, 1);
        padding: 24px 23px !important;
        .permission_list_content_cover {
            margin-bottom: 16px;
            position: relative;
            .checkbox_content {
                font-family: "Inter_Regular";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                /* identical to box height, or 167% */
                /* Black */
                color: #121212;
                margin-left: 0px;
            }
            .horizontal_list_icon_cover {
                position: absolute;
                top: 0px;
                right: 0px;
                display: none;
            }
            .dropdown_div_cover {
                width: 112px;
                position: absolute;
                right: 0px;
            }
        }
        .permission_list_content_cover:hover {
            cursor: pointer;
            .horizontal_list_icon_cover {
                position: absolute;
                top: 0px;
                right: 0px;
                display: block !important;
            }
        }
    }
}