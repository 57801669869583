.create_loan_modal_cover {
  background-color: white;
  border-radius: 6px;
  width: 416px;
  padding: 16px 32px 30px 32px;
  @media (max-width: 650px) {
    width: 100%;
  }
  .create_loan_cover {
    .create_loan_title {
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      color: #14142b;
      margin-bottom: 4px;
    }
    .create_loan_subtitle {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      color: #6e7191;
      margin-bottom: 50px;
    }
    .create_loan_btn_cover {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 18px;
    }
  }
}

.empty-date-field {
  position: relative;
  .date-label {
    position: absolute;
    top: 7px;
    left: 16px;
    font-size: 10px;
    color: #98a2b3;
    z-index: 5;
  }
  .react-datepicker__input-container {
    z-index: 6;
    background-color: transparent;
    input {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      border: 1px solid rgba(234, 236, 240, 0.7);
      font-size: 13px;
      padding-left: 16px;
      color: #475467;
      font-family: Inter_Regular;
      background-color: transparent;
    }
    input:focus {
      outline: none !important;
      border-color: 1px solid rgba(234, 236, 240, 0.7) !important;
    }
  }
  .calender_icon {
    position: absolute;
    top: 25%;
    left: calc(100% - 30px);
    z-index: 1;
  }
}

.has-date-field {
  position: relative;
  .date-label {
    position: absolute;
    top: 7px;
    left: 16px;
    font-size: 10px;
    color: #98a2b3;
    z-index: 5;
  }
  .react-datepicker__input-container {
    z-index: 6;
    background-color: transparent;
    input {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      border: 1px solid rgba(234, 236, 240, 0.7);
      font-size: 13px;
      padding-left: 16px;
      color: #475467;
      padding-top: 18px;
      font-family: Inter_Regular;
      background-color: transparent;
    }
    input:focus {
      outline: none !important;
      border-color: 1px solid rgba(234, 236, 240, 0.7) !important;
    }
  }
  .calender_icon {
    position: absolute;
    top: 25%;
    left: calc(100% - 30px);
    z-index: 1;
  }
}
