.onboarding-wrap {
  background-color: #f8f8fa;
  padding-top: 2rem;
  padding-bottom: 0;
  height: fit-content;
  min-height: 93.4vh;
  flex-grow: 1;
  // min-width: 400px;
  // max-width: 500px;
  // height: calc(100vh - 63px);
  // background-color: red;
  overflow-y: scroll;

  .onboarding-container {
    // margin-top: 3.7rem;
    // padding-bottom: 0;
    // padding-left: 2em;
    min-width: 450px;
    padding-bottom: 3em !important;

    .onboard-section {
      // padding: 1em;
      padding-top: 0;
      // padding-right: 2rem;
    }
  }
}

.activate-account {
  padding: 1em 0;
  margin-bottom: 1em;
  h2 {
    font-size: 2em;
    color: #252733;
    // font-weight: 800;
    font-family: 'Recoleta_Bold' !important;
    margin-bottom: 0.3em;
    font-family: Avenir;
    letter-spacing: 0.3px;
  }
  p {
    color: #a6aab4;
    font-size: 0.9em;
    font-family: 'Avenir-Book';
    margin-right: 1em;
    margin-bottom: 0;
  }
  .activate-cover {
    align-items: flex-end;
  }
}

.subtitle {
  color: #a6aab4;
  font-size: 0.9em;
  font-family: 'Avenir-Book';
}
.merchant-activate-account {
  padding: 40px 40px 0px 40px !important;
  &-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    font-family: Calibre_Regular;
    color: #211740;
  }
}
@media (max-width: 650px) {
  .merchant-activate-account {
    padding: 40px 24px 0px 24px !important;
    &-title {
      font-size: 24px;
      line-height: 24px;
      font-weight: 400;
      font-family: Calibre_Regular;
      color: #211740;
    }
  }
}
