.create_group_user_modal_cover {
    width: 346px;
    background-color: white;
    border-radius: 10px;
    padding: 32px;
    .create_group_user_modal_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #211740;
        font-family: Inter_Regular;
    }
    .create_group_user_modal_subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.002em;
        color: #667085;
        font-family: Inter_Regular;
        padding-bottom: 16px;
        border: 1px solid #f9fafb;
        margin-bottom: 24px;
    }
}