.customer-verification-cover{
  .customer-verification-link-cover{
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .customer-verification-bvn{
    margin-bottom: 16px;
    font-family: 'Inter_Regular';
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    .bvn-number-span{
      span{
        margin-left: 10px;
        margin-right: 5px;
      }
    }

  }
  .export-bankstatement{
    margin-top: 150px;
  }
}