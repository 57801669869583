.debit_card_cover {
  background-color: #362769;
  border-radius: 16px;
  max-width: 362px;
  min-width: 362px;
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .card-bng {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .debit_card_main {
    position: relative;
    z-index: 10;
    .debit_card_header {
      padding-top: 20px;
      padding-right: 22px;
      text-align: right;
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      opacity: 0.8;
      margin-bottom: 24px;
      text-transform: capitalize;
    }
    .card_type_and_icon_cover {
      display: flex;
      padding-left: 21px;
      padding-right: 25px;
      margin-bottom: 20px;
      align-items: center;
      .card_type_name_cover {
        flex-grow: 1;
        .type {
          font-family: "Inter_Regular";
          font-weight: 600;
          font-size: 19.7674px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 2px;
          text-transform: capitalize;
        }
        .bank {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 15.3746px;
          line-height: 19px;
          /* identical to box height */
          color: #ffffff;
          opacity: 0.8;
          text-transform: capitalize;
          // background-color: green;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 1120px) {
            width: 140px;
          }
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }
    }
  }
  .debit_card_footer {
    position: relative;
    z-index: 10;
    padding: 21px 45px;
    background-color: #111827;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    @media (max-width: 1270px) {
      padding: 21px 20px;
    }
    .debit_card_footer_span {
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 17.571px;
      line-height: 21px;
      /* Primary 25 */
      color: #fcfaff;
      margin-left: 12%;
      @media (max-width: 1270px) {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    max-width: 300px;
    min-width: 300px;
    height: fit-content;

    .debit_card_main {
      padding: 3px;
      .debit_card_header {
        padding-top: 10px;
        margin-bottom: 12px;
      }
      .card_type_and_icon_cover {
        margin-bottom: 12px;
        .card_type_name_cover {
          .type {
            font-size: 16px;
          }
          .bank {
            font-size: 12px;
          }
        }
      }
    }
    .debit_card_footer {
      padding: 12px 20px;
      .debit_card_footer_span {
        font-size: 12px;
      }
    }
  }
}
