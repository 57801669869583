.notfound {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .not-found-wrapper {
    margin-right: 2em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    h1 {
      font-size: 7em;
      font-family: 'Effra_Heavy';
      text-align: center;
      padding: 0;
      margin: 0;
      color: #001b3f70;
    }
    h3 {
      font-size: 1.5em;
      font-family: 'Avenir_Book';
      text-align: right;
      padding: 0;
      margin: 1em 0em 0.7em;
      color: #030303ab;
    }
    h4 {
      font-size: 1.1em;
      font-family: 'Avenir_Book';
      text-align: right;
      padding: 0;
      margin: 0em 0 2em;
      color: #22222269;
    }
    button {
      background: #1292ee;
      border: none;
      height: 3em;
      width: 10em;
      font-size: 0.9em;
      font-family: 'Effra_Heavy';
      color: white;
      border-radius: 3em;
    }
  }
  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
    svg {
      transform: scale(0.8);
    }
    .not-found-wrapper {
      font-size: 0.9em;
      align-items: center;
      margin: 0 auto;
      h3,
      h4 {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 400px) {
    flex-direction: column-reverse;
    svg {
      display: none;
    }
    .not-found-wrapper {
      font-size: 0.8em;
      align-items: center;
      margin: auto;
      h3,
      h4 {
        text-align: center;
      }
    }
  }
}
