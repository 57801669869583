.teams-cover-view{
  padding:40px !important;
  .mobile-add-member{
    display: none;
  }
  @media (max-width:650px) {
    padding:20px !important;
    .mobile-add-member{
      display: block;
      border-bottom: 1px solid #F9FAFB;
      border-left: 1px solid #F9FAFB;
      border-right: 1px solid #F9FAFB;
      padding-top: 14px;
      padding-bottom: 14px;
      text-align: center;
    }
    .hide-button{
      display: none !important;
    }
  }
  .table-container {
    border: 1px solid #F9FAFB;
  }
}