.loan-repayment-card-root {
  position: relative;
  .loan-repayment-card-cover {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    // background-color: green;
    .repayment-dot {
      width: 4px;
      height: 4px;
      background-color: #5941a9;
      border-radius: 16px;
      margin-right: 8px;
    }
    // :nth-last-child(1){
    //   border-left: 1px solid green;
    // }

    .details-and-button-cover {
      width: 100%;
      .loan-repayment-first-element {
        border-right: 1px solid #eaecf0;
      }
      .details-and-button-cover-row {
        .details-and-button-cover-col {
          .installment-number {
            font-family: "Inter_Regular";
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #98a2b3;
            margin-bottom: 4px;
          }
          .amount {
            font-family: "Inter_Medium";
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #261940;
          }
        }
        .status-and-date-div {
          .get-status-cover {
            display: flex;
            .Paid {
              font-family: "Inter_Medium";
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #00c48c;

              flex-grow: 1;
            }

            .not-due {
              font-family: "Inter_Medium";
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              color: #98a2b3;

              flex-grow: 1;
            }
            .Due {
              font-family: "Inter_Medium";
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              color: #f8c203;

              flex-grow: 1;
            }
            .Defaulting {
              font-family: "Inter_Medium";
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              color: #912018;
              flex-grow: 1;
            }
          }

          .status-div {
            font-family: "Inter_Regular";
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #98a2b3;
          }
        }

        .repayment-trigger-div {
          display: flex;
          justify-content: flex-start;

          padding-left: 25px !important;

          // @media (max-width:575px) {
          //   justify-content: flex-start;
          //   margin-top: 20px;
          // }
        }
      }
    }
  }
  .repayment-option-dropdown {
    position: absolute;
    width: 160px;

    right: -30px;
    top: -10px;
    .special-dropdown-details-list {
      z-index: 10;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.13);
    }
    // background-color: green;

    @media (max-width: 575px) {
      // right:-250% !important;
      // background-color: green;
      right: 0%;
      top: 35%;
      // top: auto;
    }
  }
}
