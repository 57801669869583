.filter-components {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  z-index: 100;
  right: 0;
  width: 280px;
  background: #ffffff;
  filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  // max-height: calc(100vh - 180px);
  .filter-header {
    padding: 16px;
    border-bottom: 1px solid #f2f4f7;
    h6 {
      font-family: "Calibre_Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      color: #000000;
      margin: 0;
      padding: 0;
    }
    span {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #98a2b3;
      display: block;
      margin: 0;
      padding: 0;
      cursor: pointer;
      &:hover {
        color: #523b9f;
      }
    }
  }
  .filter-content {
    // overflow-y: scroll;
    // overflow-y:auto ;

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 5px grey;
      background: none;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: silver;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: silver;
    }
    .filter-date {
      border-bottom: 1px solid #f2f4f7;
      .date-chips {
        cursor: pointer;
        // height: 102px;
        padding: 16px 16px 24px;
        padding-top: 0;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #f2f4f7;
        .date-chip {
          margin-top: 16px;
          padding: 4px 8px;
          /* Gray/Grey 100 */
          background: #f2f4f7;
          border-radius: 100px;
          margin-right: 8px;
          width: fit-content;
          height: fit-content;
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 12px;
          color: #344054;
          white-space: nowrap;
        }
        .selected-chip {
          background: #523b9f;
          color: white;
        }
      }
      .period-input {
        padding: 16px 16px 24px;
      }
    }
    .filter-select-comp {
      padding: 16px 16px 24px;
      border-bottom: 1px solid #f2f4f7;
      .filter-select-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        border: 1px solid #e6e7ec;
        border-radius: 2px;
        padding-bottom: 0;
        svg {
          cursor: pointer;
        }
        .filter-input-placeholder {
          padding-bottom: 8px;
        }
        .filter-selected-chips {
          margin-right: 8px;
          display: flex;
          flex-wrap: wrap;
          flex-grow: 1;
          .select-chip {
            width: fit-content;
            height: fit-content;
            margin-bottom: 8px;
            white-space: nowrap;
            padding: 2px 4px;
            background: #f8f9fb;
            border-radius: 2px;
            margin-right: 8px;
            .select-chip-text {
              font-family: "Inter_Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #81899e;
              margin-right: 4px;
            }
          }
        }
      }
      .filter-select-dropdown {
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: 4px;
        ul {
          z-index: 10;
          max-height: 400px;
          overflow-y: scroll;
          position: absolute;
          text-decoration: none;
          filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.1));
          right: 0;
          left: 0;
          list-style: none;
          padding: 10px 16px;
          background: #ffffff;
          border-radius: 4px;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0 6px;
            cursor: pointer;
            span {
              font-family: "Inter_Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #667085;
            }
            &:hover {
              span {
                color: #523b9f;
                // font-family: "Inter_Medium";
              }
            }
          }
          .selected {
            span {
              color: #523b9f;
            }
          }
        }
      }
      .filter-select-hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
    .filter-amount-input {
      padding: 16px 16px 32px;
      border-bottom: 1px solid #f2f4f7;
      .amount-input {
        input:-webkit-autofill {
          margin-top: 0 !important;
        }
        input,
        fieldset {
          padding: 8px 10px;
          .PrivateNotchedOutline-root-1 {
            top: 0;
          }
          box-sizing: border-box;
        }
        fieldset {
          border: none;
        }
        input {
          border: 1px solid #e6e7ec;
          border-radius: 2px;
          height: 32px;
          &::placeholder {
            color: #98a2b3;
            opacity: 1;
          }
        }
      }
    }
  }
  .filter-buttons {
    padding: 10px 16px;
    border: 1px solid #e6e7e9;
    border: none;
    width: 100%;
    button {
      width: 100%;
      span {
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.filters-hidden {
  opacity: 0;
  visibility: hidden;
}
.twin-input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .delimiter {
    width: 9px;
    height: 1px;
    background: #cdd0d8;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
    color: #344054;
    margin: 0 5px;
  }
  input,
  .date-picker-cover {
    flex-grow: 1;
    // width: 49%;
  }
}
.filter-input-label {
  font-family: "Inter_Medium";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #98a2b3;
  display: block;
  margin: 0;
  margin-bottom: 8px;
  margin-left: 2px;
}
.filter-input-placeholder {
  font-family: "Inter_Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #98a2b3;
}

.date-picker-cover {
  border: 1px solid #e6e7ec;
  border-radius: 2px;
  padding: 4px 10px;
  display: flex;
  .date-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value-span {
      font-family: "Inter_Regular";
      color: #344054;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.has-filter {
  width: 14px;
  height: 14px;
  background: #523b9f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  small {
    font-family: "Inter_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    color: #ffffff;
  }
}

.button-filter-cover {
  position: relative;
}
