.role_details_pages_cover {
    padding: 40px;
    .go_back {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */
        display: flex;
        align-items: center;
        color: rgba(102, 112, 133, 0.7);
        margin-bottom: 24px;
    }
}