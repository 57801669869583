.rnpl-voucher-upload {
  border-top: 1px solid #f2f4f7;
  padding: 30px 0;
  .rnpl-vouchers {
    margin-bottom: 20px;
    .rnpl-vouchers_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      color: #1d2939;
      font-family: "Calibre_Regular";
    }
    .rnpl-vouchers_subtitle {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      color: rgba(102, 112, 133, 0.8);
    }
  }

  .file-size-cover {
    margin-top: 10px;
    font-family: "Inter_Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #98a2b3;
  }

  .rnpl-search-cover {
    width: 100%;
    max-width: 506px;
    .amount-inp {
      margin: 0;
    }
  }

  .file-upload-design {
    width: 100%;
    max-width: 506px;
    margin-top: 20px;

    .label {
      padding: 16px;
      width: 100%;
      border: 1px solid #e4e7ec;
      border-radius: 8px;
      .no-file {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        strong {
          font-family: "Avenir-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #5941a9;
          margin: 12px auto 5px;
        }
        span {
          font-family: "Avenir-Roman";
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #555555;
        }
      }
    }
    .has-file {
      background: #ffffff;
      border: 1px solid #eaecf0;
      border-radius: 8px;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      width: 100%;
      .file-info {
        flex-grow: 1;
        margin: 0 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        strong {
          font-family: "Avenir-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          width: 300px;
        }
        .gear-cover {
          align-items: center;
          small {
            font-family: "Avenir-Roman";
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #555555;
            margin-right: 4px;
          }
          .gear-loading {
            svg {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }
    .uploading {
      font-family: "Inter_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #667085;
      margin-bottom: 10px;
      display: block;
    }
    input {
      display: none;
    }
  }
  .voucher-modal-wrap {
    .container {
      width: 100vh;
      max-width: 600px;
      .upload-wrapper {
        background-color: white;
        padding: 16px 32px 48px;
        border-radius: 10px;

        .title-head {
          margin-bottom: 20px;
          h6 {
            font-family: "Inter_Medium";
            font-weight: 500;
            font-size: 20px;
            color: #211740;
            margin: 0;
            margin-bottom: 4px;
            text-transform: capitalize;
          }
          span {
            font-family: "Avenir-Roman";
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            text-align: center;
            color: #555555;
          }
        }
        .chips {
          // border: 1px solid #eaecf0;
          background-color: #fbfbfb;
          box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
          padding: 10px;
          strong {
            font-family: "Avenir-Roman";
            font-weight: bold;
            font-size: 16px;
            line-height: 14px;
            text-align: center;
            color: #211740;
          }
          .chips-wrap {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 10px;
            // background-color: #5941a941;
            border-top: 1px solid #eaecf0;
            margin-top: 10px;
            padding: 10px;
            height: 200px;
            overflow-y: auto;
            justify-content: flex-start;
            align-content: flex-start;
            .voucher-chips {
              height: fit-content;
              border: 1px solid #eaecf0;
              border-radius: 10px;
              background-color: #fff;
              padding: 4px 8px;
              font-size: 11px;
              font-weight: 600;
              font-family: "Avenir-Roman";
              box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
              height: fit-content;
            }
          }
        }
        .twin-buttons-cover {
          width: 100%;
          max-width: 100%;
          display: flex;
          justify-content: center !important;
          .app-buttons {
            // width: 48%;
            flex-grow: 1;
          }
        }
      }
    }
  }
}
