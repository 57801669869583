.about-audit-wrapper {
  .about-audit-title {
    padding: 22px;
    h5 {
      margin: 0;
      font-family: "Inter_SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000e29;
    }
  }
  .about-audit-container {
    border-top: 1px solid #ecf2ff;
    border-bottom: 1px solid #ecf2ff;
    .about-audit-body {
      padding: 30px;
      .about-audit-text-container {
        display: flex;
        .content-text {
          h4 {
            font-size: 14px;
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            color: #90a0c2;
          }
          h3 {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #000e29;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }
    }
  }
  .about-audit-address {
    padding: 35px 30px 22px 30px;
    .content-text {
      h4 {
        font-size: 14px;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        color: #90a0c2;
      }
      h3 {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000e29;
      }
    }
  }
}
