.merchant-order-cover{
  padding-right: 40px;
  // @media (max-width:927px) {
  //   margin-right: 0px;
  // }
    .merchant-order-table-cover{
      @media (max-width:927px) {
        margin-top: 20px;
      }
    }
}