.admin-details-merchant-tab {
  display: flex;
  border-bottom: 1px solid #f2f4f7;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 24px !important;
  @media (max-width: 800px) {
    overflow-x: scroll;
    // margin-left: 20px !important;
    margin-right: 20px !important;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: white;
    }
  }
  .settings-nav-cover {
    margin-right: 23px;
    border-bottom: 1px solid white;

    .navrouter-link-comp {
      text-decoration: none !important;
      p {
        margin-bottom: 16px !important;
        font-size: 16px !important;
        line-height: 20px !important;
        color: #98a2b3;
        font-family: Calibre_Regular !important;
        font-weight: 400;
        @media (max-width: 650px) {
          width: max-content;
        }
      }
    }
  }
  .settings-active-nav-cover {
    border-bottom: 2px solid #523b9f;
    margin-right: 23px;
    .navrouter-link-comp {
      text-decoration: none !important;
      p {
        margin-bottom: 16px !important;
        font-size: 16px !important;
        line-height: 20px !important;
        color: #98a2b3;
        font-family: Calibre_Regular !important;
        font-weight: 400;
        @media (max-width: 650px) {
          width: max-content;
        }
      }
    }
  }
}
