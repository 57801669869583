.time_div_cover {
    border: 1px solid rgba(230, 231, 236, 1);
    padding: 8px 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    .time_div_content {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #344054;
        font-family: Inter_Regular;
        flex-grow: 1;
    }
}