.employment-select-search-wrapper {
  display: grid;
  .select-custom-styles {
    .css-1uccc91-singleValue {
      padding-top: 14px;
    }
    .css-107lb6w-singleValue {
      margin-top: 0px;
    }
  }
  .select-search-with-value {
    .css-107lb6w-singleValue {
      padding-top: 14px;
    }
    .css-g1d714-ValueContainer {
      padding-top: 20px;
    }
    .select-search {
      .css-b8ldur-Input {
        margin-top: 0px;
      }
    }
  }
}

.employment-upload-document-button {
  width: 268px;
  height: 76px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 350px) {
    width: 100%;
  }
  .employment-upload-document-inner-cover {
    text-align: center;
    div {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #523b9f;
    }
  }
}
