.customer-profile-cover {
  max-width: 709px;
  .MuiInputBase-input {
    color: #475467 !important;
  }
  .okhi-status-cover {
    display: flex;
    align-items: center;
    height: 50px;
    .okhi-status {
      width: 102px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: "Inter_Medium";
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
    }
    .pending {
      background: #ff9300;
      span {
        color: white;
      }
    }
    .verified {
      background: #3bdd99;
      span {
        color: white;
      }
    }
    .failed {
      background: transparent;
      border: 1px solid #ff5959;
      width: 124px;
      span {
        color: #ff5959;
      }
    }
    .default {
      background: #c1c1ce;
      span {
        color: #121212;
      }
    }
  }
}
