.repayment {
  max-width: 910px;
  min-height: 470px;
  border-bottom: 1px solid #f2f4f7;
  .save-wrap {
    display: flex;
    justify-content: flex-end;
    padding: 25px 0;
  }
}
