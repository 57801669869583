.admin-merchant-details-cover {
  padding: 31px;
  // padding-right: 31px;
  // .admin-merchant-details-inner-div{
  //   max-width: 709px;
  // }
  .merchant-title-and-logo {
    margin-top: 12px;
    display: flex;
    .merchant-logo {
      margin-right: 5px;
      img {
        width: 28px;
        height: 28px;
        border-radius: 100%;
      }
    }
    .merchant-name {
      color: #211740;
      font-size: 24px;
      font-weight: 400;
      font-family: "Inter_Regular";
      margin-right: 4px;
      height: 24px;
      text-transform: capitalize !important;
      display: flex;
      @media (max-width: 404px) {
        font-size: 16px;
        line-height: 35px;
      }
    }
    .merchant-active-status {
      margin-right: 5px;
      padding: 5px 10px 5px 10px;
      border-radius: 100px;
      background-color: rgba(0, 196, 140, 0.07);
      font-size: 10px;
      font-weight: 500;
      height: 22px;
      color: #05603a;
      display: block;
      text-transform: lowercase !important;
      margin-top: 6px;
      font-family: "Inter_Regular";
    }
    .merchant-active-status::first-letter {
      text-transform: uppercase;
    }
    .merchant-inactive-status {
      margin-right: 5px;
      padding: 5px 10px 5px 10px;
      border-radius: 100px;
      background-color: #d9dbde;
      font-size: 10px;
      font-weight: 500;
      height: 22px;
      color: #2f394b;
      margin-top: 6px;
      display: block;
      text-transform: lowercase !important;
      font-family: "Inter_Regular";
    }
    .merchant-inactive-status::first-letter {
      text-transform: uppercase;
    }
  }
}
