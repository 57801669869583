.dashboard-date-comp {
  position: relative;
  span {
    font-family: "Inter_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #475467;
    margin-right: 4px;
  }
  .selected-title {
    align-items: flex-end;
    cursor: pointer;
  }
  .date-select-dropdown {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    z-index: 110;
    right: 0;
    width: 120px;
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.1));
    display: flex;
    flex-direction: column;
    margin: 0;
    .dropdown-ul {
      width: 100%;
      margin-top: 12px;
      li {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #344054;
        text-align: left;
        padding: 16px 16px 8px;
        &:hover {
          background: rgba(220, 216, 236, 0.3);
          color: #523b9f;
        }
      }
    }
  }
  .date-select-hidden {
    opacity: 0;
    visibility: hidden;
  }
}
