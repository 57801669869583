.new_round_link_cover {
    display: flex;
    overflow-x: auto;
    padding-bottom: 4px;
    .new_round_link_list {
        display: flex;
        white-space: nowrap;
        margin-right: 26px;
        cursor: pointer;
        .new_round_link_list_count {
            width: 26px;
            height: 26px;
            border: 1px solid rgba(205, 208, 216, 1);
            border-radius: 100%;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            color: rgba(205, 208, 216, 1);
        }
    }
    .new_active_round_link_list {
        display: flex;
        white-space: nowrap;
        margin-right: 26px;
        cursor: pointer;
        .new_round_link_list_count {
            width: 26px;
            height: 26px;
            border: 1px solid rgba(82, 59, 159, 1);
            border-radius: 100%;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            color: rgba(82, 59, 159, 1);
        }
    }
}