.prefrence-page-view{
&-cover-row{
margin: 40px 0px 40px 39px !important;
max-width: 958px !important;
@media (max-width:650px) {
  margin: 40px 20px 40px 0px !important;
  padding:0px 20px 0px 20px !important ;
}
&-one{
  border-bottom: 1px solid #F2F4F7;
  padding-bottom: 40px;
}
&-two{
  border-bottom: 1px solid #F2F4F7;
  padding-bottom: 40px;
  margin-top: 40px;
}
&-last{
  margin-top: 40px;
}
}
}