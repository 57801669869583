.merchant-onboarding-side-nav {
  color: white;

  display: flex;
  flex-direction: column;

  .side-bar-body {
    flex-grow: 1;
    .merchant-container {
      background: rgba(246, 246, 249, 0.5);
      padding: 14px 16px 14px 32px;
      border-bottom: 1px solid rgba(234, 236, 240, 0.5);
      display: flex;
      &-logo {
        &-inner {
          background-color: #5941a9;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          font-size: 16px;
          font-weight: 400;
          font-family: Inter_Regular;
          margin-right: 12px;
        }
        img {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 12px;
        }
      }
      &-name {
        color: black;
        font-size: 12px;
        line-height: 16px;
        font-family: Inter_Regular;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &-title {
          margin-bottom: 2px;
          font-family: Inter_Regular;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &-id {
          color: rgba(25, 28, 35, 0.6);
          font-family: Inter_Regular;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .side-bar-list-cover {
      height: calc(100vh - 82px);
      overflow-y: scroll;
      background: rgba(246, 246, 249, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ul {
        list-style-type: none;
        padding: 20px 16px 0px 16px;
        margin: 0px;

        a {
          color: white;
          text-decoration: none;
        }
      }

      .heading {
        font-size: 0.9rem;
        font-weight: 400;
        color: #a6aab4;
        letter-spacing: 0.1rem;
      }
      .your-bus {
        font-family: Inter_Regular;
        font-weight: 500;
        font-size: 10px;
        line-height: 12.1px;
        padding: 0px 20px 0px 32px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #98a2b3;
        margin: 0px;
        margin-top: 36px;
        margin-bottom: 0px;
      }

      .nav-link-comp {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        padding: 0px 20px 0px 20px;

        .nav-p {
          font-size: 13px;
          color: #475467;
          font-family: Inter_Regular;
          // margin-left: 16px;
          padding: 8px 16px 8px 17px;
          margin-bottom: 0;
          text-decoration: none;
          line-height: 15.73px;
          letter-spacing: -0.1px;
        }
      }
      .active {
        background: rgba(220, 216, 236, 0.2);
        padding: 8px 16px 8px 17px;
        border-radius: 4px;

        svg {
          fill: #523b9f;
          path {
            fill: #523b9f;
          }
        }
        .nav-p {
          font-size: 14px;
          color: #523b9f;
        }
      }
      .inactive {
        background: transparent !important;
        svg {
          fill: initial;
          path {
            fill: initial;
          }
        }
        .nav-p {
          font-size: 14px;
          color: initial;
        }
      }
      .logout-and-settings {
        margin: 2em 0 0;
        hr {
          background: #e6e7ec;
          margin-left: 16px;
          margin-right: 16px;
          height: 1px;
        }
      }
      /* width */
      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background: none;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba(25, 28, 35, 0.6);
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(25, 28, 35, 0.6);
      }
    }
    svg {
      cursor: pointer;
    }

    .header {
      border-bottom: 1px solid #4d4d64;
      padding-bottom: 2rem;

      h1 {
        font-size: 1.3rem;
        font-weight: 400;
        font-family: Inter_Regular;
      }
    }
  }
}

.onboarding-shrink {
  min-width: 4em;
  .nav-link-comp {
    .nav-p {
      display: none;
    }
  }
}
@media (max-width:650px) {
  .merchant-onboarding-side-nav {
    color: white;

    display: flex;
    flex-direction: column;

    .side-bar-body {
      flex-grow: 1;
      .merchant-container {
        padding: 14px 16px 14px 32px;
      }
    }
  }
}