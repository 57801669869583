.login-form-view,
.register-page-cover-row {
  background-color: #f6f6f9;
  .login-form-view-cover {
    background-color: #f6f6f9;
    height: 100vh;
    overflow: auto;
    padding-left: 152px;
    padding-right: 152px;
    padding-bottom: 70px;
    &-row {
      &-cont {
        display: block;
        padding-top: 148px;
        @media (max-width: 767px) {
          display: none;
        }
        &-logo {
          margin-bottom: 100px;
        }
        &-title {
          color: #312360;
          font-size: 56px;
          letter-spacing: -0.01em;
          line-height: 60px;
          font-weight: 600;
          margin-bottom: 36px;
          font-family: Calibre_Semibold;

          br {
            display: block;
          }
          &-span {
            color: #312360 !important;
            position: relative;
            font-family: Calibre_Semibold;

            &-circle {
              position: absolute;
              top: -18px;
              left: -7px;
            }
          }
          span {
            color: #5941a9;
            font-family: Calibre_Semibold;
          }
        }
        &-subtitle {
          color: #667085;
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          width: 60%;
          padding-left: 22px;
          border-left: 1px solid #d0d5dd;
          margin-bottom: 42px;
          font-family: Inter_Regular;
          q {
            font-family: Inter_Regular;
          }
        }
        &-avater-div {
          display: flex;
          position: relative;
          margin-left: 10px;

          &-span {
            // background-color: silver;
            border-radius: 100%;
            width: fit-content;
            img {
              border: 1.5px solid #ffffff;
              border-radius: 200px;
              width: 50px;
              height: 50px;
              background-color: white;
              object-fit: contain;
            }
          }
          // :nth-child(1) {
          //   // background-color: silver;
          //   border-radius: 100%;
          //   width: 40px;
          //   height: 40px;
          // }
          :nth-child(-n + 5) {
            // background-color: silver;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            margin-left: -10px;
          }
          &-name {
            color: #1d2939;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 4px;
            font-family: Inter_Regular;
            span {
              color: #667085;
              font-size: 13px;
              line-height: 16px;
              font-weight: 400;
              font-family: Inter_Regular;
            }
          }
          span {
            font-size: 16px;
            flex-grow: 1;
            align-items: center;
            display: flex;
            margin-left: 16px !important;
            color: #667085;
            line-height: 24px;
            font-weight: 400;
            font-family: Inter_Regular;
          }
        }
      }
      &-form {
        padding-top: 213px;
        display: flex;
        justify-content: flex-end;
        // background: blue;
        // @media (max-width:767px) {

        //   padding-left: 25px !important;
        //   padding-right: 25px !important;
        // }

        &-cover {
          padding: 48px !important;
          background-color: white;
          width: 100%;
          max-width: 508px;
          @media (max-width: 767px) {
            max-width: 100%;
            // margin-top: 30px;
          }
          &-title {
            color: #312360;
            line-height: 36px;
            font-size: 28px;
            letter-spacing: -0.5%;
            font-weight: 600;
            font-family: Calibre_Semibold;
            margin-bottom: 6px;
          }
          &-subtitle {
            margin-bottom: 32px;
            color: #667085;
            font-family: Calibre_Regular;
            line-height: 20px;
            font-size: 18px;
            font-weight: 400;
          }
          button {
            border: none;
            font-family: Inter_Medium;
          }
          &-forget {
            display: flex;
            justify-content: flex-end;
            span {
              color: #523b9f;
              font-family: Calibre_Regular;
              font-size: 16px;
              font-weight: 400;
              line-height: 19.2px;
              margin-bottom: 24px;
            }
          }
          &-create {
            margin-top: 16px;
            text-align: center;
            color: #616e7c;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            font-family: Calibre_Regular;
            span {
              color: #523b9f;
              cursor: pointer;
              font-family: Calibre_Regular;
            }
          }
          .form-btn {
            height: 48px !important;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .login-form-view {
    .login-form-view-cover {
      background-color: #f6f6f9;
      height: 100vh;
      overflow: auto;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 70px;
      &-row {
        &-cont {
          &-title {
            br {
              display: none;
            }
          }
          &-subtitle {
            color: #667085;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            width: 80%;
            padding-left: 22px;
            border-left: 1px solid #d0d5dd;
            margin-bottom: 42px;
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .login-form-view {
    .login-form-view-cover {
      background-color: #f6f6f9;
      height: 100vh;
      overflow: auto;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 70px;
    }
  }
}
@media (max-width: 650px) {
  .login-form-view {
    .login-form-view-cover {
      background-color: #f6f6f9;
      height: 100vh;
      overflow: auto;
      padding-left: 0px;
      padding-right: 0px;
      &-row {
        &-cont {
          display: none;
        }
        &-form {
          padding-top: 0px;

          &-cover {
            background-color: white;
            padding: 30% 24px 24px 24px !important;
            width: 100%;
            height: 100vh;
            &-title {
              color: #312360;
              line-height: 36px;
              font-size: 24px;
              letter-spacing: -0.5%;
              font-weight: 600;
              font-family: Calibre_Semibold;
              margin-bottom: 4px;
            }
            &-subtitle {
              margin-bottom: 40px;
              color: #667085;
              font-family: Calibre_Regular;
              line-height: 20px;
              font-size: 18px;
              font-weight: 400;
            }
            button {
              border: none;
              font-family: Inter_Medium;
            }
            &-forget {
              display: flex;
              justify-content: flex-end;
              span {
                color: #523b9f;
                font-family: Calibre_Regular;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                margin-bottom: 24px;
              }
            }
            &-create {
              margin-top: 16px;
              text-align: center;
              color: #616e7c;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              font-family: Calibre_Regular;
              span {
                color: #523b9f;
                cursor: pointer;
                font-family: Calibre_Regular;
              }
            }
          }
        }
      }
    }
  }
}
