.select {
  .MuiFilledInput-root.Mui-focused {
    background-color: inherit;
  }

  .MuiFilledInput-input {
    padding: 27px 12px 9px;
  }
  .makeStyles-root-1 {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
  }
  .makeStyles-formControl-9 {
    width: 100% !important;
  }

  .MuiFilledInput-underline:before {
    border-bottom: none;
  }

  .makeStyles-root-8 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none;
  }
  .MuiInputBase-root {
    border-color: rgba(234, 236, 240, 0.7);
    border: 1px solid rgba(234, 236, 240, 0.7);
    border-radius: 4px;
    height: 50px;
  }

  .MuiFilledInput-underline:after {
    border-bottom: none !important;
  }

  .MuiFilledInput-underline:hover:before {
    border-bottom: none !important;
  }
}
