.business-info-cover {
  .title {
    color: #211740;
    font-size: 18px;
    line-height: 21.78px;
    font-weight: 500;
    font-family: Inter_Medium;
    margin-bottom: 10px;
  }
  .sub-title {
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3;
    font-family: Inter_Regular;
    font-weight: 400;
    margin-bottom: 32px;
  }
  .MuiSelect-icon {
    color: #523b9f !important;
  }
  .business-form-cover {
    .business-form-type {
      .business-form-type-row-profile {
        padding-bottom: 50px !important;
        border-bottom: 1px solid #f2f4f7;
        .business-form-type-col-left {
          padding-right: 10px !important ;
        }
        .business-form-type-col-right {
          padding-left: 10px !important ;
        }
      }
      .business-form-type-row-contact {
        margin-top: 49px !important;
        padding-bottom: 50px !important;
        border-bottom: 1px solid #f2f4f7;
        .title {
          color: #211740;
          font-size: 18px;
          line-height: 21.78px;
          font-weight: 500;
          font-family: Inter_Medium;
          margin-bottom: 10px !important;
        }
        .subtitle {
          font-size: 14px;
          line-height: 20px;
          color: #98a2b3;
          font-family: Inter_Regular;
          font-weight: 400;
          margin-bottom: 32px !important;
        }
        &-left {
          padding-right: 10px !important ;
          .left-inner-form-row {
            &-left-col {
              padding-right: 8px !important;
            }
            &-right-col {
              padding-left: 8px !important;
            }
          }
        }
        &-right {
          padding-left: 10px !important ;
          .right-inner-form-row {
            &-left-col {
              padding-right: 8px !important;
            }
            &-right-col {
              padding-left: 8px !important;
            }
          }
        }
      }

      &-row-social-media {
        padding-top: 49px !important;
        padding-bottom: 40px !important;
        border-bottom: 1px solid #f2f4f7;
        .title {
          color: #211740;
          font-size: 18px;
          line-height: 21.78px;
          font-weight: 500;
          font-family: Inter_Medium;
          margin-bottom: 10px !important;
        }
        .sub-title {
          font-size: 14px;
          line-height: 20px;
          color: #98a2b3;
          font-family: Inter_Regular;
          font-weight: 400;
          margin-bottom: 32px !important;
        }
        &-left {
          padding-right: 10px !important ;
        }
        &-right {
          padding-left: 10px !important ;
        }
      }
      &-button {
        margin-top: 41px;
        display: flex;
        justify-content: flex-end;
        div{
          width: 130px;
        }

      }
    }
  }
}
@media (max-width: 767px) {
  .business-info-cover {
    .title {
      color: #211740;
      font-size: 18px;
      line-height: 21.78px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .sub-title {
      font-size: 14px;
      line-height: 20px;
      color: #98a2b3;
      font-weight: 400;
      margin-bottom: 32px;
    }
    .MuiSelect-icon {
      color: #523b9f !important;
    }
    .business-form-cover {
      .business-form-type {
        .business-form-type-row-profile {
          padding-bottom: 50px !important;
          border-bottom: 1px solid #f2f4f7;
          .select-business-type-div{
            margin-bottom: 20px !important;
          }
          .business-form-type-col-left {
            padding-right: 0px !important ;
          }
          .business-form-type-col-right {
            padding-left: 0px !important ;
          }
        }
        .business-form-type-row-contact {
          margin-top: 49px !important;
          padding-bottom: 50px !important;
          border-bottom: 1px solid #f2f4f7;
          .title {
            color: #211740;
            font-size: 18px;
            line-height: 21.78px;
            font-weight: 500;

            margin-bottom: 10px !important;
          }
          .subtitle {
            font-size: 14px;
            line-height: 20px;
            color: #98a2b3;
            font-weight: 400;
            margin-bottom: 32px !important;
          }
          &-left {
            padding-right: 0px !important ;
            .left-inner-form-row {
              &-left-col {
                padding-right: 8px !important;
              }
              &-right-col {
                padding-left: 8px !important;
              }
            }
          }
          &-right {
            padding-left: 0px !important ;
            .right-inner-form-row {
              &-left-col {
                padding-right: 0px !important;
              }
              &-right-col {
                padding-left: 0px !important;
              }
            }
          }
        }

        &-row-social-media {
          padding-top: 49px !important;
          padding-bottom: 40px !important;
          border-bottom: 1px solid #f2f4f7;
          .title {
            color: #211740;
            font-size: 18px;
            line-height: 21.78px;
            font-weight: 500;
            margin-bottom: 10px !important;
          }
          .sub-title {
            font-size: 14px;
            line-height: 20px;
            color: #98a2b3;
            font-weight: 400;
            margin-bottom: 32px !important;
          }
          &-left {
            padding-right: 0px !important ;
          }
          &-right {
            padding-left: 0px !important ;
          }
        }
        &-button {
          margin-top: 41px;
          display: flex;
          justify-content: flex-end;
          div{
            width: 100%;
          }
        }
      }
    }
  }
}
