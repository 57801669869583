.payment-links {
  .empty-record {
    .empty-wrap {
      width: 390px;
      max-width: 390px;
    }
  }
  .table-container {
    border: 1px solid #f2f4f7;
  }
  .menu {
    width: 200px;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
    background-color: #ffffff;
  }

  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .modal-card {
    textarea {
      padding: 0;
      height: auto;
      border-radius: 0;
      margin-bottom: 0;
      border: none;
    }
  }

  .add-new-link {
    h6 {
      font-family: "Inter_Regular" !important;
      font-size: 12px !important;
      line-height: 14.52px !important;
      text-align: center;
      color: #667085;
      margin-bottom: 1em;
      text-align: center;
      letter-spacing: -0.2%;
    }
    .app-buttons {
      font-size: 1em;
      padding: 0.5em;
      margin-bottom: 0;
      width: 10em;
      border-radius: 8px;
    }
    .cancel-type {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      color: #344054;
    }
    .save-type {
      background: #5941a9;
      border: 1px solid #5941a9;
      color: white;
    }

    .show-more {
      margin: 0 auto !important;
      margin-bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      span {
        font-size: 0.8em;
        color: #98A2B3;
        margin-right: 0.6em;
        font-family: "Inter_Regular";
        letter-spacing: -0.2%;
      }
    }
.payment-link-save-div{
  display: flex;
}
  }

  .form-content {
    max-height: calc(100vh - 10em);
    overflow-y: scroll;
  }
}
