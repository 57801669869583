.merchant-business-profile-cover {
  padding: 42px 40px 40px 40px !important;
  @media (max-width:650px) {
    padding: 32px 20px 40px 20px !important;
  }
  width: 100%;
  max-width: 1100px;
  &-avater-cover {
    padding-bottom: 41px;
    border-bottom: 1px solid #f2f4f7;
  }
  &-row {
    margin-top: 41px !important;
    padding-bottom: 20px !important;
    border-bottom: 1px solid #f2f4f7;
    .business-profile-col-left {
      &-title {
        color: #1d2939;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 16px;
        font-weight: 400;
        font-family: Calibre_Regular;
      }
      &-subtitle {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: Calibre_Regular;
        color: rgba(102, 112, 133, 0.8);
        margin-bottom: 4px;
        width: 55%;
        @media (max-width:650px) {
          width: 80%;
        }
      }
      &-edit-profile {
        color: #523b9f;
        font-size: 13px;
        font-family: Inter_Regular;
        font-weight: 400;
        line-height: 20px;
        @media (max-width:650px) {
          margin-bottom: 36px;
        }
        span {
          cursor: pointer;
        }
      }
    }
    .business-profile-col-right {
      &-row {
        .business-profile-col-right-form-left-div {
          padding-right: 8px !important;
          @media (max-width:650px) {
            padding-right: 0px !important;
          }
        }
        .business-profile-form-right-div {
          padding-left: 8px !important;
          @media (max-width:650px) {
            padding-left: 0px !important;
          }
        }
      }
    }
  }
  .merchant-social-media-profile-cover-row {
    margin-top: 42px !important;
    padding-bottom: 80px !important;

    .merchant-social-media-profile-col-left {
      &-title {
        color: #1d2939;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 16px;
        font-weight: 400;
        font-family: Calibre_Regular;
      }
      &-subtitle {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: Calibre_Regular;
        color: rgba(102, 112, 133, 0.8);
        margin-bottom: 4px;
        width: 55%;
        @media (max-width:650px) {
          width: 65%;
        }
      }
      &-edit-profile {
        color: #523b9f;
        font-size: 13px;
        font-family: Inter_Regular;
        font-weight: 400;
        line-height: 20px;
        @media (max-width:650px) {
          margin-bottom: 32px;
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
}
