.webhook-view-cover{
  max-width: 958px !important;

&-api-cover{
margin:40px 0px 0px 40px !important;
padding-bottom: 17px !important;

  border-bottom: 1px solid #F2F4F7;
  @media (max-width:970px) {
    margin:40px 20px 0px 20px !important;
  }

 &-col-left {

    &-title {
      color: #1d2939;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      font-weight: 400;
      font-family: Calibre_Regular;
    }
    .webhook-view-subtitle-inner-cover{
      @media (max-width:767px) {
        display: flex;
        margin-bottom: 32px;
      }


     .webhook-view-cover-api-cover-col-left-subtitle {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: Calibre_Regular;
        color: rgba(102, 112, 133, 0.8);
        margin-bottom: 4px;
        width: 55%;

        @media (max-width:767px) {
          flex-grow: 1;
          min-width: 190px;
        }


      }
      .webhook-view-cover-api-cover-col-left-edit-profile {
        color: #523b9f;
        font-size: 13px;
        font-family: Inter_Regular;
        font-weight: 400;
        line-height: 20px;



        @media (max-width:767px) {
          width: 100%;
        text-align: end;
        }
        span {
          cursor: pointer;
        }
      }
    }

  }
}
.webhook-form-cover{
  margin:40px 0px 0px 40px !important;
  @media (max-width:970px) {
    margin:40px 20px 40px 20px !important;
  }
  &-col-left {
    &-title {
      color: #1d2939;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      font-weight: 400;
      font-family: Calibre_Regular;
    }
    .webhook-form-cover-subtitle-and-edit-cover{
      @media (max-width:767px) {
        display: flex;
        margin-bottom: 32px;
      }

      .webhook-form-cover-col-left-subtitle {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: Calibre_Regular;
        color: rgba(102, 112, 133, 0.8);
        margin-bottom: 4px;
        width: 55%;
        @media (max-width:767px) {
          flex-grow: 1;
          min-width: 190px;
        }
      }
      .webhook-form-cover-col-left-edit-profile{
        color: #523b9f;
        font-size: 13px;
        font-family: Inter_Regular;
        font-weight: 400;
        line-height: 20px;
        @media (max-width:767px) {
          width: 100%;
        text-align: end;
        }
        span {
          cursor: pointer;
        }
      }
    }

  }
  &-col-right{
    form{
      .button-div{
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
}