.account-check-icon {
  span {
    color: #344054;
    font-size: 13px;
    letter-spacing: -0.2%;
    line-height: 18px;
    font-weight: 400;
    font-family: Inter_Regular;
    margin-left: 8px;
    cursor: pointer;
  }
}
