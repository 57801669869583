
.admin-password-reset-root {
  background: #f6f6f9;
  border-radius: 10px;
  .cancle-icon-cover{
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-left: 16px;
  }
  .admin-password-reset-view {
    background: #f6f6f9;
    // background-color: green;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 44px 59px 79px 59px;
    // border-radius: 10px !important;
    &-cover {
      max-width: 344px;

      &-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
        &-div {
          background-color: #dcd8ec;
          width: 60px;
          height: 60px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-title {
        color: #312360;
        text-align: center;
        line-height: 38.4px;
        font-size: 32px;
        font-weight: 400;
        font-family: Calibre_Regular;
        margin-bottom: 16px;
      }
      &-subtitle {
        display: flex;
        justify-content: center;
        color: #667085;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 32px;

        font-family: Calibre_Regular;
        div {
          text-align: center;
          // width: 80%;
          font-family: Calibre_Regular;
        }
      }
      &-form {
        width: 100%;
        // padding-left: 34px;
        // padding-right: 34px;
        .form-btn {
          border: none;
          margin-top: 12px;
          font-size: 13px;
          font-weight: 500;
          font-family: Inter_Medium;
          height: 48px;
        }
      }
      &-account {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 16px;
        font-family: Calibre_Regular;
        &-link {
          font-family: Calibre_Regular;
          text-decoration: underline;
          color: #5941A9;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width:650px) {
  .overlay-view-cover {
    background: rgba(33, 23, 64, 0.55);
    .password-reset-view {
      background: #f6f6f9;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100vh;
      overflow-y: auto;
      &-cover {
        max-width: 100%;

        &-logo {
          display: flex;
          justify-content: center;
          margin-bottom: 32px;
          &-div {
            background-color: #dcd8ec;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &-title {
          color: #312360;
          text-align: center;
          line-height: 38.4px;
          font-size: 32px;
          font-weight: 400;
          font-family: Calibre_Regular;
          margin-bottom: 16px;
        }
        &-subtitle {
          display: flex;
          justify-content: center;
          color: #667085;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 32px;

          font-family: Calibre_Regular;
          div {
            text-align: center;
            width: 80%;
            font-family: Calibre_Regular;
          }
        }
        &-form {
          width: 100%;
          padding-left: 34px;
          padding-right: 34px;
          .form-btn {
            border: none;
            margin-top: 22px;
            font-size: 13px;
            font-weight: 500;
            font-family: Inter_Medium;
          }
        }
        &-account {
          color: #667085;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          margin-top: 16px;
          font-family: Calibre_Regular;
          &-link {
            font-family: Calibre_Regular;
          }
        }
      }
    }
  }

}
// .admin-password-reset-view{
//   border-radius: 10px;
// }