// .onboarding-top-nav {
//   display: flex;
//   background-color: white;
//   width: 100%;
//   z-index: 1000;
//   padding: 1em 2em;
//   border-bottom: 1px solid rgba(234, 236, 240, 0.5);

//   form {
//     position: relative;
//     width: 100%;
//     // max-width: 800px;
//     margin-right: 2em;
//     flex-grow: 1;
//     max-width: 600px;

//     input {
//       width: 100%;
//       padding: 0.5rem 2.9rem;
//       border: 1px solid #e6e7e9;
//       box-sizing: border-box;
//       border-radius: 8px;
//       outline: none;
//       font-family: "Avenir";
//       font-style: normal;
//       font-weight: normal;
//       font-size: 14px;
//     }

//     svg {
//       position: absolute;
//       z-index: 1;
//       left: 1rem;
//       top: 0.6rem;
//     }
//   }

//   .onboarding-nav-links {
//     margin-left: 0;
//     min-width: 250px;
//     display: flex;
//     justify-content: flex-end;
//     svg {
//       cursor: pointer;
//       margin: auto 1em;
//     }

//     .onboarding-test-mode,
//     .onboarding-live-mode {
//       background-color: #e3edff;
//       padding: 0.3rem 0.8rem;
//       border-radius: 100px;
//       margin: auto 1em;
//       font-weight: 500;
//       font-size: 0.9rem;
//       cursor: pointer;
//       color: #5941a9;
//       font-family: "Roboto";
//       font-weight: 500;
//     }
//     .onboarding-live-mode {
//       color: #00c48c;
//       background: rgba(0, 196, 140, 0.1);
//     }
//   }
// }
.merchant-navbar-cover {
  padding-top: 14.5px;
  padding-bottom: 11.5px;
  border-bottom: 1px solid rgba(234, 236, 240, 0.5);
  // @media (max-width:991px) {
  //   padding-left: 16px;
  // }
  &-mobile-title{
    flex-grow: 1;
   visibility: hidden;
  }
  &-menu{
    display: none;
    @media (max-width:991px) {
      display: block;
      padding-left: 20px;
    }
  }
  &-details {
    justify-content: flex-end;
    padding-right: 43px;
    &-avatar {
      cursor: pointer;
      display: block;
      display: flex;
      padding-right: 17px;
      border-right: 1px solid rgba(234, 236, 240, 0.7);
      span {
        margin-right: 8px;
        .avatar-initials {
          background-color: #5941a9;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          font-size: 16px;
          font-weight: 400;
          font-family: "Inter_Regular";
          color: #fff;
        }
      }
      &-names {
        &-title {
          color: #5941a9;
          font-size: 13px;
          line-height: 15.6px;
          font-weight: 400;
          font-family: Calibre_Regular;
          margin-bottom: 0px;
          &::first-letter {
            text-transform: capitalize;
          }
        }
        &-subtitle {
          color: #667085;
          letter-spacing: -0.1px;
          font-size: 14px;
          line-height: 16.8px;
          font-weight: 400;
          font-family: Calibre_Regular;
          span {
            margin-left: 6.5px;
          }
        }
      }
    }
    &-icon {
      margin-left: 20.5px;
      display: block;
    }
  }
}
@media (max-width: 680px) {
  .merchant-navbar-cover {
    padding-top: 7px;
    padding-bottom: 7px;
    background: #FAFAFC;
    border-bottom: none;
    &-mobile-title{
      flex-grow: 1;
    justify-content: center;
      align-items: center;
      display: flex;
      visibility: visible;
      font-size: 14px;
      line-height: 16.8px;
      letter-spacing: 4%;
      font-weight: 500;
      color: #00022F;
      font-family:Calibre_Medium;
      text-transform: uppercase;
    }
    &-menu{
      display: flex;
      align-items: center;
      padding-left: 20px;

    }
    &-details {
      justify-content: flex-end;
      padding-right: 20px;
      &-avatar {
        display: block;
        border-right: none;
        padding-right: 0px;
        height: 34px;
        &-names{
          display: none;
        }
      }
      &-icon {
        display: none;
      }
    }
  }
}
