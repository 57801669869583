.business-owner-form {
  width: 100%;
  &-title {
    font-size: 18px;
    line-height: 21.78px;
    color: #211740;
    font-weight: 500;
    font-family: Inter_Medium;
    margin-bottom: 10px;
  }
  .MuiSelect-icon {
    color: #523b9f !important;
  }
  &-subtitle {
    color: #98a2b3;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter_Regular;
  }
  .business-owner-form-cover {
    padding-top: 32px;
    .business-owner-form-row-profile {
      .business-owner-form-row-profile-col-left {
        padding-right: 10px !important;
      }
      .business-owner-form-row-profile-col-right {
        padding-left: 10px !important;
        .col-right-inner-row {
          .col-right-inner-row-inner-col-left {
            padding-right: 8px !important;
          }
          .col-right-inner-row-inner-col-right {
            padding-left: 8px !important;
          }
        }
      }
      .resident-details-title {
        font-size: 16px;
        line-height: 19.36px;
        color: #211740;
        font-weight: 500;
        font-family: Inter_Medium;
        margin-top: 20px !important;
        margin-bottom: 32px !important;
      }
    }
  }
  .upload-input-kyc-cov {
    margin: 0;
  }
  .means-of-identification-title {
    font-size: 16px;
    color: #211740;
    font-weight: 500;
    font-family: Inter_Medium;
    line-height: 19.36px;
    margin-top: 20px !important;
    margin-bottom: 32px !important;
  }
  .business-owner-border {
    margin-top: 36px;
    // border-color: #f2f4f7;
    border: 1px solid #f2f4f7;
  }
  .business-owner-button-div {
    margin-top: 24px !important;
    display: flex;
    justify-content: flex-end;
    span {
      margin-right: 16px;
    }
  }
}
@media (max-width: 767px) {
  .business-owner-form {
    width: 100%;
    &-title {
      font-size: 18px;
      line-height: 21.78px;
      color: #211740;
      font-weight: 500;
      font-family: Inter_Medium;
      margin-bottom: 10px;
    }
    .MuiSelect-icon {
      color: #523b9f !important;
    }
    &-subtitle {
      color: #98a2b3;
      font-size: 14px;
      line-height: 20px;
      font-family: Inter_Regular;
    }
    .business-owner-form-cover {
      padding-top: 32px;
      .business-owner-form-row-profile {
        .business-owner-form-row-profile-col-left {
          padding-right: 0px !important;
        }
        .business-owner-form-row-profile-col-right {
          padding-left: 0px !important;
          .col-right-inner-row {
            .col-right-inner-row-inner-col-left {
              padding-right: 8px !important;
            }
            .col-right-inner-row-inner-col-right {
              padding-left: 8px !important;
            }
          }
        }
        .resident-details-title {
          font-size: 16px;
          line-height: 19.36px;
          color: #211740;
          font-weight: 500;
          font-family: Inter_Medium;
          margin-top: 20px !important;
          margin-bottom: 32px !important;
        }
      }
    }
    .means-of-identification-title {
      font-size: 16px;
      color: #211740;
      font-weight: 500;
      font-family: Inter_Medium;
      line-height: 19.36px;
      margin-top: 20px !important;
      margin-bottom: 32px !important;
    }
    .business-owner-border {
      margin-top: 36px;
      // border-color: #f2f4f7;
      border: 1px solid #f2f4f7;
    }
    .business-owner-button-div {
      margin-top: 24px !important;
      display: flex;
      justify-content: flex-end;
      span {
        margin-right: 16px;
      }
    }
  }
}
