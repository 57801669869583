.register-page-view {
  background-color: #f6f6f9;
  .register-page-cover {
    background-color: #f6f6f9;
    height: 100vh;
    overflow: auto;
    padding-left: 152px;
    padding-right: 152px;
    padding-bottom: 70px;

    &-row {
      &-cont {
        display: block;
        padding-top: 148px;
        @media (max-width:767px) {
          display: none;
        }
        &-logo {
          margin-bottom: 100px;
        }
        &-title {
          color: #312360;
          font-size: 56px;
          letter-spacing: -0.01em;
          line-height: 60px;
          font-weight: 600;
          margin-bottom: 36px;
          font-family: Calibre_Semibold;

          br {
            display: block;
          }
          &-span {
            color: #312360 !important;
            position: relative;
            font-family: Calibre_Semibold;

            &-circle {
              position: absolute;
              top: -18px;
              left: -7px;
            }
          }
          span {
            color: #5941a9;
            font-family: Calibre_Semibold;
          }
        }
        &-subtitle {
          color: #667085;
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          width: 60%;
          padding-left: 22px;
          border-left: 1px solid #d0d5dd;
          margin-bottom: 42px;
          font-family: Inter_Regular;
          q {
            font-family: Inter_Regular;
          }
        }
        &-avater-div {
          display: flex;
          position: relative;
          margin-left: 10px;

          &-span {
            // background-color: silver;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            font-family: Inter_Regular;
          }
          // :nth-child(1) {
          //   // background-color: silver;
          //   border-radius: 100%;
          //   width: 40px;
          //   height: 40px;
          // }
          :nth-child(-n + 5) {
            // background-color: silver;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            margin-left: -10px;
          }
          &-name {
            color: #1d2939;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 4px;
            font-family: Lato_Regular;
            span {
              color: #667085;
              font-size: 13px;
              line-height: 16px;
              font-weight: 400;
              font-family: Inter_Regular;
            }
          }
          span {
            font-size: 16px;
            flex-grow: 1;
            align-items: center;
            display: flex;
            margin-left: 16px !important;
            color: #667085;
            line-height: 24px;
            font-weight: 400;
            font-family: Inter_Regular;
          }
        }
      }
      &-form {
        padding-top: 69px;
        &-cover {
          background-color: white;
          padding: 48px !important;
          width: 100%;
          max-width: 508px;
          @media (max-width:767px) {
            max-width: 100%;
          }
          .desktop-phone-input-div{
            display: block;
          }
          .mobile-phone-input-div{
            display: none;
          }
          .mobile-terms-and-condition{
            display: none;
          }
          &-title {
            color: #312360;
            margin-bottom: 6px;
            line-height: 36px;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: -0.5%;
            font-family: Calibre_Semibold;
          }
          &-subtitle {
            color: #667085;
            line-height: 20px;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 32px;
            font-family: Calibre_Regular;
          }
          &-button {
            button {
              width: 100%;
              font-size: 13px;
              line-height: 18px;
              color: white;
              height: 48px;
              font-family: Inter_Medium;
            }
          }
          &-terms {
            display: flex;
            font-size: 12px;
            line-height: 16px;
            color: #475467;
            align-items: center;
            margin-bottom: 26px;
            font-family: Inter_Regular !important;

            &-link {
              margin-right: 5px;
              margin-left: 5px;
              color: #98a2b3;
              text-decoration: underline;
              font-family: Inter_Regular !important;
            }
            span {
              font-family: Inter_Regular;
            }
          }
        }
        &-login-link {
          text-align: center;
          margin-top: 24px;
          font-size: 16px;
          line-height: 19px;
          color: #616e7c;
          font-family: Calibre_Regular !important;
          &-tag {
            font-family: Calibre_Regular !important;
            margin-left: 0.25em;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .register-page-view {
    .register-page-cover {
      background-color: #f6f6f9;
      height: 100vh;
      overflow: auto;
      padding-left: 100px;
      padding-right: 100px;
      padding-bottom: 70px;
      &-row {
        &-cont {
          &-title {
            br {
              display: none;
            }
          }
          &-subtitle {
            color: #667085;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            width: 80%;
            padding-left: 22px;
            border-left: 1px solid #d0d5dd;
            margin-bottom: 42px;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .register-page-view {
    .register-page-cover {
      background-color: #f6f6f9;
      height: 100vh;
      overflow: auto;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 70px;
    }
  }
}
@media (max-width: 820px) {
  .register-page-view {
    .register-page-cover {
      background-color: #f6f6f9;
      height: 100vh;
      overflow: auto;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 70px;
    }
  }
}
@media (max-width: 650px) {
  .register-page-view {
    .register-page-cover {
      background-color: white;
      height: 100vh;
      overflow: auto;
      padding-left: 0px;
      padding-right: 0px;
      &-row {
        &-cont {
          display: none;
        }
        &-form {
          padding-top: 0px;
          &-cover {
            background-color: white;
            padding: 64px 24px 24px 24px !important;
            width: 100%;
            .desktop-phone-input-div{
              display: none;
            }
            .mobile-phone-input-div{
              display: block;
            }
            .mobile-terms-and-condition{
              display: block;
              text-align: center;
              font-size: 16px;
              line-height: 22px;
              font-weight: 400;
              font-family: Calibre_Regular !important;
              color: #667085;
              margin-top: 24px;
              span{
              color: #98a2b3 !important;
              text-decoration: underline;

              }
            }
            &-title {
              color: #312360;
              margin-bottom: 4px;
              line-height: 36px;
              font-size: 24px;
              font-weight: 600;
              letter-spacing: -0.5%;
              font-family: Calibre_Semibold;
            }
            &-subtitle {
              color: #667085;
              line-height: 20px;
              font-size: 18px;
              font-weight: 400;
              margin-bottom: 40px;
              font-family: Calibre_Regular;
            }
            &-button {
              margin-top: 12px;
              button {
                width: 100%;
                font-size: 13px;
                line-height: 18px;
                color: white;
                height: 48px;
                font-family: Inter_Medium;
              }
            }
            // &-terms {
            //  display: block;


            // }
          }
        }
      }
    }
  }
}
