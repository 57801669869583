.roles_card_cover_comp {
    border: 1px solid rgba(230, 231, 236, 1);
    padding: 20px;
    border-radius: 4px;
    position: relative;
    height: 100%;
    .roles_title_status_and_icon_cover {
        display: flex;
        .roles_title_status_cover {
            flex-grow: 1;
            display: flex;
            .roles_card_title {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                font-family: Inter_Regular;
                color: #5941a9;
                margin-right: 10px;
                text-transform: capitalize;
            }
            .roles_active_card_status {
                padding: 6px 12px;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #4caf6e;
                font-family: Inter_Regular;
                background-color: rgba(76, 175, 110, 0.1);
                border-radius: 16px;
                height: fit-content;
            }
            .roles_inactive_card_status {
                padding: 6px 12px;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: rgba(47, 57, 75, 1);
                font-family: Inter_Regular;
                background-color: rgba(217, 219, 222, 1);
                border-radius: 16px;
                height: fit-content;
            }
        }
    }
    .roles_card_content_cover {
        margin-top: 12px;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */
        color: #676e7e;
        margin-bottom: 19px;
    }
    .roles_total_members {
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        /* identical to box height, or 140% */
        text-align: center;
        color: #34373f;
        width: fit-content;
        background-color: rgba(244, 246, 249, 1);
        padding: 4px 6px;
    }
    .roles_card_dropdown_cover {
        position: absolute;
        z-index: 4000;
        background-color: green;
        right: 24px;
        width: 112px;
        top: 25%;
    }
}