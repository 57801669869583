.avater-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato_Regular;
  img {
    width: 34px;
    height: 34px;
    color: white;
    font-size: 16px;
    border-radius: 100%;
    object-fit: cover;
  }
  strong {
    font-family: "Inter_Medium";
    font-weight: 400;
    font-size: 24px;
    color: white;
  }
}
