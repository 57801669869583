.select {
  position: relative;
}

.text-wrap {
  position: relative;
  .pre-icon-div {
    height: 50px;
    padding-left: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    border: 1px solid #eaecf0b2;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }

  .icon-comp {
    position: absolute;
    right: 2em;
    top: 1em;
  }
  .copy-icon-div {
    position: absolute;
    z-index: 1;
    right: 10px;
    padding-top: 16px;
    cursor: pointer;
    span {
      color: #523b9f;
      font-size: 11px;
      font-family: Inter_regular;
      font-weight: 700;
    }
  }
}
.MuiInputBase-input .Mui-disabled {
  // background-color: white !important;
  // // border: 1px solid rgba(234, 236, 240, 0.7);
  border-radius: 4px;
  // height: 9.5px;
  height: 100%;
}
.MuiInputBase-root.Mui-disabled {
  // background-color: white !important;
  height: 100% !important;
}
