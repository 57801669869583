.permission_page_cover {
    .role_details_ttile_and_create_btn_cover {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .role_details_title {
            flex-grow: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            color: #14142b;
            font-family: "Inter_Regular";
            display: flex;
            align-items: center;
            span {
                font-family: "Inter_Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                /* identical to box height, or 120% */
                color: #98a2b3;
                margin-left: 4px;
            }
        }
        .create_btn_cover {}
    }
    .permision_subtitle_and_list_cover {
        .permission_subTitle_cover {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            /* Black */
            color: #121212;
            margin-bottom: 24px;
        }
    }
}