.alternative_customer_success_modal {
    width: 416px;
    background-color: white;
    border-radius: 10px;
    padding: 20px 32px;
    @media (max-width: 650px) {
        width: 100%;
    }
    .alternative_phone_success_modal {
        .success_icon_wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
        }
        .success_modal_title {
            font-family: "Calibre_Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            /* identical to box height */
            text-align: center;
            /* Black */
            color: #121212;
            margin-bottom: 12px;
        }
        .success_modal_sub_title {
            font-family: "Inter_light";
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            /* or 125% */
            text-align: center;
            color: #667085;
            span {
                color: #5941a9;
                text-transform: capitalize;
                font-family: "Inter_light";
            }
        }
        padding-bottom: 30px;
    }
}