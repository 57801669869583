.create_permission_modal {
    width: 416px;
    background-color: white;
    padding: 20px 32px 32px 32px;
    border-radius: 8px;
    .create_permission_input_and_button {
        .create_permission_title_icon_cover {
            display: flex;
            margin-bottom: 48px;
            .create_permission_icon_cover {
                width: 32px;
                height: 32px;
                background-color: rgba(89, 65, 169, 0.05);
                border-radius: 100%;
                margin-right: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .create_permission_title_and_subTitle {
                .create_permission_title {
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */
                    color: #14142b;
                    margin-bottom: 2px;
                }
                .create_permission_subTitle {
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height, or 167% */
                    color: #6e7191;
                }
            }
        }
        .create_permission_button_cover {
            display: flex;
            justify-content: flex-end;
        }
    }
}