.merchant-title-and-logo {
  .one-time-cover {
    margin-top: 6px;
    width: fit-content;
    height: 22px;
    background-color: rgba(89, 65, 169, 0.1);
    // margin-left: 10px;
    border-radius: 23.94px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    min-width: 70px;
    span {
      flex-grow: 1;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      font-family: Inter_Regular;
      color: #5941a9;
    }
    .circle {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #ffffff;
      margin-right: 4px;
    }
  }

  .inactive-onetime-cover {
    background-color: #98a2b3;
    // margin-left: 10px;

    span {
      color: #fff;
    }
  }

  .select-search-with-value {
    margin-bottom: 24px;
    height: 72px !important;
  }
  .label-name {
    margin-bottom: 5px;
    padding-left: 2px;
  }
}
