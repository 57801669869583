.success_confirm_modal {
  // max-width: 346px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  // display: flex;
  // justify-content: center;
  // height: 353px;
  max-height: 353px;
  max-width: 346px;
  // width: 461px;
  flex-direction: column;
  @media (max-width: 650px) {
    width: 100%;
    // max-width: 346px;
  }

  .success_icon_wrapper {
    display: flex;
    justify-content: center;
    margin: 15px 0px 15px 0px;
  }
  .success_modal_title {
    font-family: "Inter_Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    /* Black */
    color: #121212;
    margin-bottom: 12px;
  }
  .success_modal_sub_title {
    font-family: "Inter_Regular";
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* or 125% */
    text-align: center;
    color: #667085;
    span {
      color: #5941a9;
      text-transform: capitalize;
      font-family: "Inter_light";
    }
  }
  padding-bottom: 30px;
}
.button-cover-div {
  //   // max-width: 370px;
  //   // margin: 2.4em auto 0;
  //   // width: fit-content;
  //   // display: flex;
  //   width: 100%;

  .save-type {
    margin-top: 40px;
    width: 100%;
    background: #5941a9;
    border: 1px solid #5941a9;
    color: white !important;
    margin-top: 40px;
    font-size: 14px;
    span {
      color: white !important;
    }
  }
}
