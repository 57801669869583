.reward_card_wrapper {
    padding: 24px;
    .card_title_and_icon_wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .card_title_icon {
            margin-right: 8px;
            padding: 8px;
            border-radius: 100%;
            width: 40px;
            height: 40px;
        }
        .card_title_wrapper {
            flex-grow: 1;
            color: #121212;
            /* Regular/Type@14 */
            font-family: "Inter_Regular";
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            /* 19.6px */
        }
    }
    .card_points_and_total_user_wrapper {
        display: flex;
        margin-bottom: 17px;
        .card_points_wrapper {
            flex-grow: 1;
            color: #121212;
            font-family: "Inter_Bold";
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            /* 19.2px */
        }
        .card_total_users_wrapper {
            color: #121212;
            font-family: "Inter_Bold";
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            /* 19.2px */
        }
    }
    .card_pacentage_rate_and_chart_wrapper {
        display: flex;
        align-items: center;
        .card_pacentage_rate_wrapper {
            flex-grow: 1;
            display: flex;
            align-items: center;
            span {
                margin-right: 4px;
            }
            .pacentage_rate {
                color: #2e2c34;
                /* Semibold/Type@14 */
                font-family: "Inter_SemiBold";
                font-size: 14px;
                font-weight: 600;
                line-height: 140%;
                span {
                    color: #83879b;
                    /* Regular/Type@14 */
                    font-family: "Inter_Light";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 140%;
                    /* 19.6px */
                }
            }
        }
        .card_chart_wrapper {}
    }
}