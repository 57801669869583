.list_roles_comp_cover {
    padding: 32px 34px 34px 10px;
    border-top: 1px solid rgba(222, 230, 238, 1);
    // border-bottom: 1px solid rgba(222, 230, 238, 1);
    color: rgba(71, 84, 103, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: Inter_Regular;
    cursor: pointer;
    .span_name {
        color: rgba(89, 65, 169, 1);
        font-weight: 500;
        font-size: 16px;
        font-family: Inter_Regular;
        text-transform: capitalize;
        @media (max-width: 650px) {
            font-size: 12px;
        }
    }
    .span_content {
        color: rgba(71, 84, 103, 1);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-family: Inter_Regular;
        @media (max-width: 650px) {
            display: none;
        }
    }
}

.active {
    background-color: rgba(220, 216, 236, 0.2);
}

.inActive {
    background-color: white;
}