.select-wrap {
  // margin: 1em auto;
  // margin-bottom: 20px;
  max-width: 39em;
  width: 100%;
  // margin-top: 1em;
  margin-top: 0;
  min-width: 50px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  .label-name {
    display: block;
    font-family: Inter_Regular !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px !important;
    color: #a6aab4;
  }
  .select-search {
    position: relative;
    font-family: Inter_Regular !important;
    font-size: 13px;
    width: 100%;
    span {
      display: none;
    }
    // margin: 0.4em 0;
    div {
      &:focus {
        outline: none;
        border: 1px solid #1470eb;
      }
    }
    .css-yk16xz-control {
      height: 50px;
      background-color: #fff;
      border: 1px solid rgba(234, 236, 240, 0.7) !important;
      font-size: 13px !important;
      color: #98a2b3 !important;
      background-color: transparent;
    }
  }
  .css-26l3qy-menu {
    z-index: 100 !important;
  }
  .css-1uccc91-singleValue {
    font-family: Inter_Regular !important;
    font-size: 13px;
    color: #98a2b3 !important;
  }
  .css-g1d714-ValueContainer {
    height: 50px;
    background-color: transparent;
  }
  .css-1pahdxg-control {
    height: 50px;
    border-color: rgba(234, 236, 240, 0.7) !important;
    background-color: transparent !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tj5bde-Svg {
    fill: #523b9f;
  }
}

.select-search-with-value {
  // margin: 1em auto;
  // margin-bottom: 20px;
  max-width: 39em;
  width: 100%;
  // margin-top: 1em;
  margin-top: 0;
  min-width: 50px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  .label-name {
    display: block;
    font-family: Inter_Regular !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px !important;
    color: #98a2b3;
  }
  .select-search {
    position: relative;
    font-family: Inter_Regular !important;
    font-size: 13px;
    width: 100%;
    span {
      position: absolute;
      z-index: 1;
      color: #98a2b3;
      font-size: 10px;
      font-family: Inter_Regular !important;
      display: block;
      left: 12px;
      top: 8px;
    }
    .css-1wa3eu0-placeholder {
      margin-top: 8px;
    }
    .css-b8ldur-Input {
      margin-top: 16px;
    }
  }
}

.select-input-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  // padding-top: 20px;
}

.select-custom-styles {
  position: relative;
  cursor: pointer;
  .drop-down-select-search {
    position: absolute;
    right: 12px;
    top: 50%;
    // transform: translateY(-50%);
    height: 50px;
    align-items: center;
    display: flex;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    display: none;
  }
  .css-g1d714-ValueContainer {
    height: 50px;
    background-color: transparent;
  }
  .css-1uccc91-singleValue {
    font-family: Inter_Regular !important;
    font-size: 13px;
    color: #475467 !important;
    // padding-top: 10px;
  }
  .css-26l3qy-menu {
    z-index: 100 !important;
  }
  .css-9gakcf-option,
  .css-1n7v3ny-option,
  .css-yt9ioa-option {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Inter_Regular";
    color: #344054;
  }
  .css-yk16xz-control {
    outline: none;
    border: 1px solid rgba(234, 236, 240, 0.7);
    min-height: 50px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(234, 236, 240, 0.7);
      background-color: transparent;
    }
  }
  .css-1n7v3ny-option {
    background: rgba(220, 216, 236, 0.3);
    color: #523b9f;
  }
  .css-9gakcf-option {
    background-color: #523b9f;
    color: white;
  }
  .css-1pahdxg-control {
    outline: none;
    box-shadow: none;
    min-height: 50px;
    border: 1px solid rgba(234, 236, 240, 0.7);
    background-color: transparent;
    &:hover {
      border: 1px solid #523b9f6f;
    }
  }
  .css-1fhf3k1-control {
    background: rgba(209, 209, 209, 0.1);
    border: 1px solid rgba(234, 236, 240, 0.7);
    height: 50px;
  }
  .css-26l3qy-menu {
    box-shadow: none;
    border: 1px solid #f2f4f7;
    border-radius: 2px;
  }
  // .css-b8ldur-Input {
  //   margin-top: 8px;
  // }
  .css-2b097-container {
    border: 1px solid rgba(234, 236, 240, 0.7);
    outline: none;
    box-shadow: none;
    &:focus-visible {
      outline: none !important;
    }
  }
  // .css-107lb6w-singleValue {
  //     // margin-top: 8px;
  // }
}

// .basic-single {
//     background-color: green;
//     height: 10px;
// }
// .select-custom-styles .css-g1d714-ValueContainer {
//     height: 60px !important;
// }
// .basic-single {
//     .css-1fhf3k1-control {
//         .css-g1d714-ValueContainer {
//             .css-107lb6w-singleValue {
//                 color: green;
//                 margin-top: 7px !important;
//             }
//         }
//     }
// }
.input_select_with_value {
  div {
    div {
      div {
        margin-top: 7px !important;
      }
    }
  }
}

.css-1wa3eu0-placeholder {
  color: inherit !important;
}

.react-datepicker-popper {
  z-index: 4 !important;
}
