.create-manual-loan-and-more {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  .loan-more-button-wrapper {
    position: relative;
    .loan-more-button {
      display: flex;
      align-items: center;
      border: 1px solid #e6e7ec;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #fff;
    }
    .admin-user-dropdown-cover {
      position: absolute;
      width: 151px;
      z-index: 1000;
      top: 55px;
      right: 0px;
    }
  }
  .dropdown-details-list {
    position: absolute;

    background: #ffffff;
    box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
    border-radius: 4px;
    top: 0% !important;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    div {
      font-size: 12px;
      line-height: 12px;
      font-family: Inter_Regular;
      font-weight: 400;
      padding: 8px 8px 9px 8px;
      height: 32px;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
    div:hover {
      display: none;
      background: rgba(220, 216, 236, 0.3);
      color: #523b9f !important;

      span {
        svg {
          path {
            fill: #523b9f;
          }
        }
      }
    }
    div:last-child:hover {
      color: #523b9f !important;
      background-color: rgba(220, 216, 236, 0.3);
      span {
        svg {
          // fill: #523b9f !important;
          path {
            // fill: none !important;
            stroke: none !important;
          }
        }
      }
    }
  }
}

.admin-user-dropdown-cover {
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 1000;
}
