.bMBuBa {
  width: 100% !important;
}
.bMBuBa.is-disabled {
  border: none !important;
}
.file-upload-cover {
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 36px;
  border: 1px dashed #eaecf0;
  width: 100%;
  margin: 0px !important;
  cursor: pointer;
  color: #98a2b3;
  font-family: Inter_Regular;
  font-weight: 400;
  .inner-cover {
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    span {
      color: #475467;
    }
  }
}
