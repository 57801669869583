.file-size-cover {
  margin-top: 10px;
  font-family: "Inter_Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #98a2b3;
}

.view-image-comp-cover {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23E6E7ECFF' stroke-width='2' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  .view-image-name {
    align-items: center;
    display: flex;
    flex-grow: 1;
    .image {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      object-fit: cover;
    }
    span {
      margin-left: 12px;
      color: #475467;
      font-weight: 400;
      font-size: 12px;
      font-family: Inter_Regular;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;

      width: 170px;
      // background-color: green;
      @media (max-width: 500px) {
        width: 150px;
      }
    }
  }
  .view-image-button {
    align-items: center;
    display: flex;

    font-weight: 500;
    font-size: 12px;
    font-family: Inter_Regular;
    cursor: pointer;
  }
  .active {
    color: #523b9f;
  }
  .inactive {
    color: rgba(82, 59, 159, 0.5);
  }
  .file-upload-doc-btn {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 10px;
    }
  }
}
.file-cover {
  height: 100%;
  width: 402px;
  img {
    object-fit: contain;
    width: 100%;
    height: 250px;
  }
  .thumbpages {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    .each-thumb {
      width: 14px;
      height: 14px;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin: 0 0.1em;
      cursor: pointer;
      span {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #362769;
      }
    }
    .active-page {
      border: 1px solid #5941a9;
      span {
        background-color: #5941a9;
      }
    }
  }
  .pdf-cover {
    border: 4px solid grey;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    .pdf-view {
      overflow: hidden;
      height: 250px;
    }
  }
}

.file-viewer {
  position: relative;
  .loader-div {
    height: 250px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .navigation {
    .right {
      position: absolute;
      right: 0;
      z-index: 1000;
      top: calc(50% - 46px);
      transform: translateY(-50%);
      cursor: pointer;
    }
    .left {
      position: absolute;
      left: 0;
      z-index: 1000;
      top: calc(50% - 46px);
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .doc-name {
    display: block;
    margin: 0 auto;
    text-transform: capitalize;
    font-family: "Inter_Medium";
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #211740;
  }
  .download-btn {
    display: block;
    margin: 0 auto;
    font-family: "Inter_Medium";
    font-style: normal;
    font-size: 12px;
    text-align: center;
    text-decoration-line: underline;
    color: #555555;
    cursor: pointer;
    margin-bottom: 20px;
    width: fit-content;
  }
  .svg-cover {
    padding: 1em;
  }
}

.file-upload-modal {
  .container {
    width: 530px;
    .upload-wrapper {
      width: 100%;
      background-color: white;
      padding: 16px 32px 48px;
      border-radius: 10px;
      .text-wrap {
        input {
          text-transform: capitalize;
        }
      }
      .select-wrap,
      .select-search-with-value {
        margin-bottom: 20px;
      }
      .file-upload-design {
        width: 100%;

        .label {
          padding: 16px;
          width: 100%;
          border: 1px solid #e4e7ec;
          border-radius: 8px;
          .no-file {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            strong {
              font-family: "Avenir-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #5941a9;
              margin: 12px auto 5px;
            }
            span {
              font-family: "Avenir-Roman";
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #555555;
            }
          }
        }
        .has-file {
          background: #ffffff;
          border: 1px solid #5941a9;
          border-radius: 8px;
          padding: 14px;
          display: flex;
          width: 100%;
          .file-info {
            flex-grow: 1;
            margin: 0 14px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            strong {
              font-family: "Avenir-Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #344054;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow-x: hidden;
              width: 300px;
            }
            .gear-cover {
              align-items: center;
              small {
                font-family: "Avenir-Roman";
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #555555;
                margin-right: 4px;
              }
              .gear-loading {
                svg {
                  width: 13px;
                  height: 13px;
                }
              }
            }
          }
        }
        .uploading {
          font-family: "Inter_Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #667085;
          margin-bottom: 10px;
          display: block;
        }
        input {
          display: none;
        }
      }
      .twin-buttons-cover {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center !important;
        .app-buttons {
          // width: 48%;
          flex-grow: 1;
        }
      }
      .title-head {
        margin-bottom: 20px;
        h6 {
          font-family: "Inter_Medium";
          font-weight: 500;
          font-size: 20px;
          color: #211740;
          margin: 0;
          margin-bottom: 4px;
        }
        span {
          font-family: "Inter_Regular";
          font-weight: 400;
          font-size: 16px;
          color: #667085;
          display: block;
        }
      }
    }
  }
}
.document-page-card-cover {
  border: 1px solid #e4e6e8;
  margin-bottom: 30px;
  border-radius: 10px;
  .doc-rejected-status-div {
    padding-top: 16px;
    padding-left: 16px;
    div {
      width: fit-content;
      padding: 5px 11px 5px 11px;
      background-color: rgba(254, 205, 202, 0.3);
      color: #912018;
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      border-radius: 100px;
      margin-bottom: 11px;
    }
  }
  .doc-accepted-status-div {
    padding-top: 16px;
    padding-left: 16px;
    div {
      width: fit-content;
      padding: 5px 11px 5px 11px;
      background-color: rgba(0, 196, 140, 0.07);
      color: #05603a;
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      border-radius: 100px;
      margin-bottom: 11px;
    }
  }
  .doc-pending-status-div {
    padding-top: 16px;
    padding-left: 16px;
    div {
      width: fit-content;
      padding: 5px 11px 5px 11px;
      background-color: #fffbf1;
      color: #93370d;
      font-family: "Inter_Regular";
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      border-radius: 100px;
      margin-bottom: 11px;
    }
  }
  .file-image-and-details-cover {
    margin: 0px 23px 29px 23px;

    .image-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .imageCover {
        width: 70px;
        height: 70px;
        background-color: #fbfbfc;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          width: 70px;
          height: 70px;
          border-radius: 100%;
          object-fit: cover;
        }
      }
    }
    .doc-name-div {
      .doc-title {
        font-family: "Inter_Regular";
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 5px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        /* identical to box height */

        text-align: center;

        color: #171721;
      }
      .doc-value {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        /* identical to box height */

        text-align: center;

        color: #555555;
        margin-bottom: 20px;
      }
    }
    .doc-button-div {
      display: flex;
      justify-content: center;
    }
  }
  .file-size-and-date-cover {
    // background-color: green;
    margin-top: 29px;
    border-top: 1px solid #e4e6e8;
    display: flex;
    .doc-size-cover {
      width: 50%;
      padding-top: 13px;
      padding-bottom: 13px;
      border-right: 1px solid #e4e6e8;
      text-align: center;
      .kb-cover {
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-align: center;

        color: #121212;
      }
      .size-cover {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        text-align: center;

        color: #8083a3;
      }
    }
    .doc-date-cover {
      width: 50%;
      padding-top: 13px;
      padding-bottom: 13px;
      text-align: center;
      .date-value-cover {
        font-family: "Inter_Regular";
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-align: center;

        color: #121212;
      }
      .updated-cover {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        text-align: center;

        color: #8083a3;
      }
    }
  }
}
