.empty-record {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1em;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  border-radius: 4px;
  .empty-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 250px;
    max-width: 250px;
    h4 {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #1d2939;
      margin-top: 32px;
    }

    h5 {
      font-family: "Inter_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #98a2b3;
    }

    h6 {
      font-family: "Inter_Medium";
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #523b9f;
    }
  }
}
