.caution-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 165, 0, 0.05);
  border: 1px solid #ffa500;
  border-radius: 4px;
  gap: 10px;
  // height: 48px;
  padding: 12px;
  margin-bottom: 25px;
  h6 {
    font-size: 14px;
    text-align: center;
    line-height: 16.94px;
    margin: 0;
    padding: 0;
    span {
      font-weight: 800;
    }
    @media (max-width: 650px) {
      font-size: 10px;
    }
  }
}
