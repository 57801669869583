.input_without_labe_cover {
    input {
        border: 1px solid rgba(234, 236, 240, 1);
        height: 48px;
        border-radius: 4px;
        padding-left: 16px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1d2939;
        font-family: "Inter_Regular";
        width: 100%;
    }
    input:focus {
        outline: none !important;
        border: 1px solid #523b9f;
        // box-shadow: 0 0 10px #719ece;
    }
}