.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  flex-wrap: wrap-reverse;
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // max-width: 400px;
    h3 {
      font-size: 32px;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    max-width: 100%;
    svg {
      max-width: 100%;
    }
  }
}
