.country-select-icon {
  width: 100%;

position: relative;
&-inner{
  display: flex;
  border: 1px solid rgba(234, 236, 240, 0.7) ;
  padding-left: 12px;
  height: 50px;
  border-radius: 4px;
  align-items: center;
  &-country {

    margin-right: 8px;
  }
 div{
  color: #81899e;
    font-size: 13px;

    font-weight: 400;
    font-family: Inter_Regular;


 }
}

}
