.list_of_roles_and_permission_cover {
    .list_of_roles_cover {
        margin-right: 20px;
        height: 100vh;
        overflow-y: auto;
        .list_of_roles_title_and_subtitle {
            margin-bottom: 32px;
            .list_of_roles_title {
                font-family: "Inter_Regular";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                color: #14142b;
                margin-bottom: 16px;
                @media (max-width: 650px) {
                    font-size: 12px;
                }
            }
            .list_of_roles_subTitle {
                font-family: "Inter_Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* or 150% */
                /* Gray */
                color: #98a2b3;
                @media (max-width: 650px) {
                    display: none;
                }
            }
        }
        .active_role_div_cover {
            padding-top: 32px;
            padding-bottom: 31px;
            border-top: 1px solid rgba(222, 230, 238, 1);
            display: flex;
            .active_assign_role_name {
                margin-left: 17px;
                font-family: "Inter_Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */
                /* Primary Gray */
                color: #475467;
                @media (max-width: 650px) {
                    .check_all_content {
                        display: block;
                        font-size: 12px;
                    }
                }
                span {
                    font-family: "Inter_Regular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */
                    /* Primary Gray */
                    color: #475467;
                    text-transform: capitalize;
                    @media (max-width: 650px) {
                        display: none;
                    }
                }
                // .roles_details_cover {
                // }
            }
        }
    }
    .list_of_permission_cover {
        border: 1px solid rgba(222, 230, 238, 1);
        padding: 15px 20px;
        border-radius: 10px;
        overflow-y: auto;
        margin-left: 20px;
        height: 100vh;
        .permission_title_and_btn_cover {
            display: flex;
            align-items: flex-start;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(222, 230, 238, 1);
            margin-bottom: 16px;
            align-items: center;
            @media (max-width: 1242px) {
                flex-direction: column;
            }
            .permission_title {
                flex-grow: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                font-family: "Inter_Regular";
                line-height: 30px;
                color: #14142b;
                @media (max-width: 650px) {
                    font-size: 12px;
                }
            }
            .permission_update_btn {
                margin-top: 12px;
            }
        }
        .permission_content_cover {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            /* identical to box height, or 154% */
            /* Gray */
            color: #98a2b3;
        }
        .permission_list_component_cover {
            overflow-y: auto;
            height: 80vh;
        }
    }
}