.onboarding-steps {
  svg {
    cursor: pointer;
  }
  @media (max-width:650px) {
    .onboarding-step {
      margin-right: 24px !important;
    }
  }
  .onboarding-step {
    display: flex;
    align-items: center;
    padding-bottom: 28px !important;
    margin-right: 32px !important;

    .circle {
      background-color: #523b9f;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      padding: 1px;
      position: relative;

      .inner-circle {
        background-color: white;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;

        p {
          margin: 0 auto;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          font-family: Inter_Regular;
        }
      }
    }

    p {
      margin: auto 0;
      margin-left: 12px;
      cursor: pointer;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      font-family: Inter_Regular;
      white-space: nowrap;
    }
    @media (max-width:650px) {
      .mobile-step-name-tag{
        display: none;

      }
    }

  }

  // .onboarding-step:not(:last-child) {
  //   margin-bottom: 0.91rem;
  // }
}
