.wallet-wrapper {
  padding: 56px 56px;
  max-width: 100vw;
  
  .wallet-card {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    box-sizing: border-box;
    border-radius: 4px;
    justify-content: space-between;
    row-gap: 25px;
    column-gap: 40px;
  }
  
}
