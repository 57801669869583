.instore-form {
  padding: 2em;
  .instore-container {
    background-color: #f8f8fa;
    height: fit-content;
    width: 100%;
    max-width: 500px;
    min-width: 300px;

    .header {
      margin: 1rem 0 4rem 0;

      h1 {
        font-size: 1.9rem;
      }
    }
    .generated-link {
      align-items: center;
      justify-content: space-between;
      padding: 1em;
      span {
        font-size: 0.8em;
      }
      button {
        border: 1px solid #e4e4e4;
        cursor: pointer;
        height: 35px;
        min-width: 70px;
      }
    }
    .amount-container {
      flex-direction: column;
      padding: 2rem;
      border: 1px solid rgb(240, 240, 240);
      background-color: white;

      h2 {
        color: #272643;
        font-family: "Recoleta_Bold";
        margin-bottom: 1em;
      }

      p {
        margin-bottom: 2rem;
        color: #667e93;
        font-family: "Avenir-Roman";
      }

      a {
        color: #5941a9;
        text-decoration: none;
        font-family: "Avenir-Heavy";
      }

      .submit-button {
        font-size: 1.1rem;
        border: none;
      }
    }
  }
}

// a {
//     color: #5941A9;
//     text-decoration: none;
//     font-family: "Avenir-Heavy";
//   }
