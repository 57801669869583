.filter-column-cover{
  position: relative;
  &-body{
    background-color:white;
    // height: 200px;
    width: 277px;
    position: absolute;
    z-index: 110;
    right: 0;
    margin-top: 5px;
    visibility: visible;
    opacity: 1;
    box-shadow: 0px 3px 13px -6px rgba(77, 70, 70, 0.25);
    border-radius: 4px;
    transition: opacity 0.3s ease-in-out;
    .filter-column-header{
      width: 100%;
      padding-top: 15px;
      padding-left: 16px;
      padding-bottom: 10px;
      padding-right: 16px;
      border-bottom: 1px solid #F2F4F7;
      font-size: 16px;
      font-family: Calibre_Regular;
      font-family: 400;
      display: flex;
      color: #000000;
      &-title{
        flex-grow: 1;

        height: fit-content;
      }
      &-cancle{
        height:24px;

        padding-bottom: 0px !important;
      }

    }
    .list-filter-contents{
      height: auto;
      max-height: 500px;
      overflow-y: auto;
    }
    .filter-column-footer{
      padding: 10px 18px 10px 18px;
      border-top: 1px solid #F2F4F7;
    }
  }
  .filter-body-hidden {
    opacity: 0;
    visibility: hidden;
  }
}