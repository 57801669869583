.overlay-view-cover {
  background: rgba(33, 23, 64, 0.55);
  .password-reset-view {
    background: #f6f6f9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    &-cover {
      max-width: 412px;
      &-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
        &-div {
          background-color: #dcd8ec;
          width: 60px;
          height: 60px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-title {
        color: #312360;
        text-align: center;
        line-height: 38.4px;
        font-size: 32px;
        font-weight: 400;
        font-family: Calibre_Regular;
        margin-bottom: 16px;
      }
      &-subtitle {
        display: flex;
        justify-content: center;
        color: #667085;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 32px;

        font-family: Calibre_Regular;
        div {
          text-align: center;
          width: 80%;
          font-family: Calibre_Regular;
        }
      }
      &-form {
        width: 100%;
        padding-left: 34px;
        padding-right: 34px;
        .form-btn {
          border: none;
          margin-top: 22px;
          font-size: 13px;
          font-weight: 500;
          font-family: Inter_Medium;
        }
      }
      &-account {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 16px;
        font-family: Calibre_Regular;
        &-link {
          font-family: Calibre_Regular;
        }
      }
    }
  }

}
.admin-password-reset {
  background: #f6f6f9;
  .password-reset-view {
    background: #f6f6f9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    &-cover {
      max-width: 344px;

      &-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
        &-div {
          background-color: #dcd8ec;
          width: 60px;
          height: 60px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-title {
        color: #312360;
        text-align: center;
        line-height: 38.4px;
        font-size: 32px;
        font-weight: 400;
        font-family: Calibre_Regular;
        margin-bottom: 16px;
      }
      &-subtitle {
        display: flex;
        justify-content: center;
        color: #667085;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 32px;


        font-family: Calibre_Regular;
        div {
          text-align: center;
          // width: 80%;
          font-family: Calibre_Regular;
        }
      }
      &-form {
        width: 100%;
        // padding-left: 34px;
        // padding-right: 34px;
        .form-btn {
          border: none;
          margin-top: 12px;
          font-size: 13px;
          font-weight: 500;
          font-family: Inter_Medium;
          height: 48px;
        }
      }
      &-account {
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 16px;
        font-family: Calibre_Regular;
        &-link {
          font-family: Calibre_Regular;
        }
      }
    }
  }
  .password-success-cover{
    background-color: white;
    width: 462px;
    border-radius: 10px;
    @media (max-width:500px) {
      width: 100%;
    }
    .cancle-icon-cover{
      padding: 16px 16px 44px 16px;
    }
    .icon-checked-cover{
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
   .password-title-and-subtitle-cover{
     padding-left: 59px;
     padding-right: 59px;
     padding-bottom: 192px;

    .password-success-title{
      text-align: center;
      font-family: 'Calibre_Regular';
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      color: #312360;
      margin-bottom: 16px;

    }
    .password-success-subtitle{
      font-family: 'Calibre_Regular';
font-weight: 400;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #667085;
margin-bottom: 32px;
    }
   }
  }
}

@media (max-width:650px) {
  .overlay-view-cover {
    background: rgba(33, 23, 64, 0.55);
    .password-reset-view {
      background: #f6f6f9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      overflow-y: auto;
      &-cover {
        max-width: 100%;

        &-logo {
          display: flex;
          justify-content: center;
          margin-bottom: 32px;
          &-div {
            background-color: #dcd8ec;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &-title {
          color: #312360;
          text-align: center;
          line-height: 38.4px;
          font-size: 32px;
          font-weight: 400;
          font-family: Calibre_Regular;
          margin-bottom: 16px;
        }
        &-subtitle {
          display: flex;
          justify-content: center;
          color: #667085;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 32px;

          font-family: Calibre_Regular;
          div {
            text-align: center;
            width: 80%;
            font-family: Calibre_Regular;
          }
        }
        &-form {
          width: 100%;
          padding-left: 34px;
          padding-right: 34px;
          .form-btn {
            border: none;
            margin-top: 22px;
            font-size: 13px;
            font-weight: 500;
            font-family: Inter_Medium;
          }
        }
        &-account {
          color: #667085;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          margin-top: 16px;
          font-family: Calibre_Regular;
          &-link {
            font-family: Calibre_Regular;
          }
        }
      }
    }
  }
  .password-reset {
    background: #f6f6f9;
    .password-reset-view {
      background: #f6f6f9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      overflow-y: auto;
      &-cover {
        max-width: 100%;

        &-logo {
          display: flex;
          justify-content: center;
          margin-bottom: 32px;
          &-div {
            background-color: #dcd8ec;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &-title {
          color: #312360;
          text-align: center;
          line-height: 38.4px;
          font-size: 32px;
          font-weight: 400;
          font-family: Calibre_Regular;
          margin-bottom: 16px;

        }
        &-subtitle {
          display: flex;
          justify-content: center;
          color: #667085;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 32px;

          font-family: Calibre_Regular;
          div {
            text-align: center;
            width: 80%;
            font-family: Calibre_Regular;
          }
        }
        &-form {
          width: 100%;
          padding-left: 34px;
          padding-right: 34px;
          .form-btn {
            border: none;
            margin-top: 22px;
            font-size: 13px;
            font-weight: 500;
            font-family: Inter_Medium;
          }
        }
        &-account {
          color: #667085;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          margin-top: 16px;
          font-family: Calibre_Regular;
          &-link {
            font-family: Calibre_Regular;
          }
        }
      }
    }
  }
}