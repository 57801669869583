.name-and-switch-cover {
  display: flex;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-right: 17px;
  border-bottom: 1px solid #f2f4f7;
  font-size: 12px;
  color: #000000;
  font-family: Inter_Regular;
  font-weight: 500;
  .name-and-switch-cover-switch {
    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #d9dbe9;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .checked {
      .slider {
        background-color: #5941a9;
      }
    }

    .checked {
      .slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
      }
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  &-title {
    flex-grow: 1;
    text-transform: capitalize;
  }
}
