
.pref-section-cover{
  &-left-col{
    &-title {
      color: #1d2939;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      font-weight: 400;
      font-family: Calibre_Regular;
    }
    &-subtitle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-family: Calibre_Regular;
      color: rgba(102, 112, 133, 0.8);
      margin-bottom: 4px;
      width: 55%;
    }
  }
  &-right-col{
&-first-div-cover{


&-content{
  width: 100%;
  &-inner{
    display: flex;
    margin-bottom: 24px;

div{
  .title{
    font-size: 14px;
    color: #1D2939;
    line-height: 24px;
    font-weight: 500;
    font-family: Inter_Medium;
    margin-bottom: 8px;

  }
  .subtitle{
    font-size: 16px;
    color: #98A2B3;
    line-height: 20px;
    font-weight: 400;
    font-family: Calibre_Regular;

  }
}
span{
  margin-right: 14px;
}

  }
  &-subinner{
    display: flex;
    div{
      .title{
        font-size: 14px;
        color: #1D2939;
        line-height: 24px;
        font-weight: 500;
        font-family: Inter_Medium;
        margin-bottom: 8px;

      }
      .subtitle{
        font-size: 16px;
        color: #98A2B3;
        line-height: 20px;
        font-weight: 400;
        font-family: Calibre_Regular;

      }
    }
    span{
      margin-right: 14px;
    }
  }

}
.input-div{
  margin-top: 24px;
}
}
  }
}
