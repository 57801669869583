.card-grid {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 32px;
    // max-width: 760px;
    max-width: 1150px;
    // background-color: green;
}

.full-screen {
    max-width: 1150px;
    .card_div {
        margin-bottom: 32px;
    }
}