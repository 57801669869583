.create_row_modal_cover {
    max-width: 416px;
    background-color: white;
    border-radius: 10px;
    padding: 13px 0px;
    @media (min-width: 500px) {
        width: 416px;
    }
    .create_row_modal_header_wrapper {
        display: flex;
        margin-bottom: 18px;
        padding: 0 32px;
        .create_row_modal_title_wrapper {
            padding-left: 13px;
            .create_row_modal_title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #121212;
                font-family: Inter_Regular;
            }
            .create_row_modal_subtitle {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: -0.002em;
                color: #81899e;
                font-family: Inter_Regular;
            }
        }
    }
    .create_row_modal_input_wrapper {
        background: #fcfcfd;
        padding: 35px 32px;
        .create_row_modal_input_text {
            font-family: Inter_Regular;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #121212;
            margin-bottom: 25px;
        }
        .create_row_modal_radio_wrapper {
            display: flex;
            flex-wrap: wrap;
            .custom-radio {
                margin-bottom: 0px;
                label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .radio-icon {
                        width: 12px;
                        height: 12px;
                        background: #ffffff;
                        border: 1px solid #cdd0d8;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .checked {
                            background-color: transparent;
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                        }
                    }
                    .radio-info {
                        margin-left: 7px;
                        display: flex;
                        strong {
                            font-family: "Inter_Regular";
                            font-size: 12px;
                            color: #121212;
                            font-weight: 400;
                        }
                        span {
                            font-family: "Inter_Regular";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            color: #81899e;
                        }
                    }
                }
                input {
                    display: none;
                }
                input:checked+label .checked {
                    background-color: #5941a9;
                }
                input:checked+label .checkIcon {
                    border-color: #5941a9;
                    background-color: #ebecfe;
                }
            }
        }
    }
    .create_row_modal_button_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        justify-content: flex-end;
        margin: 32px 0 20px 0;
        padding: 0px 32px;
    }
}