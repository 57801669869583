.success-payment-link {
    width: 400px;
    padding: 1.5em;
    @media (max-width: 650px) {
        width: 100%;
    }
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    border-radius: 12px;
    h6 {
        font-size: 1em;
        text-align: center;
        color: #211740;
        font-weight: 500;
        font-family: Inter_Medium;
        margin-top: 7px;
        margin-bottom: 38px;
    }
    .success-icon {
        // margin: 1.5em auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .reason {
        text-align: center;
        display: block;
        font-size: 0.75em;
        margin-bottom: 2em;
        font-family: Inter_Regular;
        line-height: 14.52px;
        color: #667085;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        h6 {
            font-family: "Inter_Medium";
            font-size: 1em;
            color: #121212;
            max-width: 350px;
            margin-left: 0.9em;
            font-weight: 500;
            width: 100%;
            padding: 7px 0px 0px 0px;
            margin: 0px;
        }
        span {
            font-family: "Inter_Regular" !important;
            font-size: 0.9em;
            color: #211740;
            max-width: 350px;
            margin-left: 0.9em;
            font-weight: 400 !important;
        }
    }
    .comment-input {
        width: 100%;
        height: 100px;
        padding: 0.5em;
        border-radius: 4px;
        border: 1px solid rgba(234, 236, 240, 0.7);
        font-size: 0.9em;
        font-family: "Inter_Regular" !important;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 0.5em;
        margin-top: 45px;
        color: #101828;
        font-weight: 400;
        resize: none;
        outline: none;
    }
    .description {
        font-family: "Inter_Regular";
        font-size: 0.8em;
        color: #555555;
        text-align: center;
        display: block;
        margin-top: 1em;
        strong {
            font-family: "Inter_Regular";
            font-size: 1em;
            color: #523b9f;
            text-transform: uppercase;
        }
        .create_payment_link_message {
            font-family: "Inter_Regular";
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
        }
    }
    .twin-buttons-cover {
        width: 100%;
        display: flex;
        justify-content: center !important;
        .app-buttons {
            width: 10em;
        }
        .edit-type {
            color: rgba(82, 59, 159, 1);
            border: 1px solid rgba(220, 216, 236, 1);
        }
    }
    .deactivate-type {
        justify-content: flex-end;
        margin-right: 0;
        .app-buttons {
            width: 6em;
        }
    }
    .bg-red {
        background: #ff0000;
    }
    .social-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1em;
        .link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: none;
            // margin-left: 2px;
            // margin-right: 2px;
            .icon_cover {
                margin-bottom: 0.5em;
                @media (max-width: 650px) {
                    margin-left: 3px;
                    margin-right: 3px;
                    svg {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            img {
                width: 60px;
                height: 60px;
                border-radius: 4px;
                margin-bottom: 0.5em;
                @media (max-width: 650px) {
                    margin-left: 3px;
                    margin-right: 3px;
                    width: 50px;
                    height: 50px;
                }
            }
            small {
                color: #11142d;
                font-size: 0.7em;
                font-family: Inter_Regular;
                // @media (max-width: 650px) {
                //     font-size: 10px;
                // }
            }
        }
    }
}