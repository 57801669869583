.leaderboard-root-cover {
    padding-top: 30px;
    padding-left: 31px;
    padding-right: 31px;
    .leaderboard-table-details-cover {
        margin-top: 13px;
        padding: 16px;
        border: 1px solid #f2f4f7;
        border-radius: 4px;
    }
    .div-table {
        table {
            tbody {
                tr {
                    td {
                        .double-cell {
                            align-items: center;
                            p {
                                font-weight: 500;
                                font-family: "Inter_Medium" !important;
                                text-transform: capitalize;
                                color: #475467 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}