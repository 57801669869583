.search-box {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 40px;

  background: #ffffff;

  /* Gray/Grey 100 */

  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  border-radius: 4px;
  input {
    display: block;
    border: none;
    font-family: "Inter_Regular";
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #475467;
    margin-left: 12px;
    flex-grow: 1;
    outline: none;
    margin-top: 0 !important;
    &::placeholder {
      color: #98a2b3;
      font-family: "Inter_Regular";
    }
  }
  .lds-ring {
    margin: 0;
    width: 15px;
    height: 15px;
    div {
      margin: 0;
      width: 15px;
      height: 15px;
      border-width: 1.5px;
    }
  }
}
@media (max-width: 650px) {
  .search-box {
    width: 100%;
  }
}
