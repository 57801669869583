.short-table-cover{
border-radius: 10px;
 border: 1px solid #E0E7ED;
//  margin-bottom: 20px;
 table{
  width: 100%;
  min-width: 550px;
//  border: 1px solid #E0E7ED;
 border-radius: 8px !important;
//  background-color: green;

  // background-color: green;
  thead{
    background: rgba(220, 216, 236, 0.2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-family: Inter_Regular !important;


    tr{
      th{
        padding: 16px 12px 16px 12px !important;
        font-family: Inter_Regular !important;
      }
    }

  }
}
td:first-child{
  p{
    padding: 16px 12px 16px 12px !important;
    margin: 0px;
    font-family: 'Inter_Regular';
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #121212;
    text-transform: capitalize !important;

  }
}
td{
  p{
    padding: 16px 12px 16px 12px !important;
    margin: 0px;
    font-family: 'Inter_Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #667085;
    text-transform: capitalize !important;


  }

}

thead,tbody,tr,td,th{

  box-shadow: 0px -1px 0px 0px #E0E7ED;
  // border: 1px solid #E0E7ED;


// border-bottom: 1px solid green;



max-width: 200px;
}
}
