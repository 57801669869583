.sms_config_cover {
    .select-custom-styles .drop-down-select-search {
        display: none;
    }
}

.edit_sms_config_cover {
    .select-custom-styles .css-yk16xz-control {
        border: 1px solid rgba(89, 65, 169, 1);
    }
}

.sms_config_button_cover {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}