.steps-card {
  .onboarding-c-r-top {
    h3 {
      font-size: 1.3rem;
      color: #272643;
      font-family: 'Avenir';
      font-weight: 800;
    }

    p {
      color: #667e93;
    }
    margin-bottom: 3em;
  }
  .merchant-onboarding-top {
    .title {
      color: #211740;
      font-size: 24px;
      letter-spacing: -2%;
      line-height: 29.05px;
      font-weight: 500;
      font-family: Inter_Medium;
      margin-bottom: 16px;
    }
    .subtitle {
      color: #667085;
      line-height: 19.36px;
      font-size: 16px;
      font-weight: 400;
      font-family: Inter_Regular;
      margin-bottom: 50px;
    }
  }
}
