.loan-details-cover {
  padding: 30px;
  .title {
    color: rgba(102, 112, 133, 0.7);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "Inter_Regular";
    margin-bottom: 11px;
  }
  .loan-ref-id-and-badge {
    display: flex;
    align-items: center;
    margin-bottom: 45.5px;
    .loan-ref-span {
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #211740;
      font-family: "Inter_Regular";
      margin-right: 10px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    .ref-no-span {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #98a2b3;
      margin-right: 10px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    .loan-badge-span {
      width: fit-content;
      padding: 2px 8px 2px 8px;
      // height: 24px;
      background-color: #f9f5ff;
      font-family: "Inter_Regular";

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: center;

      color: #5941a9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
  .manual-loan-creation-wrapper {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: start;
    max-width: 445px;
    height: 108px;
    background-color: #362769;
    color: white;
    border-radius: 4px;
    padding: 16px 24px;
    @media (max-width: 768px) {
      height: 100%;
    }
  }
}
