.special_mobile_cover {
    // display: flex;
    max-width: 390px;
    overflow-x: auto;
    // float: right;
    .mobile_view_image_div {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-right: 14px;
    }
    .mobile_view_double_rec {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        max-width: 250px;
        color: rgba(52, 55, 63, 1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}