.user-details-card-cover {
    position: relative;
    .user-details-card {
        background-color: rgba(220, 216, 236, 0.2);
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 34px;
        padding-bottom: 34px;
        border-radius: 10px;
        display: flex;
        position: relative;
        @media (max-width: 809px) {
            flex-direction: column;
        }
        @media (max-width: 500px) {
            padding-left: 10px;
            padding-right: 10px;
        }
        .image-outer-cover {
            @media (max-width: 500px) {
                display: flex;
                justify-content: center;
            }
            .image-div-cover {
                cursor: pointer;
                img {
                    object-fit: cover;
                }
                width: 130px;
                height: 130px;
                position: relative;
                div {
                    width: 130px;
                    height: 130px;
                    border-radius: 100%;
                    .img-div-tag {
                        width: 130px;
                        height: 130px;
                        border-radius: 100%;
                    }
                }
                .active-indicator {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    background-color: green;
                    border-radius: 100%;
                    border: 4px solid white;
                    top: 80%;
                    right: 5%;
                    z-index: 1;
                }
                .inactive-indicator {
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    background-color: #d9dbde;
                    border-radius: 100%;
                    border: 2px solid white;
                    top: 80%;
                    right: 5%;
                    z-index: 1;
                }
            }
        }
        .details-div-cover {
            margin-left: 30px;
            flex-grow: 1;
            width: 0%;
            @media (max-width: 809px) {
                margin-left: 0px;
                width: 100%;
            }
            .name-details {
                display: flex;
                align-items: center;
                @media (max-width: 650px) {
                    flex-direction: column;
                    margin-bottom: 10px;
                }
                .name {
                    font-size: 36px;
                    font-weight: 600;
                    color: #121212;
                    font-family: Inter_Regular;
                    text-transform: capitalize;
                    padding-right: 0.6rem;
                    @media (max-width: 809px) {
                        font-size: 20px;
                    }
                    @media (max-width: 500px) {
                        text-align: center;
                    }
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    .partner-name {
                        margin-left: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 10px;
                        background: rgba(89, 65, 169, 0.1);
                        border-radius: 100px;
                        font-size: 12px;
                        text-transform: capitalize;
                        font-weight: 500;
                        color: #5941a9;
                        font-family: Inter_Medium;
                        height: 25px;
                    }
                }
                .loand_mandate_status_wrapper {
                    margin-left: 10px;
                    padding: 5px 13px;
                    background: rgba(18, 18, 18, 0.07);
                    border-radius: 100px;
                    text-transform: capitalize;
                    font-family: Inter_Medium;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    @media (max-width: 650px) {
                        margin-top: 15px;
                    }
                }
            }
            @media (max-width: 500px) {
                text-align: center;
            }
            .transaction_status_root_wrapper {
                display: flex;
                align-items: center;
                @media (max-width: 650px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                .tranaction-status-div-cover {
                    .active-status-div {
                        background-color: #ecfdf3;
                        width: fit-content;
                        padding: 6.5px 22.5px 6.5px 22.5px;
                        border-radius: 50px;
                        font-size: 14px;
                        font-weight: 500;
                        font-family: Inter_Medium;
                        color: #027a48;
                        line-height: 16.94px;
                        height: 30px;
                    }
                    .inactive-status-div {
                        background-color: #d9dbde;
                        width: fit-content;
                        padding: 6.5px 22.5px 6.5px 22.5px;
                        border-radius: 50px;
                        font-size: 14px;
                        font-weight: 500;
                        font-family: Inter_Medium;
                        color: #2f394b;
                        line-height: 16.94px;
                        height: 30px;
                    }
                    @media (max-width: 500px) {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
            .location {
                margin-top: 10px;
                color: #a3a3a3;
                font-family: "Inter_Regular";
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            }
            .email-phone-and-alternative-cover {
                display: flex;
                // align-items: center;
                @media (max-width: 1255px) {
                    flex-direction: column;
                }
                .email-and-phone-details {
                    display: flex;
                    margin-top: 32.5px;
                    width: fit-content;
                    @media (max-width: 809px) {
                        margin-top: 14px;
                    }
                    @media (max-width: 500px) {
                        display: flex;
                        justify-content: center;
                    }
                    .email-span-details {
                        display: flex;
                        color: #555555;
                        font-size: 20px;
                        font-weight: 400;
                        font-family: Inter_Regular;
                        line-height: 24.2px;
                        align-items: center;
                        @media (max-width: 1369px) {
                            width: 50%;
                        }
                        @media (max-width: 809px) {
                            font-size: 10px;
                            .email-user-icon {
                                width: 14px;
                                height: 14px;
                            }
                        }
                        .email-span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            width: 100%;
                            margin-left: 12.67px;
                            font-family: Inter_Regular;
                        }
                    }
                    .phone-details-span {
                        display: flex;
                        color: #555555;
                        font-size: 20px;
                        font-weight: 400;
                        font-family: Inter_Regular;
                        line-height: 24.2px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        align-items: center;
                        margin-left: 34px;
                        @media (max-width: 1369px) {
                            width: 50%;
                        }
                        @media (max-width: 809px) {
                            font-size: 10px;
                            .phone-user-icon {
                                width: 14px;
                                height: 14px;
                            }
                        }
                        .phone-span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            margin-left: 12.67px;
                            width: 100%;
                            font-family: Inter_Regular;
                            span {
                                font-family: Inter_Regular;
                            }
                        }
                    }
                }
                .alternative-number-cover {
                    margin-left: 10px;
                    margin-top: 32px;
                    display: flex;
                    align-items: flex-end;
                    @media (max-width: 650px) {
                        justify-content: center;
                    }
                }
            }
        }
    }
    .admin-user-dropdown {
        position: absolute;
        width: 170px;
        right: 50px;
        top: 12%;
        z-index: 1;
        // width: 50%;
        // height: 50px;
        @media (max-width: 500px) {
            top: 10%;
            right: 30px;
        }
    }
    .admin-user-list-cover {
        position: absolute;
        top: 0%;
        right: 0%;
        // width: 50px;
        // height: 50px;
        // background-color: green;
        z-index: 7000;
    }
    .admin-user-dropdown-cover {
        position: absolute;
        top: 30px;
        width: 100%;
        z-index: 1000;
    }
}