.app-overlay {
  width: 100%;
  position: relative;
  background: rgba(33, 23, 64, 0.55);
  .app-overlay-cover {
    background: rgba(33, 23, 64, 0.55);
    height: 100vh;
    position: absolute;
    width: 100%;
    top: 0%;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }
}
