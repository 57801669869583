.app-buttons {
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  padding: 0 12px;
  height: 40px;
  min-width: 77px;
  //   @media (max-width:500px) {

  //   display: none;

  // }
  span {
    font-family: "Inter_Regular";
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: block;
  }
}

.btn-md {
  // min-width: 105px;
  height: 40px;
}

.btn-sm {
  // min-width: 90px;
  height: 40px;
}

.bg-light-pri {
  background: #fffafa;
}

.text-white {
  span {
    color: #fff !important;
  }
}
.delete-type {
  background-color: #db2838;
}
@media (max-width: 650px) {
  .app-buttons {
    min-width: 40px;
  }
}

.filter-button {
  @media (max-width: 500px) {
    display: none;
  }
}
.ismobile-filter {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #f2f4f7;
  margin-right: 12px;
}
