.label-edit {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  border-bottom: 1px solid #f2f4f7;
  section {
    margin-top: 24px;
    h6 {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #1d2939;
      margin-bottom: 16px;
    }
    small {
      font-family: "Calibre_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: rgba(102, 112, 133, 0.8);
    }
    .label {
      font-family: "Inter_Regular";
      font-weight: 400;
      font-size: 13px;
      letter-spacing: -0.002em;
      color: #98a2b3;
      margin-bottom: 10px;
      display: block;
    }
    .section-input-cover {
      display: flex;
      justify-content: space-between;
      max-width: 506px;
      margin-right: 0;
      margin-bottom: 24px;
    }
    .label-edit-input {
      width: 100%;
      max-width: 356px;
      height: 48px;
      background: #ffffff;
      border-radius: 4px;
      padding: 15px 16px;
      font-family: "Inter_Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: -0.002em;
      color: #000000;
      outline: none;
      border: 1px solid rgba(234, 236, 240, 0.7);
    }
  }
  &:last-child {
    border-bottom: none;
  }
  .select-custom-styles {
    .css-1uccc91-singleValue {
      padding-top: 0px;
    }
    .css-107lb6w-singleValue {
      margin-top: 0px;
    }
  }
  .select-search-with-value {
    .select-search {
      .css-b8ldur-Input {
        margin-top: 0px;
      }
    }
  }
}
