.view_payment_link_cover {
    .go_back_div_cover {
        margin-bottom: 10px;
        span {
            font-family: "Inter_Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: rgba(102, 112, 133, 0.7);
        }
    }
    .go_back_div_cover {
        font-family: "Inter_Regular";
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */
        color: #4d525f;
    }
    .create_link_title_cover {
        margin-bottom: 24px;
        font-family: "Inter_Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */
        color: #4d525f;
    }
    .view_payment_link_cover_page {
        margin-top: 32px;
    }
}