.loan-repayment-schedule{
.loan-repayment-title{
  font-family: 'Inter_Regular';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #121212;
padding-bottom: 19px;
border-bottom: 1px solid #F2F4F7;
margin-bottom: 11px;
}
}