.reset-password-modal {
  background: white;
  padding: 84px 40px 57px 40px;
  border-radius: 10px;
  position: relative;
  &-closeIcon {
    position: absolute;
    top: 24px;
    right: 24px;
    span {
      padding: 8px;
      background: rgba(242, 244, 247, 0.5);
      border-radius: 100%;
      cursor: pointer;
    }
  }
  &-icon {
    text-align: center;
    margin-bottom: 24px;
  }
  &-title {
    text-align: center;
    color: #312360;
    font-size: 32px;
    font-weight: 500;
    font-family: Calibre_Medium;
    text-align: center;
    margin-bottom: 16px;
  }
  &-subtitle {
    color: #616e7c;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-weight: 400;
    font-family: Calibre_Regular;
    margin-bottom: 32px;
    span {
      color: #523b9f;
      font-family: Calibre_Regular;
    }
  }
  &-button {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    button {
      width: 70%;
      border: none;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      height: 48px;
      font-family: Inter_Regular;
    }
  }
  &-resend {
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
    color: #616e7c;
    font-family: Calibre_Regular;
    text-align: center;
    span {
      color: #523b9f;
      text-decoration: underline;
      font-family: Calibre_Regular;
      cursor: pointer;
    }
  }
}
