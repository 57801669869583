.modalwrapcomp {
    .dark-env {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(33, 23, 64, 0.55);
        cursor: pointer;
    }
    .content-in-modal {
        position: relative;
        z-index: 500;
        max-width: fit-content;
        display: flex;
        justify-content: center;
    }
}