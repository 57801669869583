.business-detail-cover{
  .business-profile-cover{
    .business-profile-form-cover{
      margin-top: 32px;
      border-bottom: 1px solid #F2F4F7;
      padding-bottom: 30px;
    }

  }
  .business-contact-cover{
    padding-top: 33px;


    .business-contact-form-cover{
      margin-top: 32px;
      padding-bottom: 30px;
      border-bottom: 1px solid #F2F4F7;
    }
  }

}
