.lds-ring {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 1em auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 2px solid rgb(89, 65, 169);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(89, 65, 169) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #5941a9;
  color: #5941a9;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -0.75em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #5941a9;
  color: #5941a9;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 0.75em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #5941a9;
  color: #5941a9;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #5941a9;
  }
  50%,
  100% {
    background-color: #5941a97c;
  }
}

.dot-loader {
  width: fit-content;
  margin: 1em auto;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gear-loading {
  display: flex;
  justify-content: flex-end;
  svg {
    animation: rotate 1s infinite linear;
  }
}
